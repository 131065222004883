import { useIntervalEffect } from "@react-hookz/web";
import { useState } from "react";

import {
  calcWorkerInstance,
  initializecalcWorker,
} from "../../utils/useComlink";
import type {
  TGridDataColumn,
  TGridDataEntry,
  TGridDataRowId,
  TManualStoreKey,
} from "./sharedStores";
import { addInfoToPageProduct } from "../market-grid/plugins/live-prices/helpers";
import type { TPageProduct } from "../../triplit/schema";

const isSharedWorker = false;
//isDev && !isMobile && typeof SharedWorker !== "undefined";

export function createWorker() {
  const worker = isSharedWorker
    ? new SharedWorker(new URL("./index", import.meta.url), {
        type: "module",
      })
    : new Worker(new URL("./index", import.meta.url), {
        type: "module",
      });

  console.log("starting worker - isSharedWorker", isSharedWorker);
  initializecalcWorker(worker);
}

createWorker();

export const calcWorker = calcWorkerInstance;

export type TWorker = ReturnType<NonNullable<typeof calcWorker>>["proxy"];
export function useLatestEditInfo({
  productId,
  storageType,
}: {
  storageType?: TManualStoreKey["storageType"] | null;
  productId?: TManualStoreKey["productId"];
}) {
  const worker = calcWorker?.()?.proxy;
  const [editInfo, setEditInfo] = useState<string | null | undefined>(null);

  useIntervalEffect(() => {
    const fetchManualProductInfo = async () => {
      if (worker && productId && storageType) {
        try {
          const info = await worker.getManualProductInfo({
            productId,
            storageType,
          });

          setEditInfo(info?.latestEditInfo);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchManualProductInfo();
  }, 1000);

  return editInfo || "";
}

export function useSessionState() {
  const worker = calcWorker?.()?.proxy;
  const [sessionState, setSessionState] = useState<{
    existingSession: boolean;
  } | null>(null);
  useIntervalEffect(() => {
    if (worker) {
      worker.getSessionState().then(setSessionState).catch(console.error);
    }
  }, 5000);
  return sessionState || { existingSession: false };
}

export async function productAndRowToValue(
  products: TPageProduct[],
  rowIds: TGridDataRowId[],
) {
  const worker = calcWorker?.().proxy;

  const eodEvalDate = await worker?.getEodDate();

  const columns: TGridDataColumn[] = products
    .map((product) => addInfoToPageProduct(product))
    .filter(Boolean);

  const data = await worker?.getGridData({
    columns,
    rowIds,
    eodEvalDate,
  });

  const dataIndexedByRowIds = data?.reduce(
    (acc, entry, idx) => {
      const rowId = rowIds[idx];
      if (!rowId) return acc;
      acc[rowId.toString()] = entry;
      return acc;
    },
    {} as Record<string, TGridDataEntry>,
  );

  return dataIndexedByRowIds;
}

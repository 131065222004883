import { createContext, useContext, useEffect, useMemo } from "react";
import { atom, useAtomValue, useStore } from "jotai";
import { useColorScheme } from "@mui/joy";
import type React from "react";

import { client } from "../triplit/triplit";
import { useUserId } from "../context/auth";

type Section = "lumiTheme" | "webappTheme" | "liveChartsTheme";

const ThemeModeContext = createContext({
  mode: atom<"light" | "dark">("light"),
  section: "webappTheme" as Section,
});

export const ThemeModeProvider = (p: {
  children: React.ReactNode;
  section: Section;
}) => {
  const value = useMemo(
    () => ({ mode: atom<"light" | "dark">("light"), section: p.section }),
    [p.section],
  );
  return (
    <ThemeModeContext.Provider value={value}>
      <RegisterTheme />
      {p.children}
    </ThemeModeContext.Provider>
  );
};

export const useThemeMode = () =>
  useAtomValue(useContext(ThemeModeContext).mode);

export const useSetThemeMode = () => {
  const ctx = useContext(ThemeModeContext);
  const store = useStore();
  const userId = useUserId();
  const setMode = useColorScheme().setMode;

  return async (theme: "dark" | "light") => {
    if (!userId || store.get(ctx.mode) === theme) return;
    const section = ctx.section;
    store.set(ctx.mode, theme);
    setMode(theme);
    const existing = await client.fetchById("theme", userId);
    if (existing) {
      client.update("theme", userId, (prev) => {
        prev[section] = theme;
      });
    } else {
      client.insert("theme", { id: userId, [section]: theme });
    }
  };
};

const RegisterTheme = () => {
  const ctx = useContext(ThemeModeContext);
  const setMode = useColorScheme().setMode;
  const userId = useUserId();
  const store = useStore();

  useEffect(() => {
    if (!userId) return;
    const section = ctx.section;
    client.fetchById("theme", userId).then((existing) => {
      if (
        !existing ||
        !existing[section] ||
        existing[section] === store.get(ctx.mode)
      ) {
        setMode(store.get(ctx.mode));
        return;
      }
      store.set(ctx.mode, existing[section] as "light" | "dark");
      setMode(existing[section] as "light" | "dark");
    });
  }, [store, userId, setMode, ctx]);

  return null;
};

import type { FetchResult } from "@apollo/client";
import * as R from "remeda";

import { capitalizeFirstLetter } from "../../utils";
import { getOffset, monthStringToCode } from "../market-grid/periodHelpers";
import type {
  TSharedCellUser,
  TManualStoreKey,
  TLatestEditInfoValue,
} from "./sharedStores";
import {
  clearEodStore,
  setEodForCalcsStore,
  setEodStore,
  setLatestEditInfoStore,
  setManualCellsStore,
} from "./storeLogic";
import type {
  AllConfigsSubSubscription,
  ProductInfoQuery,
  ProductInfoSubSubscription,
  SharedInstrumentsSubscription,
  SharedInstrumentsQueryQuery,
  EodEntriesSubscription,
  AllConfigsQueryQuery,
  LocalInstrumentsSubscription,
} from "../../__generated__/gql/graphql";
import type { TManualStoreStorageType } from "../market-grid/statuses/statusLogic";

export type ProductInfoFields = {
  eod_product_dep: string | undefined | null;
  artis_type: string;
  summary_type: string;
  original_artis_type: string;
};

export type ProductInfo = Record<string, ProductInfoFields>;

export type TOptimisticCell = {
  product: string;
  offset: number;
  field: string;
  result: number | undefined;
  storageType: TManualStoreStorageType;
  month: string;
  rowId: string;
};

export type TOptimisticCellEnhanced = TOptimisticCell & {
  editedAt: string;
  folio_user: TSharedCellUser;
};

export function handleConfigs(
  res: FetchResult<AllConfigsSubSubscription | AllConfigsQueryQuery>,
) {
  const data = res?.data?.product_config;
  if (!data) return;

  const configs = R.pipe(
    data,
    R.map((config) => ({
      formula: config?.formula,
      product: config.product,
      offset: config.offset,
    })),
    R.groupBy((config) => config.product),
  );

  return configs;
}

export const productInfoKey = "productInfo";

export function handleProductInfo(
  res: FetchResult<ProductInfoQuery | ProductInfoSubSubscription>,
) {
  const data = res?.data?.product;
  if (!data) return;

  const productInfo = data.reduce((acc, product) => {
    acc[product.id] = {
      summary_type: capitalizeFirstLetter(product.summary_type),
      eod_product_dep: product.eod_product_dep,
      artis_type: product.artis_type
        .split("_")
        .map(capitalizeFirstLetter)
        .join(""),
      original_artis_type: product.artis_type,
    };
    return acc;
  }, {} as ProductInfo);
  return productInfo;
}

export function handleLocalInsts(
  res: FetchResult<LocalInstrumentsSubscription>,
) {
  if (!res.data) return;
  const data = res.data.local_instrument;

  if (!data) return;

  data.map((inst) => {
    const rowId = monthStringToCode(inst.month);
    if (!rowId) throw new Error("monthCode is undefined");
    const productId = inst.product;
    const value = inst.value ?? undefined;
    const key = {
      productId,
      storageType: inst.storage_type,
    } satisfies TManualStoreKey;

    setManualCellsStore(key, rowId, value);
  });
}

const recentlySeenUpdates: string[] = [];

export function handleSharedInsts(
  currentUserId: string,
  res: FetchResult<SharedInstrumentsSubscription | SharedInstrumentsQueryQuery>,
) {
  if (!res.data) return;
  const data =
    "shared_instrument" in res.data
      ? res.data.shared_instrument
      : res.data.shared_instrument_stream;
  if (!data) return;
  const isStream = "shared_instrument_stream" in res.data;

  data.map((inst) => {
    const rowId = monthStringToCode(inst.month);
    if (!rowId) throw new Error("monthCode is undefined");
    const productId = inst.product;
    const value = inst.value ?? undefined;

    const instInfo = {
      key: {
        productId,
        storageType: "shared",
      } satisfies TManualStoreKey,
      lastEditedInfo: {
        username: inst.folio_user?.username ?? "",
        firstname: inst.folio_user?.firstname ?? "",
        lastname: inst.folio_user?.lastname ?? "",
        editedAt: inst.edited_at ?? "",
      } satisfies TLatestEditInfoValue,
      value,
      rowId,
    };
    const { key, lastEditedInfo } = instInfo;

    setManualCellsStore(key, rowId, value);
    setLatestEditInfoStore({ ...key, rowId }, lastEditedInfo);
    try {
      if (isStream && currentUserId !== inst.folio_user?.id) {
        const instHash =
          inst.value + inst.product + inst.month + inst.edited_by;
        // this is to workaround some strange behaviour with hasura streams
        // where we get the same update multiple times but with different timestamps
        if (!recentlySeenUpdates.includes(instHash)) {
          recentlySeenUpdates.push(instHash);
          if (recentlySeenUpdates.length > 100) {
            recentlySeenUpdates.shift();
          }
        }
      }
    } catch (e) {
      console.error("Error in handleSharedInsts", e);
    }
  });
}

export function handleEodInsts(res: FetchResult<EodEntriesSubscription>) {
  if (!res.data) return;
  const data = res.data.eod_entry;

  if (!data) return;

  console.log("handleEodInsts", data);

  clearEodStore();

  for (const eod of data || []) {
    const rowId = monthStringToCode(eod.month);
    try {
      const offset = getOffset(eod.month);

      if (!rowId) continue;

      const key = {
        productId: eod.product,
        rowId,
        evaluationDate: eod.evaluation_date,
      };

      setEodForCalcsStore(key, {
        product: eod.product,
        offset,
        field: "value",
        result: eod.value,
      });

      setEodStore(key, eod.value);
    } catch (e) {
      console.debug("skipping invalid eod");
    }
  }
}

import type { CanvasRenderingTarget2D } from "fancy-canvas";
import type {
  AutoscaleInfo,
  Coordinate,
  DataChangedScope,
  ISeriesPrimitive,
  ISeriesPrimitivePaneRenderer,
  ISeriesPrimitivePaneView,
  Logical,
  SeriesAttachedParameter,
  SeriesDataItemTypeMap,
  SeriesType,
  Time,
} from "lightweight-charts";
import { PluginBase } from "../plugin-base";
import { cloneReadonly } from "../plugin-helpers/simple-clone";
import { ClosestTimeIndexFinder } from "../plugin-helpers/closest-index";
import { UpperLowerInRange } from "../plugin-helpers/min-max-in-range";
import type { BBResult } from "indicatorts";
import type { LineSeriesProps } from "../components/line-series";

interface BandRendererData {
  x: Coordinate | number;
  upper: Coordinate | number;
  lower: Coordinate | number;
}

class BandsIndicatorPaneRenderer implements ISeriesPrimitivePaneRenderer {
  _viewData: BandViewData;
  constructor(data: BandViewData) {
    this._viewData = data;
  }
  // biome-ignore lint/suspicious/noEmptyBlockStatements: needs a draw stub to implement the interface
  draw() {}
  drawBackground(target: CanvasRenderingTarget2D) {
    const points: BandRendererData[] = this._viewData.data;

    // biome-ignore lint/correctness/useHookAtTopLevel: this isn't a hook, it just has use in the name
    target.useBitmapCoordinateSpace((scope) => {
      try {
        const ctx = scope.context;
        ctx.scale(scope.horizontalPixelRatio, scope.verticalPixelRatio);

        ctx.strokeStyle = this._viewData.options.lineColor;
        ctx.lineWidth = this._viewData.options.lineWidth;
        ctx.beginPath();
        const region = new Path2D();
        const lines = new Path2D();
        region.moveTo(points[0].x, points[0].upper);
        lines.moveTo(points[0].x, points[0].upper);
        for (const point of points) {
          region.lineTo(point.x, point.upper);
          lines.lineTo(point.x, point.upper);
        }
        const end = points.length - 1;
        region.lineTo(points[end].x, points[end].lower);
        lines.moveTo(points[end].x, points[end].lower);
        for (let i = points.length - 2; i >= 0; i--) {
          region.lineTo(points[i].x, points[i].lower);
          lines.lineTo(points[i].x, points[i].lower);
        }
        region.lineTo(points[0].x, points[0].upper);
        region.closePath();
        ctx.stroke(lines);
        ctx.fillStyle = this._viewData.options.fillColor;
        ctx.fill(region);
      } catch (e) {
        // it's okay because the only time this happens is when there aren't enough points to draw the bands (i.e. when there are not enough candlesticks like when at the oldest point in the chart before we started collecting data). This shouldn't happen unless candlestick data is missing at which point the whole chart is broken most likely.
      }
    });
  }
}

interface BandViewData {
  data: BandRendererData[];
  options: Required<BandsIndicatorOptions>;
}

class BandsIndicatorPaneView implements ISeriesPrimitivePaneView {
  _source: BandsIndicator;
  _data: BandViewData;

  constructor(source: BandsIndicator) {
    this._source = source;
    this._data = {
      data: [],
      options: this._source._options,
    };
  }

  update() {
    const series = this._source.series;
    const timeScale = this._source.chart.timeScale();
    this._data.data = this._source._bandsData.map((d) => {
      return {
        x: timeScale.timeToCoordinate(d.time) ?? -100,
        upper: series.priceToCoordinate(d.upper) ?? -100,
        lower: series.priceToCoordinate(d.lower) ?? -100,
      };
    });
  }

  renderer() {
    return new BandsIndicatorPaneRenderer(this._data);
  }
}

interface BandData {
  time: Time;
  upper: number;
  lower: number;
}

export interface BandsIndicatorOptions {
  lineColor?: string;
  fillColor?: string;
  lineWidth?: number;
  period?: number;
  calculationFn?: (params: {
    highs: number[];
    lows: number[];
    closings: number[];
  }) => BBResult;
}

const defaults: Required<BandsIndicatorOptions> = {
  lineColor: "rgb(25, 200, 100)",
  fillColor: "rgba(25, 200, 100, 0.25)",
  lineWidth: 1,
  period: 20,
  calculationFn: () => ({ upper: [], lower: [], middle: [] }),
};

export type BandCalcData = {
  upper: number[];
  lower: number[];
  middle: number[];
  times: Time[];
};

export const emptyBandCalcData = {
  upper: [],
  lower: [],
  middle: [],
  times: [],
} satisfies BandCalcData;

export class BandsIndicator
  extends PluginBase
  implements ISeriesPrimitive<Time>
{
  _paneViews: BandsIndicatorPaneView[];
  _seriesData: SeriesDataItemTypeMap[SeriesType][] = [];
  _bandsData: BandData[] = [];
  _options: Required<BandsIndicatorOptions>;
  _timeIndices: ClosestTimeIndexFinder<{ time: number }>;
  _upperLower: UpperLowerInRange<BandData>;
  _calcData: BandCalcData = emptyBandCalcData;

  constructor(options: BandsIndicatorOptions = {}) {
    super();
    this._options = { ...defaults, ...options };
    this._paneViews = [new BandsIndicatorPaneView(this)];
    this._timeIndices = new ClosestTimeIndexFinder([]);
    this._upperLower = new UpperLowerInRange([]);
  }

  updateAllViews() {
    this._paneViews.forEach((pw) => pw.update());
  }

  paneViews() {
    return this._paneViews;
  }

  attached(p: SeriesAttachedParameter<Time>): void {
    super.attached(p);
    this.dataUpdated("full");
  }

  dataUpdated(scope: DataChangedScope): void {
    // plugin base has fired a data changed event
    this._seriesData = cloneReadonly(
      this.series.data(),
    ) as typeof this._seriesData;
    const options = this.series.options() as LineSeriesProps;
    this._calcData = options?.bandCalcData ?? emptyBandCalcData;
    this.calculateBands();
    if (scope === "full") {
      this._timeIndices = new ClosestTimeIndexFinder(
        this._seriesData as { time: number }[],
      );
    }
  }

  _minValue: number = Number.POSITIVE_INFINITY;
  _maxValue: number = Number.NEGATIVE_INFINITY;

  /**
   * Calculates the upper and lower bands for the given series data.
   *
   * This method processes the candlestick data to extract the closing, high, and low values,
   * and then uses the provided calculation function to determine the upper and lower bands.
   * It updates the minimum and maximum values encountered during the calculation and stores
   * the resulting band data.
   *
   * @private
   * @method calculateBands
   * @returns {void}
   */
  calculateBands(): void {
    const bandData: BandData[] = new Array(this._seriesData.length);
    let index = 0;
    this._minValue = Number.POSITIVE_INFINITY;
    this._maxValue = Number.NEGATIVE_INFINITY;

    const bandCalcs = this._calcData;

    for (let i = 0; i < bandCalcs.times.length; i++) {
      try {
        const upper = bandCalcs.upper[i];
        const lower = bandCalcs.lower[i];
        if (upper > this._maxValue) this._maxValue = upper;
        if (lower < this._minValue) this._minValue = lower;
        bandData[index] = {
          upper,
          lower,
          time: bandCalcs.times[i],
        };
        index += 1;
      } catch (e) {
        console.log("error looping band data", e);
      }
    }
    bandData.length = index;
    this._bandsData = bandData;
    this._upperLower = new UpperLowerInRange(this._bandsData, 4);
  }

  /**
   * Return autoscaleInfo which will be merged with the series base autoscaleInfo. You can use this to expand the autoscale range
   * to include visual elements drawn outside of the series' current visible price range.
   *
   * **Important**: Please note that this method will be evoked very often during scrolling and zooming of the chart, thus it
   * is recommended that this method is either simple to execute, or makes use of optimisations such as caching to ensure that
   * the chart remains responsive.
   *
   * @param startTimePoint - start time point for the current visible range
   * @param endTimePoint - end time point for the current visible range
   * @returns AutoscaleInfo
   */
  autoscaleInfo(startTimePoint: Logical, endTimePoint: Logical): AutoscaleInfo {
    try {
      const ts = this.chart.timeScale();
      const startTime = (ts.coordinateToTime(
        ts.logicalToCoordinate(startTimePoint) ?? 0,
      ) ?? 0) as number;
      const endTime = (ts.coordinateToTime(
        ts.logicalToCoordinate(endTimePoint) ?? 5000000000,
      ) ?? 5000000000) as number;
      const startIndex = this._timeIndices.findClosestIndex(startTime, "left");
      const endIndex = this._timeIndices.findClosestIndex(endTime, "right");
      const range = this._upperLower.getMinMax(startIndex, endIndex);
      return {
        priceRange: {
          minValue: range.lower,
          maxValue: range.upper,
        },
      };
    } catch (e) {
      return {
        priceRange: {
          minValue: 0,
          maxValue: 0,
        },
      };
    }
  }
}

import dayjs from "dayjs";
import { z } from "zod";
import { rolesSchema } from "../../../utils/users";
import { dateSchema, requiredStringSchema } from "../../../utils/forms";
import type { TableSchema } from "../../../utils/table";
import type { TUserRow } from "../../users";

export const subscriptionTier = z.enum([
  "artis_enhanced",
  "artis_enhanced_noexch",
  "artis_lite",
  "artis_professional",
]);

export const usersConfig = {
  id: {
    type: "string",
    schema: z.string(),
    initialHide: true,
  },
  email: {
    type: "string",
    schema: z.string().email(),
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  roles: {
    editable: true,
    type: "string",
    schema: rolesSchema,
    floatingFilter: true,
    filter: "agSetColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  firstname: {
    type: "string",
    schema: requiredStringSchema("First Name"),
    editable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  lastname: {
    editable: true,
    schema: requiredStringSchema("Last Name"),
    type: "string",
    floatingFilter: true,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  username: {
    editable: true,
    schema: requiredStringSchema("Username"),
    type: "string",
    floatingFilter: true,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  blocked: {
    type: "boolean",
    schema: z.boolean().default(false),
    initialHide: true,
    editable: true,
    floatingFilter: true,
    filter: "agSetColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  subscription_tier: {
    type: "string",
    schema: subscriptionTier,
    editable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  },
  last_seen: {
    type: "string",
    schema: dateSchema,
    headerName: "Last Seen",
    filter: "agDateColumnFilter",
    valueParser: (params) => dateSchema.parse(params.newValue),
    tooltipValueGetter: (params) => params.value,
    valueFormatter: (params) => {
      if (!params.value) {
        return null;
      }
      try {
        return dayjs(params.value).fromNow();
      } catch (e) {
        console.error("error formatting date", e, params.value);
      }
      return params.value;
    },
    filterParams: {
      closeOnApply: true,
    },
    valueGetter: (params) => {
      const dateStr = params.data?.sessions[0]?.last_seen;
      if (!dateStr) return null;
      try {
        const date = new Date(dateStr);
        return date;
      } catch (e) {
        console.error("error getting date", e, dateStr);
        return null;
      }
    },
  },
} as const satisfies TableSchema<TUserRow>;

import type { ValueGetterParams } from "ag-grid-community";
import dayjs, { type Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import * as R from "remeda";
import { memoize0, profileEnd, profileStart } from "../../utils";
import { months } from "../globals";

import {
  adhocLookups,
  halfLookups,
  monthCodeLookup,
  monthLookups,
  orderedMonthCodes,
  quarterLookups,
  seasonLookups,
  yearLookups,
} from "./relativeRowHelpers";
import type { TriplitEntity } from "../../triplit/triplit";

// ...refactoring laziness
export {
  adhocLookups,
  halfLookups,
  monthCodeLookup,
  monthLookups,
  orderedMonthCodes,
  quarterLookups,
  seasonLookups,
  yearLookups,
  relativeRowToRowId,
  rowIdToRelativeRow,
} from "./relativeRowHelpers";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const defaultMonthsRangeSettings = months;

function getMonthRowIds(numMonths: number) {
  const currentMonth = dayjs().startOf("month");
  const currentMonthIndex: number = currentMonth.month();
  const currentYear: number = currentMonth.year() % 100;

  return Array.from({ length: numMonths }, (_, i) => {
    const offsetMonthIndex: number = (currentMonthIndex + i) % 12;
    const offsetYear: number =
      currentYear + Math.floor((currentMonthIndex + i) / 12);

    const monthCode: string = orderedMonthCodes[offsetMonthIndex];
    const yearCode: string = offsetYear.toString().padStart(2, "0");

    return `${monthCode}${yearCode}`;
  });
}

export const monthRowIds = getMonthRowIds(defaultMonthsRangeSettings);

export const monthCodeInverseLookup = R.invert(monthCodeLookup);

export const currentMonth = dayjs().utc(true).startOf("month");

export function rowStringToCode(rowStr: string) {
  if (rowStr) {
    const full = rowStr.substring(0, 4).toUpperCase() === "FULL";
    const rowString = full ? rowStr.substring(5) : rowStr;
    const first3 = rowString.substring(0, 3) as keyof typeof monthCodeLookup;
    const monthPart = monthCodeLookup[first3] || first3;
    const yearPart = rowString.slice(-2);
    const codeStr = `${monthPart}${yearPart}`.replace(" ", "");
    return full ? `FULL ${codeStr}` : codeStr;
  }
  throw new Error("rowStr is undefined in rowStringToCode function");
}

/**
 * Converts a month code like 'F23' to a month string in the format YYYY-MM-DD.
 *
 * @param code - The month code.
 * @returns - The month string in the format YYYY-MM-DD.
 * @throws - If the code is invalid.
 */
export function monthCodeToMonthString(code: string): string {
  // Validate the code format
  if (code.length !== 3) {
    throw new Error("Code must be 3 characters long");
  }

  // Extract the year and month code from the input
  const monthCode = code.charAt(0);
  const year = `20${code.substring(1)}`; // Extract the year assuming it's in the 2000s

  // Find the month index using the monthCode
  const monthIndex = orderedMonthCodes.findIndex((mc) => mc === monthCode);
  if (monthIndex === -1) {
    throw new Error(`Invalid month code: ${monthCode}`);
  }

  // Convert month index to a month number string with leading zero if necessary
  const month = (monthIndex + 1).toString().padStart(2, "0");

  // Construct and return the month string in the format YYYY-MM-DD
  return `${year}-${month}-01`;
}

function currentMonthToCode(currentMonth: Dayjs) {
  const formattedMonth = currentMonth.format("MMM").toUpperCase();
  const formattedYear = currentMonth.format("YY");
  return rowStringToCode(`${formattedMonth}${formattedYear}`);
}

const currentMonthCode = currentMonthToCode(currentMonth);

const emptyMonths = Array.from(
  { length: defaultMonthsRangeSettings },
  (_, i) => i,
);

const monthCodeToOffsetIndex = Object.fromEntries(
  emptyMonths.map((i) => {
    const monthCode = genMonthCodeWithOffset(currentMonthCode, i);
    return [monthCode, i];
  }),
);

const offsetToMonthCodeIndex = R.invert(monthCodeToOffsetIndex);

export function monthCodeFromOffset(offset: number) {
  return offsetToMonthCodeIndex[offset];
}

const shortMonthToOffsetIndex = Object.fromEntries(
  emptyMonths.map((i) => {
    const monthCode = genMonthCodeWithOffset(currentMonthCode, i);
    const shortMonth = monthCodeToShortMonthAndYear(monthCode);

    return [shortMonth, i];
  }),
);

const longMonthToOffsetIndex = Object.fromEntries(
  emptyMonths.map((i) => {
    const monthCode = genMonthCodeWithOffset(currentMonthCode, i);
    const longMonth = monthCodeToMonthString(monthCode);

    return [longMonth, i];
  }),
);

const rowToOffset = R.mergeAll([
  monthCodeToOffsetIndex,
  shortMonthToOffsetIndex,
  longMonthToOffsetIndex,
]);

console.log("rowToOffset", rowToOffset);

export const offsetToRow = R.invert(longMonthToOffsetIndex);

export function relativeRowToRowType(rowId: string) {
  if (monthLookups[rowId]) {
    return "mth";
  }
  if (quarterLookups[rowId]) {
    return "qtr";
  }
  if (halfLookups[rowId]) {
    return "hlv";
  }
  if (seasonLookups[rowId]) {
    return "season";
  }
  if (yearLookups[rowId]) {
    return "cal";
  }
  if (adhocLookups[rowId]) {
    return "adhoc";
  }

  throw new Error("Could not find row type for rowId");
}

export function nthNextMonths(monthRows: Dayjs[]) {
  return monthRows.map((m, idx) => {
    const monthCount = idx + 1;
    const formattedMonth = m.format("MMM").toUpperCase();
    const formattedYear = m.format("YY");
    return [monthCount, `${formattedMonth}${formattedYear}`] as const;
  });
}

export function genMonthList(n: number, date: Dayjs) {
  return Array.from({ length: n }, (_, offset) => {
    return dayjs(date).add(offset, "month");
  });
}

function dateToFormattedBy(date: Dayjs, period: string, numMonths: number) {
  if (!date)
    throw new Error("`date` is undefined in dateToFormattedBy function");

  const monthInt = dayjs(date).month() + 1;
  const year = dayjs(date).format("YY");
  return `${period}${Math.floor((monthInt - 1) / numMonths) + 1} ${year}`;
}

function dateToFormattedQtr(date: Dayjs) {
  return dateToFormattedBy(date, "Q", 3);
}

function nthNextPeriods(
  dates: Dayjs[],
  showCurrent: boolean | undefined,
  formatter: (date: Dayjs) => string,
) {
  return Array.from(new Set(dates.map(formatter)))
    .map((q, idx) => [idx + 1, q] as const)
    .slice(showCurrent ? 0 : 1);
}

export function nthNextQtrs(dates: Dayjs[], showCurrent: boolean | undefined) {
  return nthNextPeriods(dates, showCurrent, dateToFormattedQtr);
}

function dateToFormattedHlv(date: Dayjs) {
  return dateToFormattedBy(date, "H", 6);
}

export function nthNextHlvs(dates: Dayjs[], showCurrent: boolean | undefined) {
  return nthNextPeriods(dates, showCurrent, dateToFormattedHlv);
}

function dateToFormattedSeason(date: Dayjs) {
  if (!date)
    throw new Error("`date` is undefined in dateToFormattedSeason function");
  const month = dayjs(date).month() + 1;
  if (month < 4) {
    return `Win ${dayjs(date).subtract(1, "year").format("YY")}`;
  }
  if (month < 10) {
    return `Sum ${dayjs(date).format("YY")}`;
  }
  return `Win ${dayjs(date).format("YY")}`;
}

export function nthNextSeasons(
  dates: Dayjs[],
  showCurrent: boolean | undefined,
) {
  return nthNextPeriods(dates, showCurrent, dateToFormattedSeason);
}

function dateToFormattedCal(date: Dayjs) {
  if (!date)
    throw new Error("`date` is undefined in dateToFormattedCal function");

  return `Cal ${dayjs(date).format("YY")}`;
}

export function nthNextCals(dates: Dayjs[], showCurrent: boolean | undefined) {
  return nthNextPeriods(dates, showCurrent, dateToFormattedCal);
}

export function allPeriodsCount({
  months,
  qtrs,
  qtrCurrent,
  hlvs,
  hlvCurrent,
  seasons,
  seasonCurrent,
  cals,
  calCurrent,
}: Pick<
  TriplitEntity<"gridSettings">,
  | "months"
  | "qtrs"
  | "qtrCurrent"
  | "hlvs"
  | "hlvCurrent"
  | "seasons"
  | "seasonCurrent"
  | "cals"
  | "calCurrent"
>) {
  const date = dayjs();

  const monthsUserLimit = months || defaultMonthsRangeSettings;
  const monthListMonths = genMonthList(monthsUserLimit, date);
  const monthRows = nthNextMonths(monthListMonths);

  const qtrsInMonths = 3;
  const qtrsUserLimit = qtrs ? qtrs * qtrsInMonths : defaultMonthsRangeSettings;
  const monthListQtrs = genMonthList(qtrsUserLimit - (qtrsInMonths - 1), date);
  const qtrRows = nthNextQtrs(monthListQtrs, qtrCurrent);

  const hlvsInMonths = 6;
  const hlvsUserLimit = hlvs ? hlvs * hlvsInMonths : defaultMonthsRangeSettings;
  const monthListHlvs = genMonthList(hlvsUserLimit - (hlvsInMonths - 1), date);
  const hlvRows = nthNextHlvs(monthListHlvs, hlvCurrent);

  const seasonsInMonths = 6;
  const seasonsUserLimit = seasons ? seasons * 6 : defaultMonthsRangeSettings;
  const monthListSeasons = genMonthList(
    seasonsUserLimit - (seasonsInMonths - 1),
    date,
  );
  const seasonRows = nthNextSeasons(monthListSeasons, seasonCurrent);

  const calsInMonths = 12;
  const calsUserLimit = cals ? cals * calsInMonths : defaultMonthsRangeSettings;
  const monthListCals = genMonthList(calsUserLimit - (calsInMonths - 1), date);
  const calRows = nthNextCals(monthListCals, calCurrent);

  return {
    months: monthRows,
    qtrs: qtrRows,
    hlvs: hlvRows,
    seasons: seasonRows,
    cals: calRows,
  };
}

/**
 * Converts a month code string in the format YYYY-MM-DD to a month code.
 *
 * @param monthStr - The month code string in the format YYYY-MM-DD.
 * @returns - The month code.
 * @throws - If the month code string is undefined.
 */
export function monthStringToCode(monthStr: string) {
  if (monthStr.length !== 10) {
    console.warn("monthStr is not 10 characters long");
    return;
  }
  try {
    const month = Number.parseInt(monthStr.substring(5, 7));
    const code = orderedMonthCodes[month - 1];
    const year = monthStr.substring(2, 4);
    return code + year;
  } catch (error) {
    throw new Error(`Error in monthStringToCode: ${monthStr} ${error}`);
  }
}

export type TBaseColumn = {
  id: string;
  period: string;
  code: string;
  firstOfType?: boolean;
};

export type TMonthColumn = {
  rowType: "mth";
  lastRow?: boolean;
  quarter?: boolean;
  monthString: string;
} & TBaseColumn;

export type TQrtColumn = {
  rowType: "qtr";
  lastRow?: boolean;
  current?: boolean;
} & TBaseColumn;

export type THlvColumn = {
  rowType: "hlv";
  lastRow?: boolean;
  current?: boolean;
} & TBaseColumn;

export type TSeasonColumn = {
  rowType: "season";
  lastRow?: boolean;
  current?: boolean;
} & TBaseColumn;

export type TCalColumn = {
  rowType: "cal";
  lastRow?: boolean;
  current?: boolean;
} & TBaseColumn;

export type TAdhocColumn = {
  rowType: "adhoc";
  lastRow?: boolean;
  formula?: string;
  firstOfType?: boolean;
} & TBaseColumn;

export type TPeriodColumn =
  | TMonthColumn
  | TQrtColumn
  | THlvColumn
  | TSeasonColumn
  | TCalColumn
  | TAdhocColumn;

const startsWithMonthCache: Record<string, boolean> = {};
export function startsWithMonth(s: string) {
  return memoize0(startsWithMonthCache, s, () =>
    /^(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)/.test(s.toUpperCase()),
  );
}

const dateParseCache: Record<string, string> = {};

const MMMToNum = {
  JAN: "01",
  FEB: "02",
  MAR: "03",
  APR: "04",
  MAY: "05",
  JUN: "06",
  JUL: "07",
  AUG: "08",
  SEP: "09",
  OCT: "10",
  NOV: "11",
  DEC: "12",
} as const;

function monthToNumber(month: string) {
  if (!month || !(month in MMMToNum)) {
    throw new Error("month is undefined in monthToNumber function");
  }
  // cast because typescript too dumb to understand the above
  return MMMToNum[month as keyof typeof MMMToNum];
}

export function parseAndFormatDate(gridMonth: string) {
  const res = memoize0(dateParseCache, gridMonth, () => {
    if (!gridMonth) {
      console.log("gridMonth is undefined in parseAndFormatDate function");
      return "";
    }
    profileStart("parseAndFormatDate");
    try {
      if (gridMonth.startsWith("20")) {
        return `${dayjs(gridMonth).utc(true).format("YYYY-MM")}-01`;
      }
      const month = monthToNumber(gridMonth.substring(0, 3));
      const year = `20${gridMonth.substring(3)}`;

      const res = `${dayjs(`${year}-${month}-01`)
        .utc(true)
        .format("YYYY-MM")}-01`;
      profileEnd("parseAndFormatDate");
      return res;
    } catch (error) {
      throw new Error(`Error in parseAndFormatDate: ${gridMonth} ${error}`);
    }
  });
  return res;
}

/**
 * Given a month string, return the number of months in the future that month is.
 * Note that the webapp will restart every day,
 * so we can assume that the current date is always the current date.
 *
 * @param month - month in the format "MMMYY" or "YYYY-MM-DD" or monthCode i.e. F24.
 *
 * @returns {number} - The offset as the difference in months between the current date-time and the target date-time.
 */
export function getOffset(month: string): number {
  if (!month) {
    throw new Error("month is undefined in getOffset function");
  }
  const offset = rowToOffset[month];

  if (!offset && offset !== 0) throw new Error(`Offset not found for ${month}`);

  return offset;
}

export function monthCodeToShortMonth(monthCode: string) {
  const firstLetter = monthCode.charAt(
    0,
  ) as keyof typeof monthCodeInverseLookup;
  return (monthCodeInverseLookup[firstLetter] || "").toUpperCase();
}

export function monthCodeToShortMonthAndYear(monthCode: string) {
  const month = monthCodeToShortMonth(monthCode);
  const year = monthCode.slice(-2);
  return `${month}${year}`;
}

const monthCodeToOffsetToCache: Record<string, string> = {};

export function monthCodeToOffset(monthCode: string) {
  const res = memoize0(monthCodeToOffsetToCache, monthCode, () => {
    const month = monthCodeToShortMonth(monthCode);
    // monthCode has 3 chars.
    // the first one identifies the month
    // the last two the year
    const year = monthCode.slice(-2);
    return `${month}${year}`;
  });

  return getOffset(res);
}

const parseNextPeriod = (
  nextPeriod: ReturnType<typeof nthNextMonths>,
  period: string,
) => {
  const secondValues = nextPeriod.map((item) => item[1]);
  const foundIndex = secondValues.findIndex((value) => value === period);
  if (foundIndex !== -1 && foundIndex + 1 < secondValues.length) {
    return secondValues[foundIndex + 1];
  }
};

function genTimespreadData(months?: number) {
  const monthList = genMonthList(months || defaultMonthsRangeSettings, dayjs());

  const nextMonths = nthNextMonths(monthList);
  const nextQtrs = nthNextQtrs(monthList, true);
  const nextHlvs = nthNextHlvs(monthList, true);
  const nextCal = nthNextCals(monthList, true);
  const nextSeasons = nthNextSeasons(monthList, true);

  return {
    nextMonths,
    nextQtrs,
    nextHlvs,
    nextCal,
    nextSeasons,
  };
}

export const timespreadData = genTimespreadData(months);

export function monthTimespreadValueGetter(
  params: ValueGetterParams,
  monthData: {
    nextMonths: ReturnType<typeof nthNextMonths>;
    nextQtrs: ReturnType<typeof nthNextQtrs>;
    nextHlvs: ReturnType<typeof nthNextHlvs>;
    nextCal: ReturnType<typeof nthNextCals>;
    nextSeasons: ReturnType<typeof nthNextSeasons>;
  },
) {
  const data = params.data;
  const rowType = data.rowType;
  const period = data.period;
  const code = data.code || "";

  if (!period) return "";

  const parseMonth = parseNextPeriod(monthData.nextMonths, period) || "";
  const parseQtr = parseNextPeriod(monthData.nextQtrs, period) || "";
  const parseHlv = parseNextPeriod(monthData.nextHlvs, period) || "";
  const parseCal = parseNextPeriod(monthData.nextCal, period) || "";
  const parseSeason = parseNextPeriod(monthData.nextSeasons, period) || "";

  if (rowType === "adhoc") {
    if (period === " " && code === " ") {
      return " ";
    }
    return code ? `${period}/${code}` : period;
  }
  switch (rowType) {
    case "mth":
      return `${period}/${parseMonth}`;
    case "qtr":
      return `${period}/${parseQtr}`;
    case "hlv":
      return `${period}/${parseHlv}`;
    case "cal":
      return `${period}/${parseCal}`;
    case "season":
      return `${period}/${parseSeason}`;
    default:
      return period;
  }
}

export function genMonthCodeWithOffset(
  startMonth: string,
  monthOffsetInt: number,
) {
  const [monthCode, yTens, yOnes] = startMonth.split("");
  const startMonthIndex = orderedMonthCodes.indexOf(monthCode);
  const monthOffsetSteps = startMonthIndex + monthOffsetInt;

  // Calculate the new month index considering the cycle through the months
  const monthCodeIndex = ((monthOffsetSteps % 12) + 12) % 12; // Ensure positive index
  const offsetMonthCode = orderedMonthCodes[monthCodeIndex];

  // Calculate year change. Each step of 12 months equals one year.
  // This calculation correctly handles negative offsets.
  const yearsChange = Math.floor(monthOffsetSteps / 12);
  const offsetYears = Number.parseInt(yTens + yOnes, 10) + yearsChange;

  return `${offsetMonthCode}${offsetYears}`;
}

export function formatMonthColumnText(month: string | undefined | null) {
  if (month) {
    // ensure there is a space before the year
    const formatted =
      month.length > 3 && month[month.length - 3] !== " "
        ? `${month.slice(0, -2)} ${month.slice(-2)}`
        : month;
    return formatted;
  }
  return "";
}

export const liveChartsPeriods = nthNextMonths(genMonthList(35, dayjs())).map(
  (m) => {
    const mth = m[1];
    const parsed = `${parseAndFormatDate(mth)}T00:00:00.000000Z`;
    return {
      label: mth,
      value: parsed,
    };
  },
);

export function formatAdhocText({
  from,
  to,
}: {
  from?: string | null;
  to?: string | null;
}) {
  const fromText = !from || from?.trim() === "" ? "-" : from;
  const toText = !to || to?.trim() === "" ? "-" : to;
  return `${fromText}/${toText}`;
}

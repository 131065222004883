import type { TAgreementType } from "../licensing/licensing";
import { useUserId } from "../../context/auth";
import {
  useSources,
  useUserPermissions,
  useUserExchangeUsage,
  usePageProducts,
} from "../../data";

export const sourceToAgreementTypeMap: Record<string, TAgreementType> = {
  "CME NYMEX": "CME_NYMEX",
  "ICE FUTURES EUROPE": "ICE_FUTURES_EUROPE",
  "ICE FUTURES ABU DHABI": "ICE_FUTURES_ABU_DHABI",
  "ICE ENDEX": "ICE_ENDEX",
  IDS: "IDS",
  tpicapXC: "TP_ICAP",
  artisXC: "ARTIS_XC",
};

export const agreementTypeToSourceMap: Record<TAgreementType, string> =
  Object.keys(sourceToAgreementTypeMap).reduce(
    (acc, key) => {
      const agreementType = sourceToAgreementTypeMap[key];
      acc[agreementType] = key;
      return acc;
    },
    {} as Record<TAgreementType, string>,
  );

export function useLicenceAgreement() {
  const userId = useUserId();
  const exchangeUsage = useUserExchangeUsage(userId);
  const permissions = useUserPermissions(userId);
  const products = usePageProducts();
  const sources = useSources();

  if (products.data && products.data.length === 0) return {};
  if (!exchangeUsage.data || !permissions.data || !sources.data) return {};

  const signedLicenseAgreementsStart = (
    exchangeUsage.data?.exchange_usage_last || []
  ).filter((agreement) => agreement.event === "start");

  const filteredPermissions =
    permissions.data?.permission
      ?.filter((perm) => {
        const sourceName = perm.packageByPackage?.sourceBySource?.name;
        const source = sources.data?.find((s) => s.name === sourceName);

        if (!sourceName || !source) {
          return false;
        }

        const isSigned = signedLicenseAgreementsStart.some(
          (agreement) => agreement.source === source.id,
        );

        return (
          source &&
          !isSigned &&
          sourceToAgreementTypeMap[sourceName] !== undefined
        );
      })
      ?.map((perm) => {
        const sourceName = perm.packageByPackage?.sourceBySource?.name;
        const agreementName = perm.packageByPackage?.name;

        if (!sourceName || !agreementName) {
          return null;
        }

        return {
          name: sourceToAgreementTypeMap[sourceName],
          agreement: agreementName,
        };
      })
      .filter((perm): perm is { name: TAgreementType; agreement: string } => {
        return (
          perm !== null &&
          perm.name !== undefined &&
          perm.agreement !== undefined
        );
      }) || [];

  const licenceAgreement = filteredPermissions[0]?.name;
  const originalSourceName = licenceAgreement
    ? agreementTypeToSourceMap[licenceAgreement]
    : null;

  const selectedSource = sources.data?.find(
    (source) => source.name === originalSourceName,
  );

  const sourceId = selectedSource?.id;

  return { licenceAgreement, sourceId };
}

import {
  MismatchDirection,
  type ISeriesApi,
  type ITimeScaleApi,
  type Time,
} from "lightweight-charts";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import dayjs from "dayjs";

import {
  buildHistoricalChartDataQueryKey,
  fetchQuestData,
  parseQuestDBData,
} from "./helpers";
import { defaultSelector } from "../../market-grid/contextMenuHelpers";
import type { TInfiniteQueryData, TLiveChart } from "./types";
import {
  genDatesBasedOnBars,
  sampleConfig,
  sampleTimeToSeconds,
  type TSample,
  validSampleOrFirst,
} from "../utils";

export function useHistoricalChartData({
  chartMetadata,
  chart,
  timeScale,
}: {
  chartMetadata: TLiveChart;
  chart: ISeriesApi<"Custom"> | null;
  timeScale: ITimeScaleApi<Time> | null;
}) {
  const { productId, periodFrom } = chartMetadata;
  const sampleTime = validSampleOrFirst(chartMetadata.sampleTime);

  if (!productId || !sampleTime) {
    throw new Error("productId and sampleTime are required");
  }

  // When the hook first runs, "timeScale" is null, so initialPageParams will have the numberOfBars as 0, so it won't actually be the first initial request.
  const [initial, setInitial] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const artisType = chartMetadata.artisType || "global";
  const field = defaultSelector(artisType);
  const isCalc = field === "value";

  return useInfiniteQuery({
    queryKey: buildHistoricalChartDataQueryKey({
      productId,
      sampleTime,
      periodFrom,
    }),
    meta: { persist: false },
    gcTime: 1000 * 60 * 60,
    initialPageParam: {
      numberOfBars: getNumberOfBars(chart, timeScale, sampleTime),
    },
    enabled: !!(productId && sampleTime && artisType),
    queryFn: async ({ pageParam }) => {
      // While the chart is loading, "timeScale" will be null, so we can't calculate the number of bars and should not waste a request.
      if (pageParam.numberOfBars === 0) {
        return {
          data: [],
          fromUTCDate: "",
          toUTCDate: "",
          timeTaken: 0,
        };
      }

      const { fromDate, toDate } = genDatesBasedOnBars(
        pageParam.numberOfBars,
        sampleTime,
      );
      const token = await getAccessTokenSilently();
      const res = await fetchQuestData({
        token,
        field,
        periodFrom: periodFrom || "",
        fromUTCDate: fromDate,
        toUTCDate: initial ? new Date().toISOString() : toDate,
        product: productId,
        sampleTime,
        isCalc,
      });
      setInitial(false);
      console.log("fetching historical data...", res, pageParam);
      return res;
    },
    getPreviousPageParam: () => {
      const numberOfBars = getNumberOfBars(chart, timeScale, sampleTime);
      return {
        numberOfBars,
      };
    },
    getNextPageParam: () => {
      const numberOfBars = getNumberOfBars(chart, timeScale, sampleTime);
      return {
        numberOfBars,
      };
    },
    select,
  });
}

function select(data: TInfiniteQueryData) {
  const dataMap = new Map<number, ReturnType<typeof parseQuestDBData>[0]>();

  for (const page of data.pages) {
    if (page.data.length > 0) {
      const pageData = page.data[0] || [];
      const parsedPageData = parseQuestDBData(pageData);
      for (const item of parsedPageData) {
        if (item && item.time != null && item.close != null) {
          dataMap.set(item.time, item);
        }
      }
    }
  }

  const uniqueData = Array.from(dataMap.values());
  uniqueData.sort((a, b) => a.time - b.time);
  return uniqueData;
}

export function getNumberOfBars(
  chart: ISeriesApi<"Custom"> | null,
  timeScale: ITimeScaleApi<Time> | null,
  sampleTime: TSample,
) {
  const minBars = sampleConfig[sampleTime].minBars;
  const barsAdjustmentMultiplier = 1.2;

  const visibleLogicalRange = timeScale?.getVisibleLogicalRange();

  const visibleRangeBars = visibleLogicalRange
    ? chart?.barsInLogicalRange(visibleLogicalRange)
    : undefined;

  if (!visibleRangeBars) return 0;

  const visibleBars = Number(
    Math.round(
      Math.abs(visibleRangeBars.barsBefore) +
        Math.abs(visibleRangeBars.barsAfter),
    ),
  );

  const rightmostBarTime = chart?.dataByIndex(
    Number.MAX_SAFE_INTEGER,
    MismatchDirection.NearestLeft,
  )?.time;

  const leftmostVisibleTime = visibleRangeBars?.from;
  const rightmostVisibleTime = visibleRangeBars?.to;

  if (!rightmostBarTime || !rightmostVisibleTime || !leftmostVisibleTime) {
    console.log("No data to calculate visible bars");
    return visibleBars + visibleBars * barsAdjustmentMultiplier;
  }

  const leftmostBarTimeDate = dayjs(Number(leftmostVisibleTime) * 1000);
  const rightmostVisibleTimeDate = dayjs(Number(rightmostVisibleTime) * 1000);

  const visibleRangeDiff = rightmostVisibleTimeDate.diff(
    leftmostBarTimeDate,
    "second",
  );

  const secondsInSample = sampleTimeToSeconds[sampleTime];

  const barsInVisibleRange = Math.abs(
    Math.floor(visibleRangeDiff / secondsInSample),
  );

  const barsWithAfter =
    barsInVisibleRange + Math.abs(visibleRangeBars.barsAfter);

  const numberOfBars = barsWithAfter + barsWithAfter * barsAdjustmentMultiplier;

  return (
    (numberOfBars < visibleBars + barsInVisibleRange
      ? Math.max(visibleBars, numberOfBars)
      : numberOfBars) + minBars
  );
}

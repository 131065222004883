import { Table } from "../components/Table";
import { useNavigate, useParams } from "@tanstack/react-router";
import {
  type BlockedSuccessProps,
  type TUserRow,
  UserFragment,
  genRolesForServer,
  useSetBlocked,
  mutateUser,
  mutateUsersBulk,
  updateUsersRolesAuth0,
  useUsers,
  logoutUsersMutation,
} from "./hooks";
import * as R from "remeda";
import { QueryAllStates } from "../components/QueryAllStates";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import type { TResetMFABody } from "../../../api/reset-mfa.mjs";
import { useAuth0Config } from "../../context/auth";
import {
  CurrentEdits,
  FloatingContainer,
  ManageEdits,
  UndoSaving,
} from "../components/FloatingSave";
import { Box, Button } from "@mui/joy";
import { useMutation } from "@apollo/client";
import { useFloatingSave } from "../hooks/useFloatingSave";
import { useCallback, useMemo, useState } from "react";
import type {
  CellClassParams,
  ColDef,
  RowClassParams,
} from "ag-grid-community";
import { readFragment } from "../../graphql";
import { colDefId, redrawRowsByIds } from "../../tableUtils";
import {
  type TEditablePendingUserKeys,
  parseRoles,
  tableEditableKeys,
  upsertExistingUser,
  parseSubscriptionTier,
  orderedSubscriptionTiers,
} from "../../utils/users";
import { editableUserKeys } from "../stores/orgStore/usersEdits";
import { nanoid } from "nanoid";
import ResetPasswordModal from "./ResetPasswordModal";
import { FaUser, FaUserPlus } from "react-icons/fa";
import { dateSchema } from "../../utils/forms";
import { client } from "../../triplit/triplit";
import { useAuth0 } from "@auth0/auth0-react";
import { UserGroupsMultiSelectAgGridEditor } from "../components/UserGroupsMultiSelectAgGridEditor";
import { useAuditLoggerFactory } from "../audit";
import { UserRolesMultiSelectAgGridEditor } from "../components/RolesSelect";
import { formatEdits } from "../components/floatingSaveHelpers";
import toast from "react-hot-toast";
import { z } from "zod";

import { roleToLabel } from "../components/multiSelectHelpers";
import { useQuery } from "@triplit/react";
import { PartiallyCreatedEntities } from "../components/PartiallyCreatedEntities";
import { CreateEntityButton } from "../components/CreateEntityButton";
import { useThemeMode } from "../../context/theme";
import { useGrid } from "../../webapp/market-grid/stores";
dayjs.extend(relativeTime);

export const subscriptionTier = z.enum([
  "artis_enhanced",
  "artis_enhanced_noexch",
  "artis_lite",
  "artis_professional",
]);

export function UsersTable() {
  const {
    orgId,
    users: { data: usersData, serverData, pendingUsers, fetching, error },
  } = useUsers();

  const [openResetPasswordModal, setOpenResetPasswordModal] =
    useState<boolean>(false);

  const [currentSelectedUser, setCurrentSelectedUser] = useState<string>();

  const [executeUsersBulkMutation] = useMutation(mutateUsersBulk);
  const [executeUserMutation] = useMutation(mutateUser);
  const [executeLogoutUsersMutation] = useMutation(logoutUsersMutation);

  const currentRoute = "/admin/orgs/$org/users";
  const { org } = useParams({ from: currentRoute });
  const navigate = useNavigate({ from: currentRoute });
  const { auth0_url, auth0_org_id, env } = useAuth0Config();

  const { handleSetBlocked } = useSetBlocked();

  const auditFactory = useAuditLoggerFactory();

  const mode = useThemeMode();

  const cellStyle = useCallback(
    (params: CellClassParams<(typeof usersData)[number]>) => {
      const id = params?.data?.id;
      const field = params?.colDef?.field;
      if (!id || !field) return { backgroundColor: "transparent" };
      const strictField = field as TEditablePendingUserKeys;
      const singlePendingUser = pendingUsers?.find((user) => user.id === id);
      const editingValue = singlePendingUser?.[strictField];

      if (editingValue) {
        return {
          backgroundColor:
            mode === "dark" ? "rgb(33, 33, 35)" : "rgb(236 236 245)",
        };
      }
      return { backgroundColor: "transparent" };
    },
    [pendingUsers, mode],
  );

  const colDefs = useMemo<ColDef<TUserRow>[]>(() => {
    return [
      colDefId({
        field: "id",
        initialHide: true,
      }),
      {
        field: "email",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        floatingFilter: true,
        filter: "agTextColumnFilter",
        suppressFloatingFilterButton: true,
      },
      {
        field: "roles",
        editable: true,
        floatingFilter: true,
        cellStyle,
        filter: "agSetColumnFilter",
        suppressFloatingFilterButton: true,
        cellEditor: UserRolesMultiSelectAgGridEditor,
        valueFormatter: (params) => {
          const roles = params?.data?.roles;
          return roles?.map((role) => roleToLabel(role)).join(", ") || "";
        },
        valueParser: (params) => {
          return new Set(params.newValue);
        },
      },
      {
        field: "groups",
        editable: true,
        floatingFilter: true,
        cellStyle,
        filter: "agSetColumnFilter",
        suppressFloatingFilterButton: true,
        cellEditor: UserGroupsMultiSelectAgGridEditor,
        valueFormatter: (params) => {
          const groupNames = params?.data?.groups;
          return groupNames?.join(", ") || "";
        },
        valueParser: (params) => {
          return new Set(params.newValue);
        },
      },
      {
        field: "firstname",
        editable: true,
        cellStyle,
        floatingFilter: true,
        filter: "agTextColumnFilter",
        suppressFloatingFilterButton: true,
      },
      {
        field: "lastname",
        editable: true,
        cellStyle,
        floatingFilter: true,
        filter: "agTextColumnFilter",
        suppressFloatingFilterButton: true,
      },
      {
        field: "username",
        editable: true,
        cellStyle,
        floatingFilter: true,
        filter: "agTextColumnFilter",
        suppressFloatingFilterButton: true,
      },
      {
        field: "blocked",
        initialHide: true,
        floatingFilter: true,
        filter: "agTextColumnFilter",
        suppressFloatingFilterButton: true,
      },
      {
        field: "subscription_tier",
        floatingFilter: true,
        editable: true,
        filter: "agTextColumnFilter",
        suppressFloatingFilterButton: true,
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: orderedSubscriptionTiers,
        },
      },
      {
        headerName: "Last Seen",
        colId: "last_seen",
        filter: "agDateColumnFilter",
        valueParser: (params) => dateSchema.parse(params.newValue),
        tooltipValueGetter: (params) => params.value,
        valueFormatter: (params) => {
          if (!params.value) {
            return null;
          }
          try {
            return dayjs(params.value).fromNow();
          } catch (e) {
            console.error("error formatting date", e, params.value);
          }
          return params.value;
        },
        filterParams: {
          closeOnApply: true,
        },
        valueGetter: (params) => {
          const dateStr = params.data?.sessions[0]?.last_seen;
          if (!dateStr) return null;
          try {
            const date = new Date(dateStr);
            return date;
          } catch (e) {
            console.error("error getting date", e, dateStr);
            return null;
          }
        },
      },
    ];
  }, [cellStyle]);

  async function handleSetBlockedSuccess({
    userIds,
    shouldBlock,
  }: BlockedSuccessProps) {
    const audit = auditFactory({
      actionName: "editUserBlocked",
      clearanceLevel: "umi-internal-write",
    });

    audit.start(
      `Updating blocked for user(s) in org ${orgId}: ${userIds.join(", ")} -> ${
        shouldBlock ? "blocked" : "unblocked"
      }`,
    );

    const { data, errors } = await executeUsersBulkMutation({
      variables: {
        ids: userIds,
        user: {
          blocked: shouldBlock,
        },
      },
    });

    redrawRowsByIds(api, userIds);

    if (errors) {
      audit.error("Error updating user(s) blocked");
      return;
    }

    audit.success();

    console.log("data", data);
  }

  async function onSetBlocked({
    userIds,
    shouldBlock,
  }: {
    userIds: string[];
    shouldBlock: boolean;
  }) {
    await handleSetBlocked({
      enabled: userIds.length > 0,
      userIds,
      shouldBlock,
      onSuccess: handleSetBlockedSuccess,
      env,
      auth0_url,
    });
  }

  async function handleResetMFA({
    selectedData,
  }: {
    selectedData: TUserRow[];
  }) {
    const userIds = selectedData.map((user) => user.id);
    const body = {
      userIds,
      env,
      auth0_url,
    } satisfies TResetMFABody;

    const audit = auditFactory({
      actionName: "resetMFA",
      clearanceLevel: "umi-internal-write",
    });

    audit.start(
      `Resetting MFA for user(s) in org ${orgId}: ${userIds.join(", ")}`,
    );

    const res = await fetch("/api/reset-mfa", {
      method: "POST",
      body: JSON.stringify(body),
    });

    if (res.status !== 200) {
      audit.error("Error resetting MFA");
      // TODO toast error
      console.error("error resetting mfa", res);
      return;
    }
    audit.success();
    // TODO toast success
    console.log("successfully reset mfa", res);
  }

  async function handleLogout({ selectedData }: { selectedData: TUserRow[] }) {
    const logoutUsers = selectedData.map((user) => user.id);

    const audit = auditFactory({
      actionName: "logoutOtherUser",
      clearanceLevel: "umi-internal-write",
    });

    audit.start(
      `Logging out the following user(s) in org ${orgId}: ${logoutUsers.join(
        ", ",
      )}`,
    );

    const res = await toast.promise(
      executeLogoutUsersMutation({
        variables: {
          logoutUsers: logoutUsers.map((id) => ({
            folio_user: id,
            context: "all",
          })),
        },
      }),
      {
        loading: `Logging out ${logoutUsers.length} user(s)...`,
        success: `Successfully logged out ${logoutUsers.length} user(s).`,
        error: `Error logging out ${logoutUsers.length} user(s).`,
      },
    );

    if (res.errors) {
      console.error("Error logging out users: ", res.errors);
      audit.error("Error logging out users");
    } else {
      console.log("Successfully logged out users: ", res);
      audit.success();
    }
  }

  const { isWaiting, cancelSaveFn, floatingSaveFn } = useFloatingSave();
  const [api] = useGrid();

  const getRowClass = useCallback((params: RowClassParams) => {
    const blocked = params.data?.blocked;
    const lastSeenDateStr = params.data?.sessions[0]?.last_seen;
    const lastSeenDate = new Date(lastSeenDateStr);
    const active = dayjs().diff(lastSeenDate, "minute") < 2;
    return [blocked ? "grid-row-blocked" : "", active ? "grid-row-active" : ""];
  }, []);

  async function storeEditsToHasura(
    editedRows: NonNullable<typeof pendingUsers>,
    originalData: typeof serverData,
  ) {
    const parseEditedRows = editedRows.map((allValues) => {
      const id = allValues.id;
      const editableKeys = tableEditableKeys(allValues);
      const values = R.pickBy(
        allValues,
        (val, key) => editableKeys.includes(key) && val !== null,
      );

      const roles = values.roles?.size ? parseRoles(values.roles) : undefined;

      const editedRow = {
        id,
        user: {
          ...values,
          roles,
        },
      };

      if (!editedRow.user.roles) {
        editedRow.user.roles = undefined;
      }

      return editedRow;
    });

    const usersRoles = parseEditedRows
      .filter((row) => row.user.roles?.size)
      .map((row) => {
        const originalRoles = originalData.find((d) => d.id === row.id)?.roles;
        const roles = parseRoles(originalRoles);
        return genRolesForServer(row, roles);
      });

    const updateRolesRes = await updateUsersRolesAuth0({
      roles: usersRoles,
      env,
      auth0_url,
      auth0_org_id,
    });

    const parsedEditedRowsToUpdate = parseEditedRows.filter((row) => {
      const rowId = row.id;
      return !updateRolesRes?.find((r) => r.id === rowId)?.error;
    });

    const audit = auditFactory({
      actionName: "bulkEditUser",
      clearanceLevel: "umi-internal-write",
    });

    audit.start(
      `Editing user rows: ${parsedEditedRowsToUpdate
        .map((row) => JSON.stringify(row.user))
        .join("\n")}`,
    );
    try {
      const mutations = await Promise.all(
        parsedEditedRowsToUpdate.map(({ id, user }) => {
          if (!user.roles) {
            const originalRoles = originalData.find((d) => d.id === id)?.roles;
            if (!originalRoles) return;
            user.roles = parseRoles(originalRoles);
          }

          const variables = {
            id,
            user: {
              ...user,
              subscription_tier: parseSubscriptionTier(user.subscription_tier),
              roles: Array.from(user.roles),
            },
          };

          return executeUserMutation({
            variables,
          });
        }),
      );

      mutations.filter(Boolean).map(({ errors, data }) => {
        const id = readFragment(
          UserFragment,
          data?.update_folio_user_by_pk,
        )?.id;

        if (errors) {
          console.error("error saving user", errors);
          audit.error(`Errors editing user rows: ${errors.join(", ")}`);
        }

        if (!errors && id) {
          client?.delete("pendingEditUser", id);
        }
      });
    } catch (e) {
      if (e instanceof Error) {
        audit.error(`Error editing user rows: ${e.message}`);
        console.error("error editing user rows", e.message);
      } else {
        audit.error("Error editing user rows");
        console.error("unknown error while editing user rows", e);
      }
    }
  }

  const auth0 = useAuth0();

  const changedUsers =
    (pendingUsers?.length &&
      pendingUsers
        .map((editedRow) => {
          const rowId = editedRow.id;
          const dbData = serverData.find((row) => row.id === rowId);
          const oldData = {
            ...dbData,
            roles: parseRoles(dbData?.roles),
          };
          const primaryKey = dbData?.email;
          if (!primaryKey) return null;
          const edits = formatEdits({
            editableKeys: editableUserKeys,
            editedRow,
            dbData: oldData,
          });

          return {
            dbData,
            rowId,
            primaryKey,
            editedRow,
            edits,
          };
        })
        .filter(Boolean)) ||
    [];

  return (
    <QueryAllStates data={usersData} fetching={fetching} error={error}>
      <>
        <Table
          key={orgId}
          stateName={`users-${orgId}`}
          gridProps={{
            onCellEditRequest: (params) => {
              const userId = params.data?.id.toString();
              if (!userId) return;
              const colId = params.colDef?.field;
              if (!colId || colId === "groups") return;
              const newValue = params.newValue;

              const pendingUser = pendingUsers?.find(
                (user) => user.id === userId,
              );
              const serverUser = serverData.find((d) => d.id === userId);

              const strictColId = colId as Exclude<
                TEditablePendingUserKeys,
                "updatedAt" | "updatedBy"
              >;

              if (strictColId === "roles") {
                // already handled by the multi select editor
                return;
              }
              upsertExistingUser({
                key: strictColId,
                value: newValue,
                updatePendingUser: (k, v) => {
                  client.update("pendingEditUser", userId, (row) => {
                    //  @ts-expect-error - we know this is a valid key
                    if (serverUser?.[k] !== v) row[k] = v;
                    else row[k] = null;
                  });
                },
                userId,
                pendingEditUser: pendingUser,
                updatedBy: auth0.user?.sub || null,
              });
            },
            getRowClass,
          }}
          handleNavigate={(data) => {
            if (!data?.id || !org) return;
            try {
              navigate({
                to: `${currentRoute}/$userId`,
                search: true,
                params: {
                  org,
                  userId: data.id,
                },
              });
            } catch (e) {
              console.error("error navigating to user page", e, {
                org,
                data,
              });
            }
          }}
          actions={[
            {
              label: "Create New User",
              onClick: () => {
                navigate({
                  to: "/admin/orgs/$org/users/new/$id",
                  search: true,
                  params: {
                    org,
                    id: nanoid(),
                  },
                });
              },
            },
          ]}
          customItems={(params, checkboxSelection) => {
            const parsedCheckboxSelection = checkboxSelection?.length
              ? checkboxSelection
              : [params.node];

            const selectedData = parsedCheckboxSelection
              ?.map((node) => node?.data)
              .filter(Boolean);
            const ids = selectedData?.map((d) => d.id.toString());

            const moreThanOneSelected = selectedData?.length > 1;
            const nameInfo = `${
              moreThanOneSelected
                ? `${selectedData.length} Users`
                : `${selectedData?.[0]?.firstname} ${selectedData?.[0]?.lastname} (${selectedData?.[0]?.username})`
            }`;

            return [
              {
                name: `Edit ${nameInfo}`,
                action: () => {
                  navigate({
                    to: `${currentRoute}/$userId`,
                    search: true,
                    params: {
                      org,
                      userId: selectedData?.[0]?.id,
                    },
                  });
                },
              },
              {
                name: "Copy User ID",
                action: (params) => {
                  const id = params.node?.data?.id;
                  if (!id) return;
                  navigator.clipboard.writeText(id);
                  toast.success("Copied user ID");
                },
              },
              {
                name: "Actions",
                subMenu: [
                  {
                    action: () => handleResetMFA({ selectedData }),
                    name: `Reset MFA for ${nameInfo}`,
                  },
                  {
                    action: () => handleLogout({ selectedData }),
                    name: `Log out ${nameInfo}`,
                  },
                  {
                    name: `Block ${nameInfo}`,
                    action: () => {
                      onSetBlocked({
                        userIds: ids,
                        shouldBlock: true,
                      });
                    },
                  },
                  {
                    name: `Unblock ${nameInfo}`,
                    action: () => {
                      onSetBlocked({
                        userIds: ids,
                        shouldBlock: false,
                      });
                    },
                  },
                  ids.length === 1 && {
                    name: "Reset password",
                    action: () => {
                      setCurrentSelectedUser(ids[0]);
                      setOpenResetPasswordModal(true);
                    },
                  },
                  {
                    name: "Set Subscription Tier",
                    subMenu: orderedSubscriptionTiers.map((tier) => ({
                      name: tier,
                      action: async () => {
                        const tierValue = subscriptionTier.parse(tier);
                        const users = selectedData?.map((d) => d.id);

                        if (!users) return;

                        const audit = auditFactory({
                          actionName: "setSubscriptionTier",
                          clearanceLevel: "umi-internal-write",
                        });

                        audit.start(
                          `Setting subscription tier to ${tier} for user(s) in org ${orgId}: ${users.join(
                            ", ",
                          )}`,
                        );

                        const variables = {
                          ids: users,
                          user: {
                            subscription_tier: tierValue,
                          },
                        };

                        const res = executeUsersBulkMutation({
                          variables,
                        });

                        toast.promise(res, {
                          loading: "Setting subscription tier...",
                          success: () => {
                            audit.success();
                            return "Successfully set subscription tier";
                          },
                          error: (error) => {
                            audit.error("Error setting subscription tier");

                            if (error instanceof Error) {
                              return error.message;
                            }

                            console.error(error);
                            return "Error setting subscription tier";
                          },
                        });
                      },
                    })),
                  },
                ].filter(Boolean),
              },
            ];
          }}
          leftChildren={
            <CreateEntityButton
              onClick={() => {
                navigate({
                  to: "/admin/orgs/$org/users/new/$id",
                  search: true,
                  params: {
                    org,
                    id: nanoid(),
                  },
                });
              }}
              entityName="User"
              entityIcon={<FaUserPlus />}
            />
          }
          colDefs={colDefs}
          rowData={usersData}
        />
        {pendingUsers && pendingUsers.length > 0 && changedUsers.length > 0 ? (
          <FloatingContainer>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <ManageEdits
                editsCount={pendingUsers.length}
                isWaiting={isWaiting}
              >
                {changedUsers?.map((cu) => {
                  return (
                    <CurrentEdits
                      key={cu.rowId}
                      primaryKey={cu.dbData.email}
                      updatedBy={cu.editedRow.updatedBy}
                      updatedAt={cu.editedRow.updatedAt}
                      onSave={function handleSave() {
                        const pendingUser = pendingUsers.find(
                          (user) => user.id === cu.rowId,
                        );
                        if (!pendingUser) return;
                        toast.promise(
                          storeEditsToHasura([pendingUser], serverData),
                          {
                            loading: "Saving...",
                            success: "Saved!",
                            error: "Error saving",
                          },
                        );
                      }}
                      onCancel={() => {
                        client.delete("pendingEditUser", cu.rowId);
                      }}
                      edits={cu.edits}
                    />
                  );
                })}
              </ManageEdits>
              <Button
                color="warning"
                disabled={isWaiting}
                onClick={() => {
                  if (!client || !api) return;
                  console.log("resetting", pendingUsers);
                  const newRowData = usersData.filter((user) =>
                    pendingUsers.some(
                      (pendingUser) => pendingUser.id === user.id,
                    ),
                  );

                  pendingUsers.forEach((user) => {
                    client.delete("pendingEditUser", user.id);
                  });

                  api.applyTransaction({
                    update: newRowData,
                  });
                }}
              >
                Reset
              </Button>
              {isWaiting ? (
                <UndoSaving cancelSaveFn={cancelSaveFn} />
              ) : (
                <Button
                  onClick={() => {
                    floatingSaveFn(() =>
                      storeEditsToHasura(pendingUsers, serverData),
                    );
                  }}
                  color="success"
                >
                  Save All
                </Button>
              )}
            </Box>
          </FloatingContainer>
        ) : null}
        <ResetPasswordModal
          open={openResetPasswordModal}
          setOpen={setOpenResetPasswordModal}
          userId={currentSelectedUser}
        />
      </>
    </QueryAllStates>
  );
}

export function Users() {
  const { results } = useQuery(client, client.query("pendingUser"));

  return (
    <Box>
      <PartiallyCreatedEntities
        pendingItems={results}
        valuesToShow={[
          "email",
          "family_name",
          "given_name",
          "username",
          "password",
        ]}
        entityName="Users"
        icon={<FaUser />}
        newItemUrl={"/admin/orgs/$org/users/new/$id"}
        onDelete={(id) => client.delete("pendingUser", id)}
      />
      <UsersTable />
    </Box>
  );
}

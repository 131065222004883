import type {
  ExcelCell,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GetMainMenuItems,
  GetMainMenuItemsParams,
  GridApi,
  IMenuActionParams,
} from "ag-grid-community";
import {
  liveChartsPeriods,
  monthCodeToShortMonthAndYear,
  rowIdToRelativeRow,
  validColumnSettings,
} from ".";
import { selectorsData } from "./selectorsData";
import { nanoid } from "nanoid";
import { useCallback } from "react";
import { unique } from "remeda";
import type { TColumnHeaderProps } from ".";
import { useUserId, userDetailsAtom } from "../../context/auth";
import type { TPageProduct, TQuickAccess } from "../../triplit/schema";
import { client } from "../../triplit/triplit";
import { columnWidth } from "../globals";
import menuItems from "../market-grid/menuItems";
import {
  useUserSubscriptionTier,
  currentPageProductsAtom,
} from "../market-pages";
import { store } from "../sharedHooks";
import { generateIdxs, tryParse } from "../utils";
import { getContextMenuItems } from "./getContextMenuItems";
import { conditionalFormattingRulesAtom } from "./modals/formatCellHelpers";
import { getCellIds } from "./modals/formatCellHelpers";
import type { getProcessedSelectedCellsByRange } from "../../tableUtils";
import { captureEvent } from "../../context/ph";
import { useStore } from "jotai";
import {
  type TFormattingTabs,
  requestDockviewModal,
} from "./modals/modalComponents";
import {
  compressJsonString,
  parsePageFormatting,
} from "../../utils/compressedStringify";
import { gridSettingsAtom } from "../grid-settings";
import type { TQuickAccessAtom } from "./keyboardShortcuts";
import { genChartId, liveChartsOpenAtom } from "../live-charts/utils";
import { useActivePageId } from "../../data";

export function addPeriodToSelection({ api }: { api: GridApi }) {
  const periodColumn = api.getColumn("period");
  const cellRanges = api.getCellRanges();
  const existingColumns =
    cellRanges?.flatMap((cellRange) => cellRange.columns) || [];
  const minimumStartRow = Math.min(
    ...(cellRanges
      ?.map((cellRange) => cellRange?.startRow?.rowIndex)
      .filter(Boolean) || []),
  );
  const maximumEndRow = Math.max(
    ...(cellRanges
      ?.map((cellRange) => cellRange?.endRow?.rowIndex)
      .filter(Boolean) || []),
  );

  const columns = (
    periodColumn && existingColumns?.includes(periodColumn)
      ? existingColumns
      : [periodColumn, ...existingColumns]
  )?.filter(Boolean);

  return {
    columns,
    rowEndIndex: minimumStartRow,
    rowStartIndex: maximumEndRow,
  };
}

export const productMaturityOverrides: Record<
  string,
  TSelectorMaturity<TSelectorExchange>
> = {
  "8df15c9d-d971-43c6-bb18-d808a7af0fd3": "fx",
  "b8973c43-540b-477c-aa48-5dcd68139eba": "fx",
  "6c772c57-3604-4222-8f7e-3f3ebf453eaf": "fx",
  "46a81843-2ef3-470e-a7f8-4b452102b625": "fx",
};

export function defaultSelector(artisType: string) {
  return ["eod", "customer_curve", "global"].includes(artisType)
    ? "value"
    : "fv";
}

export function selector({ products }: { products: TPageProduct[] }) {
  return ({ gridId, artisType }: { gridId: string; artisType: string }) => {
    return (
      products?.find((product) => product.id === gridId)?.columnFieldSelector ||
      defaultSelector(artisType)
    );
  };
}

export function selectorSubgroup(): Record<string, string> {
  return menuItems.reduce((acc, { name, subItems }) => {
    return subItems.reduce((acc, subItem) => {
      return Object.assign({}, acc, { [subItem]: name });
    }, acc);
  }, {});
}

export type TSelectorExchange = keyof typeof selectorsData;

export function validateSelectorExchange(number: number): TSelectorExchange {
  if (number in selectorsData) {
    return number as TSelectorExchange;
  }
  throw new Error(`${number} is not a valid exchange.`);
}

export type TSelectorMaturity<T extends TSelectorExchange> =
  keyof (typeof selectorsData)[T];

export type TSelectorUsage<
  T extends TSelectorExchange,
  U extends TSelectorMaturity<T>,
> = keyof (typeof selectorsData)[T][U];

export function selectors({
  exchange,
  maturity,
  usage,
}: {
  exchange: TSelectorExchange;
  maturity: TSelectorMaturity<TSelectorExchange>;
  usage: TSelectorUsage<
    TSelectorExchange,
    TSelectorMaturity<TSelectorExchange>
  >;
}) {
  const exchangeData =
    exchange in selectorsData ? selectorsData[exchange] : null;

  if (!exchangeData) {
    return ["value"];
  }

  const maturityData = maturity in exchangeData ? exchangeData[maturity] : null;

  if (!maturityData) {
    return ["value"];
  }

  const usageData = usage in maturityData ? maturityData[usage] : null;

  return usageData || ["value"];
}

export const fieldNameOverrides: Record<string, string> = {
  fv: "FAIR VALUE",
};

export function getSelectors({
  exchange,
  maturity,
  productId,
}: {
  exchange: TSelectorExchange;
  maturity: TSelectorMaturity<TSelectorExchange>;
  productId: string;
}) {
  const selectorMaturity = productMaturityOverrides?.[productId] || maturity;

  const result = selectors({
    exchange,
    maturity: selectorMaturity,
    usage: "grid",
  });

  return result.map((selector) => {
    const selectorLabel =
      selector in fieldNameOverrides ? fieldNameOverrides[selector] : selector;

    return {
      label: selectorLabel.toUpperCase(),
      value: selector,
      groupValue: "selector",
    };
  });
}

export function fieldNameToLabel(fieldName: string) {
  return fieldName.replace(/-/g, " ").toUpperCase();
}

function cell(value: string): ExcelCell {
  return { data: { value, type: "String" } };
}

export function exportToExcelWithMetadata(params: IMenuActionParams) {
  const api = params.api;
  if (!api) throw new Error("No grid api provided");

  const columnDefs = api?.getColumns()?.map((column) => column?.getColDef());

  const headers = columnDefs?.map((column) => {
    const columnParams: TColumnHeaderProps = column.headerComponentParams;
    const selector = defaultSelector(columnParams?.artisType || "");

    return {
      packageName: column.headerName,
      ...columnParams,
      selector,
    };
  });

  const data = headers?.map((header) => {
    const labels = header.columnLabels;

    return {
      name: header?.packageName,
      description: labels?.commodityGroup,
      productId: header?.productId,
      package: labels?.package,
      uom: labels?.uom,
      selector:
        header?.packageName?.toLowerCase() === "month"
          ? undefined
          : header?.selector,
    };
  });

  const names =
    data?.map((header) => cell(header?.name?.toString() || "")) || [];
  const descriptions =
    data?.map((header) => cell(header?.description?.toString() || "")) || [];
  const productIds =
    data?.map((header) => cell(header?.productId?.toString() || "")) || [];
  const packages =
    data?.map((header) => cell(header?.package?.toString() || "")) || [];
  const uoms = data?.map((header) => cell(header?.uom?.toString() || "")) || [];
  const fieldSelectors =
    data?.map((header) => cell(header?.selector?.toString() || "")) || [];

  const content = [
    names,
    descriptions,
    productIds,
    packages,
    uoms,
    fieldSelectors,
  ].map((row) => {
    return { cells: row };
  });

  api?.exportDataAsExcel({
    skipColumnHeaders: true,
    skipColumnGroupHeaders: true,
    prependContent: content,
  });
}

export type TShadowColumn = "month" | "blank" | "month-timespread";

export function generateShadowColumnId() {
  return `shadow-${nanoid()}`;
}

export function insertCurveDuplicate({
  params,
  userId,
  pageId,
  currentPageProducts,
}: {
  params: IMenuActionParams;
  userId?: string;
  pageId: string;
  currentPageProducts: TPageProduct[];
}) {
  const api = params.api;
  if (!api) throw new Error("No grid api provided");
  if (!userId) throw new Error("No user id provided");
  const headerParams = params.column?.getColDef().headerComponentParams;
  const product = currentPageProducts.find(
    (p) => p.productId === headerParams.productId,
  );

  const idx = generateIdxs({
    amount: 1,
    api,
    currentPageProducts,
  })[0];
  if (!params.column) return;
  const newColId = generateShadowColumnId();

  const newColumn = {
    id: newColId,
    idx,
    productId: product?.productId,
    userId,
    pageId,
    columnType: "product",
    columnWidth: params.column?.getActualWidth(),
    columnFieldSelector: product?.columnFieldSelector,
    decimalPlaces: product?.decimalPlaces,
    thousandsSeparator: product?.thousandsSeparator,
  } satisfies TPageProduct;

  captureEvent(`Insert product duplicate - ${headerParams.productId}`);

  return client.insert("pageProducts", newColumn);
}

export function insertShadowColumn({
  params,
  userId,
  pageId,
  currentPageProducts,
  type,
}: {
  params: IMenuActionParams;
  userId?: string;
  pageId: string;
  currentPageProducts: TPageProduct[];
  type: TShadowColumn;
}) {
  const api = params.api;
  if (!api) throw new Error("No grid api provided");
  if (!userId) throw new Error("No user id provided");

  const idx = generateIdxs({
    amount: 1,
    api,
    currentPageProducts,
  })[0];

  const newColId = generateShadowColumnId();

  const newColumn = {
    id: newColId,
    idx,
    productId: undefined,
    userId,
    pageId,
    columnType: type,
    columnWidth,
    columnFieldSelector: undefined,
    decimalPlaces: undefined,
    thousandsSeparator: undefined,
  } satisfies TPageProduct;

  captureEvent(`Insert Shadow Column - ${type}`, { columnType: type });

  return client.insert("pageProducts", newColumn);
}

export function useContextMenuItems<THeaderMenu extends boolean>({
  isHeaderMenu,
}: {
  isHeaderMenu: THeaderMenu;
}): THeaderMenu extends true ? GetMainMenuItems : GetContextMenuItems {
  const { disabledFeatures } = useUserSubscriptionTier();
  const pageId = useActivePageId();
  const userId = useUserId();
  const store = useStore();

  return useCallback(
    (params: GetContextMenuItemsParams | GetMainMenuItemsParams) => {
      const products = store.get(currentPageProductsAtom);
      if (!pageId || !products) return [];
      return getContextMenuItems({
        params,
        userId,
        isHeaderMenu,
        disabledFeatures,
        pageId,
        products,
      });
    },
    [store, userId, pageId, isHeaderMenu, disabledFeatures],
  );
}

export function getFormatMenuItemName({
  api,
  selection,
  item,
}: {
  api: GridApi;
  selection: ReturnType<typeof getProcessedSelectedCellsByRange>;
  item: "cell" | "column" | "period";
}) {
  try {
    if (!api) return "";

    // Sort in ascending order so the largest row index is last.
    const sortedRangeSelection = selection?.flat()?.sort((a, b) => {
      return a?.rowIndex ?? 0 - (b?.rowIndex ?? 0);
    });

    if (!sortedRangeSelection?.length) return "";

    const firstCell = sortedRangeSelection[0];
    const lastCell = sortedRangeSelection[sortedRangeSelection.length - 1];

    const firstPeriod = firstCell?.rowId;
    const lastPeriod = lastCell?.rowId;

    switch (item) {
      case "cell":
        return sortedRangeSelection.length > 1 ? "Range" : "Cell";
      case "column":
        return unique(sortedRangeSelection.map((cell) => cell.columnId))
          .length > 1
          ? "Columns"
          : "Column";
      case "period":
        return firstPeriod === lastPeriod
          ? `Period: ${firstPeriod}`
          : `Periods: ${firstPeriod} - ${lastPeriod}`;
    }
  } catch (e) {
    console.error("Error getting format menu item name", e);
    return "";
  }
}

type TClearCellConditionalFormatting = {
  pageId: string;
  type: "range" | "column" | "period" | "all";
  periods?: string[];
  cellIds?: string[];
  colIds?: string[];
};

async function clearCellConditionalFormatting({
  type,
  periods,
  cellIds,
  colIds,
}: TClearCellConditionalFormatting) {
  const conditionalFormatting = store.get(conditionalFormattingRulesAtom);

  async function clearCell() {
    (
      cellIds
        ?.flatMap((cellId) => {
          return conditionalFormatting?.[cellId]?.map((r) => r.id);
        })
        ?.filter(Boolean) || []
    ).map((id) => client.delete("conditionalFormattingRules", id));
  }

  function clearColumn() {
    (
      colIds
        ?.flatMap((colId) => {
          return Object.values(conditionalFormatting || {}).flatMap((rules) => {
            return rules?.map((r) => {
              if (r.columnId === colId) {
                return r.id;
              }
            });
          });
        })
        ?.filter(Boolean) || []
    ).map((id) => client.delete("conditionalFormattingRules", id));
  }

  function clearPeriod() {
    (
      periods
        ?.flatMap((code) => {
          const period = monthCodeToShortMonthAndYear(code);
          const rowId = rowIdToRelativeRow?.[period];

          return Object.values(conditionalFormatting || {}).flatMap((rules) => {
            return rules?.map((r) => {
              if (r.rowId === rowId) {
                return r.id;
              }
            });
          });
        })
        ?.filter(Boolean) || []
    ).map((id) => client.delete("conditionalFormattingRules", id));
  }

  if (type === "range") {
    clearCell();
  }

  if (type === "column") {
    clearColumn();
  }

  if (type === "period") {
    clearPeriod();
  }

  if (type === "all") {
    clearCell();
    clearColumn();
    clearPeriod();
  }
}

export async function clearCellFormatting(
  params: TClearCellConditionalFormatting,
) {
  clearCellConditionalFormatting(params);

  const { pageId, type, periods, cellIds, colIds } = params;

  await client.update("pages", pageId, async (current) => {
    const parsedHighlights = await parsePageFormatting(current);

    async function clearCell() {
      try {
        if (current.cellHighlights && cellIds?.length) {
          const currentCellHighlights = parsedHighlights.cellHighlights;

          for (const cellId of cellIds) {
            delete currentCellHighlights[cellId];
          }

          current.cellHighlights = await compressJsonString(
            JSON.stringify(currentCellHighlights || {}),
          );
        }
      } catch (e) {
        console.error("Error parsing cellHighlights", e);
      }
    }

    async function clearColumn() {
      try {
        if (current.columnHighlights && colIds?.length) {
          const currentColumnHighlights = parsedHighlights.columnHighlights;

          for (const colId of colIds) {
            delete currentColumnHighlights[colId];
          }

          current.columnHighlights = await compressJsonString(
            JSON.stringify(currentColumnHighlights || {}),
          );
        }
      } catch (e) {
        console.error("Error parsing columnHighlights", e);
      }
    }

    async function clearPeriod() {
      try {
        if (current.periodHighlights && periods?.length) {
          const currentPeriodHighlights = parsedHighlights.periodHighlights;

          for (const period of periods) {
            delete currentPeriodHighlights[period];
          }

          current.periodHighlights = await compressJsonString(
            JSON.stringify(currentPeriodHighlights || {}),
          );
        }
      } catch (e) {
        console.error("Error parsing periodHighlights", e);
      }
    }

    if (type === "range") {
      await clearCell();
    }

    if (type === "column") {
      await clearColumn();
    }

    if (type === "period") {
      await clearPeriod();
    }

    if (type === "all") {
      await clearCell();
      await clearColumn();
      await clearPeriod();
    }
  });
}

export const createFormatMenuItem = ({
  api,
  pageId,
  item,
  type,
  selection,
}: {
  api: GridApi;
  pageId: string;
  item: "cell" | "column" | "period";
  type: "range" | "column" | "period";
  selection: ReturnType<typeof getProcessedSelectedCellsByRange>;
}) => ({
  name: getFormatMenuItemName({ api, selection, item }),
  action: () => {
    return clearCellFormatting({
      pageId,
      type,
      cellIds: getCellIds({
        type: "range",
        ranges: selection,
        api,
      }).cellIds,
      colIds: getCellIds({
        type: "column",
        ranges: selection,
        api,
      }).cellIds,
      periods: getCellIds({
        type: "period",
        ranges: selection,
        api,
      }).cellIds,
    });
  },
});

export function getQuickAccessName(
  shortcutNumber: number,
  quickAccess: TQuickAccessAtom,
) {
  const existing = quickAccess?.find(
    (qa) => qa.shortcutNumber === shortcutNumber,
  );

  return existing?.productName || "";
}

export async function openFormattingDockview({
  pageId,
  type,
  columnId,
  productId,
  currentTab,
  selectedRange,
}: {
  pageId: string;
  type: "range" | "column" | "period";
  columnId: string;
  productId: string;
  currentTab: TFormattingTabs;
  selectedRange: ReturnType<typeof getProcessedSelectedCellsByRange>;
}) {
  const incrementMapStr = store.get(gridSettingsAtom)?.incrementMap;
  const incrementMap = tryParse<Record<string, number>>(incrementMapStr, {});
  const product = await client.fetchById("pageProducts", columnId);

  const initialSettings = validColumnSettings({
    columnWidth: product?.columnWidth || columnWidth,
    decimalPlaces: product?.decimalPlaces,
    thousandsSeparator: product?.thousandsSeparator,
    increment: incrementMap?.[productId],
  });

  requestDockviewModal({
    parentPanel: "formatting",
    currentTab,
    params: {
      selectedRange,
      columnId,
      pageId,
      tab: "colors",
      type,
      initialColumnWidth: initialSettings.columnWidth,
      initialThousandsSeparator: initialSettings.thousandsSeparator,
      initialDecimalPlaces: initialSettings.decimalPlaces,
      initialIncrement: initialSettings.increment,
      width: 300,
      height: 550,
    },
  });
}

export async function upsertQuickAccess(quickAccess: Omit<TQuickAccess, "id">) {
  const userId = store.get(userDetailsAtom)?.id;

  if (!userId) return;

  const id = `${userId}-${quickAccess.shortcutNumber}`;

  const exists = await client.fetchById("quickAccess", id);

  if (exists) {
    await client.update("quickAccess", id, (entity) => {
      entity.pageId = quickAccess.pageId;
      entity.columnId = quickAccess.columnId;
    });
  } else {
    await client.insert("quickAccess", {
      id,
      userId,
      pageId: quickAccess.pageId,
      columnId: quickAccess.columnId,
      productId: quickAccess.productId,
      shortcutNumber: quickAccess.shortcutNumber,
    });
  }
}

export async function plotLiveChart({
  userId,
  rowId,
  productId,
}: {
  userId: string;
  rowId: string;
  productId: string;
}) {
  const chartSettings = await client.fetchById("chartSettings", userId);

  const layout = chartSettings?.layout || 1;

  const currentCharts = Array.from(
    (await client.fetch(client.query("liveCharts").build()))?.values() || [],
  );

  const month = monthCodeToShortMonthAndYear(rowId);
  const periodFrom = liveChartsPeriods.find((p) => p.label === month)?.value;

  const freeSlot = layout > currentCharts.length;
  const lastSlot =
    currentCharts.length > layout ? layout - 1 : currentCharts.length - 1;

  const id = genChartId({
    chartIdx: freeSlot ? currentCharts.length.toString() : lastSlot.toString(),
    userId,
  });

  if (freeSlot) {
    client.insert("liveCharts", {
      id,
      productId,
      sampleTime: "1m",
      userId,
      periodFrom,
    });
  } else {
    client.update("liveCharts", id, (row) => {
      row.productId = productId;
      row.sampleTime = "1m";
      row.periodFrom = periodFrom;
    });
  }

  store.get(liveChartsOpenAtom)?.setIsLiveChartsOpen(true);
}

import { extendTheme } from "@mui/joy";
import { useTheme } from "@mui/system";
import type { ColorSystemOptions } from "@mui/joy/styles/extendTheme";
import type { DefaultColorScheme } from "@mui/joy/styles/types";

const defaultTheme = extendTheme();

declare module "@mui/joy/styles" {
  interface PaletteBackgroundOverrides {
    artis: true;
    secondary: true;
    sideBar: true;
    topBar: true;
    bottomBar: true;
  }
}

declare module "@mui/joy/styles" {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    secondary: true;
  }
}

declare module "@mui/joy/styles" {
  interface Palette {
    highContrast: string;
    hover: string;
    secondary: {
      [key: string]: string;
    };
    text: {
      primary: string;
      secondary: string;
      icon: string;
      disabled: string;
    };
  }
}

type TColorSchemes = Partial<Record<DefaultColorScheme, ColorSystemOptions>>;

// all vars in src/styles/artisVariables.css

const secondary = {
  "50": "#e3f5ff",
  "100": "#d2eeff",
  "200": "#ace1ff",
  "300": "#5ec4ff",
  "400": "#31b3ff",
  "500": "#05a4ff", // main
  "600": "#0075b8",
  "700": "#005a8f",
  "800": "#004b76",
  "900": "#003553",
  solidDisabledBg: "var(--webapp-palette-neutral-200)",
  solidDisabledColor: "var(--webapp-palette-neutral-400)",
};

const webappColorSchemes = {
  ...defaultTheme.colorSchemes,
  light: {
    ...defaultTheme.colorSchemes.light,
    palette: {
      ...defaultTheme.colorSchemes.light.palette,
      highContrast: "black",
      focusVisible: "transparent",
      primary: {
        ...defaultTheme.colorSchemes.light.palette.primary,
        "50": "#ffefe3",
        "100": "#ffe6d1",
        "200": "#ffd1ac",
        "300": "#ffa55c",
        "400": "#ff8c2d",
        "500": "#ff7400", // main
        "600": "#b75200",
        "700": "#8d4000",
        "800": "#753500",
        "900": "#532500",
      },
      secondary: {
        ...defaultTheme.colorSchemes.light.palette.secondary,
        ...secondary,
      },
      neutral: {
        ...defaultTheme.colorSchemes.light.palette.neutral,
        "50": "#e0e4e6",
        "100": "#cfd5d8",
        "200": "#bfc5c9",
        "300": "#aeb5ba",
        "400": "#9ea6ac",
        "500": "#8d979e",
        "600": "#7f8990",
        "700": "#717b82",
        "800": "#636d74",
        "900": "#565f66",
      },
      danger: {
        ...defaultTheme.colorSchemes.light.palette.danger,
        "50": "#fcefef",
        "100": "#fae5e5",
        "200": "#f5d1d1",
        "300": "#eda9a9",
        "400": "#e89393",
        "500": "#e48181",
        "600": "#d32f2f", // main
        "700": "#a52323",
        "800": "#891d1d",
        "900": "#611515",
      },
      success: {
        ...defaultTheme.colorSchemes.light.palette.success,
        "50": "#e7f5e8",
        "100": "#dcf0dd",
        "200": "#c0e3c1",
        "300": "#86ca88",
        "400": "#68bd6b",
        "500": "#4cb050", // main
        "600": "#377e39",
        "700": "#2a612c",
        "800": "#235125",
        "900": "#183719",
      },
      warning: {
        ...defaultTheme.colorSchemes.light.palette.warning,
        "50": "#fff3b2",
        "100": "#ffeb7d",
        "200": "#fed700", // main
        "300": "#d9b800",
        "400": "#c6a900",
        "500": "#fed700",
        "600": "#826f00",
        "700": "#655500",
        "800": "#534700",
        "900": "#3a3200",
      },
      background: {
        surface: "var(--webapp-palette-common-white)",
        level1: "var(--webapp-palette-neutral-50)",
        level2: "var(--webapp-palette-neutral-100)",
        level3: "var(--webapp-palette-neutral-200)",
        tooltip: "var(-webapp-palette-neutral-400)",
        topBar: "var(--webapp-palette-neutral-50)",
        bottomBar: "var(--artis-nav-link-bg-light)",
        sideBar: "var(--artis-dark-bg)",
        artis: "var(--artis-light-bg)",
      },
      text: {
        primary: "var(--webapp-palette-common-black)",
        secondary: "var(--webapp-palette-neutral-800)",
        tertiary: "var(--webapp-palette-neutral-700)",
        icon: "var(--webapp-palette-neutral-600)",
        disabled: "var(--webapp-palette-neutral-400)",
      },
    },
  },
  dark: {
    palette: {
      ...defaultTheme.colorSchemes.dark.palette,
      highContrast: "white",
      primary: {
        ...defaultTheme.colorSchemes.dark.palette.primary,
        "50": "#ffefe3",
        "100": "#ffe6d1",
        "200": "#ffd1ac",
        "300": "#ffa55c",
        "400": "#ff8c2d",
        "500": "#ff7400", // main
        "600": "#b75200",
        "700": "#8d4000",
        "800": "#753500",
        "900": "#532500",
      },
      secondary: {
        ...defaultTheme.colorSchemes.dark.palette.secondary,
        "50": "#e3f5ff",
        "100": "#d2eeff",
        "200": "#ace1ff",
        "300": "#5ec4ff",
        "400": "#31b3ff",
        "500": "#05a4ff", // main
        "600": "#0075b8",
        "700": "#005a8f",
        "800": "#004b76",
        "900": "#003553",
      },
      neutral: {
        ...defaultTheme.colorSchemes.dark.palette.neutral,
        "50": "#FBFCFE", // main
        "100": "#F0F4F8",
        "200": "#DDE7EE",
        "300": "#CDD7E1",
        "400": "#9FA6AD",
        "500": "#636B74",
        "600": "#555E68",
        "700": "#32383E",
        "800": "#171A1C",
        "900": "#0B0D0E",
      },
      danger: {
        ...defaultTheme.colorSchemes.dark.palette.danger,
        "50": "#fcefef",
        "100": "#fae5e5",
        "200": "#f5d1d1",
        "300": "#eda9a9",
        "400": "#e89393",
        "500": "#e48181",
        "600": "#d32f2f", // main
        "700": "#a52323",
        "800": "#891d1d",
        "900": "#611515",
      },
      success: {
        ...defaultTheme.colorSchemes.dark.palette.success,
        "50": "#e7f5e8",
        "100": "#dcf0dd",
        "200": "#c0e3c1",
        "300": "#86ca88",
        "400": "#68bd6b",
        "500": "#4cb050", // main
        "600": "#377e39",
        "700": "#2a612c",
        "800": "#235125",
        "900": "#183719",
      },
      warning: {
        ...defaultTheme.colorSchemes.dark.palette.warning,
        "50": "#fff3b2",
        "100": "#ffeb7d",
        "200": "#fed700", // main
        "300": "#d9b800",
        "400": "#c6a900",
        "500": "#b79c00",
        "600": "#826f00",
        "700": "#655500",
        "800": "#534700",
        "900": "#3a3200",
      },
      background: {
        surface: "var(--webapp-palette-common-black)",
        level1: "var(--webapp-palette-neutral-800)",
        level2: "var(--webapp-palette-neutral-700)",
        level3: "var(--webapp-palette-neutral-600)",
        tooltip: "var(--webapp-palette-neutral-500)",
        topBar: "var(--artis-nav-bg-dark)",
        bottomBar: "var(--artis-nav-bg-dark)",
        sideBar: "black",
        artis: "var(--artis-dark-bg)",
      },
      text: {
        primary: "var(--webapp-palette-neutral-50)",
        secondary: "var(--webapp-palette-neutral-200)",
        tertiary: "var(--webapp-palette-neutral-300)",
        icon: "var(--webapp-palette-neutral-400)",
        disabled: "var(--webapp-palette-neutral-400)",
      },
    },
  },
} satisfies TColorSchemes;

const colorSchemes = {
  light: {
    palette: {
      highContrast: "black",
      focusVisible: "transparent",
      background: {
        level1: "var(--artis-dark-bg)",
        level2: "var(--artis-nav-bg-light)",
        tooltip: "var(--artis-dark-bg)",
        artis: "var(--artis-light-bg)",
      },
      text: {
        primary: "var(--artis-text-primary-light)",
        secondary: "var(--artis-text-secondary-light)",
      },
    },
  },
  dark: {
    palette: {
      highContrast: "white",
      background: {
        level1: "var(--artis-dark-bg)",
        level2: "var(--artis-dark-bg)",
        tooltip: "var(--artis-dark-bg)",
        artis: "var(--artis-dark-bg)",
      },
      text: {
        primary: "var(--artis-text-primary-dark)",
        secondary: "var(--artis-text-secondary-dark)",
      },
    },
  },
} satisfies TColorSchemes;

export const lumiTheme = extendTheme({ colorSchemes, cssVarPrefix: "lumi" });

export const webappTheme = extendTheme({
  cssVarPrefix: "webapp",
  fontFamily: {
    ...defaultTheme.fontFamily,
    body: "var(--default-font)",
    display: "var(--default-font)",
  },
  colorSchemes: webappColorSchemes,
});

export const liveChartsTheme = extendTheme({
  cssVarPrefix: "liveCharts",
  colorSchemes,
});

export function useWebappTheme() {
  return useTheme(webappTheme);
}

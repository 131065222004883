import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  Select,
  Sheet,
  Typography,
} from "@mui/joy";
import type { IHeaderParams } from "ag-grid-community";
import { memo, useCallback, useMemo, useRef, useState } from "react";

import { useUserHasRole } from "../../layout";
import { SelectPrettyValue, SelectPrettyOption } from "./SelectPretty";
import { useUserSubscriptionTier } from "../../market-pages";
import {
  radioSlotProps,
  statusModalMaxWidth,
  StatusOperationSchema,
  type TStatusTransition,
  type TUpdateStatusOperation,
} from "../statuses/consts";
import {
  changeAllHoverAtom,
  useUpdateColumnStatus,
  useWriteStatusInstruments,
  checkCanBroadcast,
  genGlobalDependencyOptions,
  genSelectStatusOptions,
  genStatusTransitionActions,
} from "../statuses/hooks";
import { useAtomValue } from "jotai";
import { hexToRgba } from "../../colorUtils";
import { captureEvent } from "../../../context/ph";
import { useGridSettingsStatusMap } from "../../grid-settings";
import { statusComponentsZIndex } from "../../globals";
import { useProductById } from "../../../data";

import type { TColumnHeaderProps } from "./ColumnHeaderProduct";
import { StatusModalTitle } from "./StatusModalTitle";

export function ColumnHeaderSelect(
  params: TColumnHeaderProps &
    IHeaderParams & {
      logo: string | null | undefined;
    },
) {
  const elRef = useRef<HTMLDivElement>(null);
  const [transitionData, setTransitionData] =
    useState<TStatusTransition | null>(null);

  const { data: product } = useProductById(params.productId || "");

  const globalOptions = genGlobalDependencyOptions(product);
  const { liteUser } = useUserSubscriptionTier();
  const isDeniedBroadcast = useUserHasRole("no-broadcast");

  const resolvedRules = useMemo(
    () => ({
      isDeniedBroadcast,
      isLiteUser: !!liteUser,
      canEod: !!product?.eod_product_dep,
      canBroadcast: !!checkCanBroadcast(product?.packageByPackage.permissions),
    }),
    [liteUser, product, isDeniedBroadcast],
  );

  const allStatusOptions = useMemo(
    () =>
      genSelectStatusOptions({
        resolvedRules,
        globalOptions,
      }),
    [resolvedRules, globalOptions],
  );

  const statusOptionsToRender = useMemo(
    () =>
      genSelectStatusOptions({
        resolvedRules,
        globalOptions,
      }),
    [resolvedRules, globalOptions],
  );
  const updateColumnStatus = useUpdateColumnStatus();
  const writeStatusInstruments = useWriteStatusInstruments();
  const handleStatusChange = useCallback(
    (value: string) => {
      if (!value) return;

      const from = params.status;
      const toStatus = value;

      const excludedStatusChanges = [
        { from: "private", to: "broadcast" },
        { from: "private", to: "hybrid_broadcast" },
      ];

      const isExcluded = excludedStatusChanges.some(
        (excluded) => excluded.from === from && excluded.to === toStatus,
      );
      if (toStatus !== "private" && !isExcluded) {
        captureEvent("Status change", { from, to: toStatus });
      }
      const transitionWithModal = genStatusTransitionActions({
        from,
        to: toStatus,
      });

      if (transitionWithModal) {
        setTransitionData(transitionWithModal);
        return;
      }

      if (
        toStatus === "broadcast" &&
        (from === "listen" || from === "hybrid_broadcast")
      ) {
        if (!params.productId) {
          console.error("productId is undefined in ColumnHeaderSelect");
          return;
        }
        writeStatusInstruments({
          to: toStatus,
          from,
          curveId: params.productId,
          gridId: params.gridId,
        });
      }

      if (!params.productId)
        throw new Error("productId is undefined in ColumnHeaderSelect");
      updateColumnStatus(toStatus, [params.productId]);
    },
    [
      params.status,
      params.productId,
      params.gridId,
      updateColumnStatus,
      writeStatusInstruments,
    ],
  );

  const changeAll = useAtomValue(changeAllHoverAtom);
  return (
    <div
      ref={elRef}
      className="column-header flex flex-col items-center overflow-hidden"
    >
      {transitionData && (
        <StatusModalOne
          anchor={elRef.current}
          curveId={params.productId || ""}
          gridId={params.gridId}
          transitionData={transitionData}
          setClose={() => setTransitionData(null)}
        />
      )}
      <Select
        autoFocus={false}
        variant="plain"
        sx={(theme) => {
          const { r, g, b, a } = hexToRgba(theme.palette.neutral[500], "0.3");
          return {
            width: "100%",
            background: changeAll
              ? `rgba(${r}, ${g}, ${b}, ${a})`
              : "transparent",
          };
        }}
        slotProps={{
          button: {
            sx: {
              width: "100%",
            },
          },
        }}
        size="sm"
        value={params.status || "listen"}
        indicator={false}
        renderValue={(renderValueParams) => (
          <SelectPrettyValue
            options={allStatusOptions}
            renderValueParams={renderValueParams}
            logo={params.logo}
          />
        )}
        onChange={(_e, status) => {
          if (typeof status === "string") {
            handleStatusChange(status);
          }
        }}
      >
        {allStatusOptions.map((option) => {
          return (
            <SelectPrettyOption
              key={option.value}
              {...option}
              statusOptionsToRender={statusOptionsToRender}
              logo={params.logo}
            />
          );
        })}
      </Select>
    </div>
  );
}

export const ColumnHeaderSelectMemo = memo(ColumnHeaderSelect);

function StatusModalOne({
  transitionData,
  setClose,
  anchor,
  curveId,
  gridId,
}: {
  curveId: string;
  gridId: string;
  transitionData: TStatusTransition;
  setClose: () => unknown;
  anchor: HTMLDivElement | null;
}) {
  const [statusOperation, setStatusOperation] =
    useState<TUpdateStatusOperation>(transitionData.options[0].value);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const updateColumnStatus = useUpdateColumnStatus();

  const writeStatusInstruments = useWriteStatusInstruments();
  const statusMap = useGridSettingsStatusMap();

  async function onSave() {
    setIsLoading(true);
    setError(undefined);

    try {
      const from = statusMap[curveId];
      await updateColumnStatus(transitionData?.to, [curveId]);

      if (statusOperation === "write") {
        console.log("product", curveId);
        console.log("write", transitionData?.from, "to", transitionData?.to);
        writeStatusInstruments({
          to: transitionData.to,
          from,
          curveId,
          gridId,
        });
      }

      captureEvent("Status change", { from, to: transitionData.to });

      // no need to setLoading false as the component unmounts
      setClose();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError("Something went wrong!");
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setClose()}>
      <BasePopup
        open={!!transitionData}
        offset={8}
        anchor={anchor}
        placement="bottom"
        style={{
          zIndex: statusComponentsZIndex,
          maxWidth: statusModalMaxWidth,
        }}
      >
        <Box
          sx={{
            borderRadius: "sm",
            overflow: "hidden",
            background: (theme) => theme.palette.background.surface,
          }}
        >
          <Box>
            <StatusModalTitle label="change status" />
            <Box sx={{ p: 2 }}>
              <Box sx={{ pb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    level="body-xs"
                  >
                    {transitionData.label}
                  </Typography>
                  <RadioGroup
                    size="sm"
                    orientation="vertical"
                    sx={{ gap: 1 }}
                    onChange={(e) =>
                      setStatusOperation(
                        StatusOperationSchema.parse(e.target.value),
                      )
                    }
                  >
                    {transitionData?.options.map((option) => (
                      <Sheet
                        key={option.value}
                        sx={{
                          p: 0.5,
                          borderRadius: "md",
                          boxShadow: "sm",
                        }}
                      >
                        <Radio
                          sx={{ textTransform: "capitalize" }}
                          checked={statusOperation === option.value}
                          value={option.value}
                          label={option.label}
                          overlay
                          disableIcon
                          slotProps={radioSlotProps}
                        />
                      </Sheet>
                    ))}
                  </RadioGroup>
                </Box>
              </Box>
              <Box>
                <Button
                  sx={{ width: "100%" }}
                  size="sm"
                  loading={isLoading}
                  loadingPosition="end"
                  onClick={onSave}
                >
                  Save
                </Button>
                {error && (
                  <Typography color="danger" sx={{ pt: 2 }} level="body-sm">
                    {error}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </BasePopup>
    </ClickAwayListener>
  );
}

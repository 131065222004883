import { useQuery } from "@apollo/client";
import { graphql } from "../../../graphql";

export function useProductArtisType(productId?: string) {
  return useQuery(artisTypeQuery, {
    variables: {
      id: productId || "",
    },
    skip: !productId,
  }).data?.product_by_pk?.artis_type;
}

const artisTypeQuery = graphql(`
  query productArtisTypeById($id: uuid!) {
    product_by_pk(id: $id) {
      id
      artis_type
    }
  }
`);

import { useIntervalEffect } from "@react-hookz/web";
import { useApolloClient } from "@apollo/client";

import { useAdhocSpreads } from "../../modals/timespreadHooks";
import { usePageLoadingState } from "../../../market-pages";
import { updateGrid } from "./helpers";
import { useAtomValue } from "jotai";
import { gridApiAtom } from "../../stores";

export const useLivePricesPlugin = () => {
  const { formattedAdhocSpreads } = useAdhocSpreads();
  const loadingState = usePageLoadingState();
  const graphqlClient = useApolloClient();
  const api = useAtomValue(gridApiAtom);
  useIntervalEffect(
    () => {
      if (api) {
        updateGrid(graphqlClient, formattedAdhocSpreads || [], api);
      }
    },
    loadingState !== "empty" ? 250 : undefined,
  );
};

import { useThrottledCallback } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import { dockviewAtom } from "../utils";

export function usePanelResize(id: string) {
  const idx = fromChartIdToIdx(id);
  const [dockview] = useAtom(dockviewAtom);
  const panelApi = dockview?.getPanel(idx)?.api;

  const [panelWidth, setPanelWidth] = useState<number | undefined>(
    panelApi?.width,
  );

  // to set new width when the user resizes internal panels
  useEffect(() => {
    dockview?.onDidLayoutChange(() => {
      setPanelWidth(panelApi?.width);
    });
  }, [dockview, panelApi]);

  const handleResize = useThrottledCallback(
    () => {
      setPanelWidth(panelApi?.width);
    },
    [panelApi],
    250,
  );

  // to set new panel width when the whole window resizes.
  // throttled to avoid unnecessary re-renders
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return {
    panelWidth,
  };
}

export function fromChartIdToIdx(chartId: string) {
  return chartId.split("-")[0];
}

import { zodResolver } from "@hookform/resolvers/zod";
import { createUserMutation, usePendingUser } from "./hooks";
import { useForm } from "react-hook-form";
import { Box, Button } from "@mui/joy";
import { InputField } from "../components/Form";
import { useFloatingSave } from "../hooks/useFloatingSave";
import { FloatingContainer, UndoSaving } from "../components/FloatingSave";
import * as R from "remeda";
import { useMutation } from "@apollo/client";
import type { TCreateUserBody } from "../../../api/create-user.mjs";
import type { TServerResponse } from "../../globals";
import { useAuth0Config } from "../../context/auth";
import { z } from "zod";
import toast from "react-hot-toast";
import { useNavigate } from "@tanstack/react-router";
import { client } from "../../triplit/triplit";
import type { TDeleteUserBody } from "../../../api/delete-user.mjs";
import { passwordValidation } from "../../utils/forms";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuditLoggerFactory } from "../audit";
import type { SxProps } from "@mui/joy/styles/types";
import { useState } from "react";
import { Loading } from "../components/Loading";
import { useUrlEnv } from "../../context/environment";
import { defaultUserSubscriptionTier } from "../../utils/users";

const defaultPageSettingsBlob =
  '["^ ","~:last-updated","2023-09-11T13:51:56.567Z","~:listen-color","#b7d7f5","~:season-switch",true,"~:column-settings",["^ "],"~:eod-eval-date","prior","~:dropdown-header-status",["^ "],"~:alternating-row-colors",true,"~:months",36,"~:qtr-switch",true,"~:cal-switch",true,"~:adhoc-spreads-switch",true,"~:hlv-switch",true,"~:eod-color","#c6e0b4","~:broadcast-color","#ffffcc","~:pages",[["^ ","~:id","fe3af151-f6c7-4b5d-9b91-0c186289764f","~:page","Home","~:page-products",[["^ ","^?","5e6830c2-11f4-4ecc-86fb-f6a60f2eb46c","~:grid-id","5e6830c2-11f4-4ecc-86fb-f6a60f2eb46c"]]]],"~:hlvs",7,"~:seasons",7,"~:season-current",true,"~:show-all-periods",true,"~:local-color","#ededed","~:hybrid-color","#fce3e3","~:hlv-current",true,"~:cals",4,"~:wrap-charts",false,"~:qtr-current",true,"~:cell-highlighter-settings",["^ "],"~:adhoc-spreads-rows",5,"~:quarters-border",false,"~:max-charts",20,"~:curves",["^ ","~:commodity_parent_group",true,"~:geographical_region",true,"~:commodity_group",true,"~:package",false,"~:description",false,"~:source",false,"~:field_name",false],"~:cal-current",true,"~:qtrs",13,"~:adhoc-spread-data",[["^ ","~:from","-","~:to","-","~:idx",0],["^ ","^10","-","^11","-","^12",1],["^ ","^10","-","^11","-","^12",2],["^ ","^10","-","^11","-","^12",3],["^ ","^10","-","^11","-","^12",4]]]';

const userValidation = z.object({
  email: z.string().email().min(4).nullable(),
  password: passwordValidation.nullable(),
  given_name: z.string().min(1).nullable(),
  family_name: z.string().min(1).nullable(),
  username: z.string().min(3).nullable(),
});

type FormPendingUser = z.infer<typeof userValidation>;
type TEditablePendingUserKeys = keyof FormPendingUser;

const inputWidth = 260;
const inputSx = {
  width: inputWidth,
} satisfies SxProps;

function CreateUserForm({ pendingUser }: { pendingUser?: FormPendingUser }) {
  const { orgId, tempUserId: rowId } = usePendingUser();
  const { auth0_url, auth0_org_id, env } = useAuth0Config();
  const [searchEnv] = useUrlEnv();

  const auth0 = useAuth0();

  const [executeCreateUserMutation] = useMutation(createUserMutation);

  const navigate = useNavigate({ from: "/admin/orgs/$org/users/new/$id" });

  const { floatingSaveFn, cancelSaveFn, isWaiting } = useFloatingSave();

  const auditFactory = useAuditLoggerFactory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormPendingUser>({
    defaultValues: pendingUser,
    resolver: zodResolver(userValidation),
  });

  const cancelCreation = () => {
    client.delete("pendingUser", rowId);
    navigate({
      to: "/admin/orgs/$org/users",
      search: true,
      params: { org: orgId },
    });
  };

  function onSubmit({
    given_name,
    email,
    family_name,
    password,
    username,
  }: FormPendingUser) {
    if (!email || !password || !given_name || !family_name || !username) return;
    floatingSaveFn(async () => {
      const body = {
        connection: "Username-Password-Authentication",
        password: password,
        blocked: true,
        verify_email: false,
        app_metadata: { organisation: orgId, env },
        email: email,
        given_name: given_name,
        family_name: family_name,
        user_metadata: {
          firstname: given_name,
          lastname: family_name,
          username: username,
        },
        auth0_url,
        auth0_org_id,
      } satisfies TCreateUserBody;

      console.log("in on submit", body);

      const audit = auditFactory({
        actionName: "createUser",
        clearanceLevel: "umi-internal-write",
      });

      audit.start(
        `Creating user: ${email} -> ${username} - ${given_name} ${family_name}`,
      );

      const fetchRes = fetch("/api/create-user", {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then(async (res) => {
          const result = await (res.json() as Promise<TServerResponse>);

          // did something go wrong in the lambda?
          if (res.status !== 200) {
            audit.error("Error creating user.");
            console.error("error creating auth0 user", result);
            return Promise.reject(result);
          }

          const userId = result?.[0]?.id as string;

          try {
            const variables = {
              id: userId,
              organisation: z.coerce.number().parse(orgId),
              email: email,
              firstname: given_name,
              lastname: family_name,
              username: username,
              pageSettings: defaultPageSettingsBlob,
            };

            console.log(variables);

            await executeCreateUserMutation({
              variables,
            });

            // YAY! user created successfully

            client.delete("pendingUser", rowId);

            audit.success();

            await client.insert("pendingEditUser", {
              id: userId,
              firstname: null,
              updatedBy: auth0?.user?.sub || null,
              lastname: null,
              username: null,
              subscription_tier: defaultUserSubscriptionTier.toString(),
              roles: null,
              blocked: false,
              groups: null,
            });

            navigate({
              to: "/admin/orgs/$org/users/$userId",
              search: {
                env: searchEnv,
              },
              params: { org: orgId, userId },
            });
          } catch (e) {
            audit.error("Error creating user.");

            audit.start(`Rolling back user creation: ${email}`);

            // roll back auth0 user creation
            fetch("/api/delete-user", {
              method: "DELETE",
              body: JSON.stringify({
                userId,
                auth0_url,
                env,
              } satisfies TDeleteUserBody),
            }).then((deleteRes) => {
              if (deleteRes.status !== 200) {
                audit.error("Error rolling back user creation.");
                console.error("error deleting auth0 user", deleteRes);
                return;
              }

              audit.success();
              console.log("auth0 user rolled back successfully");
            });

            console.error({ error: e });
            return Promise.reject([{ error: e?.toString() }]);
          }
        })
        .catch((e: TServerResponse) => {
          audit.error("Error creating user.");
          return Promise.reject([
            { error: e?.[0]?.error || "User creation server error" },
          ]);
        });

      return await toast.promise(fetchRes, {
        loading: "Creating user...",
        success: "User created!",
        error: (msg: TServerResponse) => {
          const errors = msg?.filter((m) => m?.error);
          const errorString = errors?.map((m) => m?.error).join("\n") || msg;
          return `${JSON.stringify(errorString)}`;
        },
      });
    });
  }

  const onBlurInput = async (k: TEditablePendingUserKeys, v: string) => {
    const existing = await client.fetchById("pendingUser", rowId);

    if (!existing) {
      return client.insert("pendingUser", {
        id: rowId,
        updatedBy: auth0.user?.sub || null,
        orgId,
        given_name: null,
        family_name: null,
        username: null,
        email: null,
        password: null,
        [k]: v,
      });
    }

    client.update("pendingUser", rowId, (row) => {
      row[k] = v;
    });
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Box sx={{ pb: 10 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ p: 4, gap: 4, display: "flex" }}>
          <InputField
            label="email"
            sx={inputSx}
            {...register("email", {
              onBlur: (e) => {
                const val = e.target.value;
                onBlurInput("email", val);
              },
            })}
            error={errors.email}
          />
          <InputField
            label="given name"
            sx={inputSx}
            {...register("given_name", {
              onBlur: (e) => {
                const val = e.target.value;
                onBlurInput("given_name", val);
              },
            })}
            error={errors.given_name}
          />
          <InputField
            label="family name"
            sx={inputSx}
            {...register("family_name", {
              onBlur: (e) => {
                const val = e.target.value;
                onBlurInput("family_name", val);
              },
            })}
            error={errors.family_name}
          />
        </Box>
        <Box sx={{ p: 4, gap: 4, display: "flex" }}>
          <InputField
            sx={inputSx}
            label="username"
            {...register("username", {
              onBlur: (e) => {
                const val = e.target.value;
                onBlurInput("username", val);
              },
            })}
            error={errors.username}
          />
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "flex-end",
            }}
          >
            <InputField
              label="password"
              sx={{ flexGrow: 1, ...inputSx }}
              type={showPassword ? "text" : "password"}
              {...register("password", {
                onBlur: (e) => {
                  const val = e.target.value;
                  onBlurInput("password", val);
                },
              })}
              error={errors.password}
            />
            <Button
              variant="outlined"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? "Hide" : "Show"}
            </Button>
          </Box>
        </Box>

        {!R.isEmpty(pendingUser || {}) && (
          <FloatingContainer>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Button
                color="warning"
                type="button"
                disabled={isWaiting}
                onClick={cancelCreation}
              >
                Cancel
              </Button>
              {isWaiting ? (
                <UndoSaving cancelSaveFn={cancelSaveFn} />
              ) : (
                <Button type="submit" color="success">
                  Create User
                </Button>
              )}
            </Box>
          </FloatingContainer>
        )}
      </form>
    </Box>
  );
}

export function CreateUser() {
  const { pendingUser, fetchingLocal } = usePendingUser();

  if (fetchingLocal) return <Loading />;

  return (
    <Box>
      <CreateUserForm pendingUser={pendingUser} />
    </Box>
  );
}

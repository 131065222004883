import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import type { QueryClient } from "@tanstack/react-query";

import type { TApolloClient } from "./context/apollo";
import type { TEnv } from "./globals";

type SearchParams = {
  env?: TEnv;
};

export type AppRouterContext = {
  queryClient: QueryClient;
  apolloClient: TApolloClient;
  userId?: string;
};

export const rootRoute = createRootRouteWithContext<AppRouterContext>()({
  component: () => <Outlet />,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    // these are auth0 params that we don't want to keep in the url
    search.code = undefined;
    search.state = undefined;
    return {
      ...search,
    };
  },
});

import { bb, kc } from "indicatorts";
import { useMemo } from "react";

import {
  type BandCalcData,
  emptyBandCalcData,
} from "../bands-indicator/bands-indicator";
import type { ITimeScaleApi, OhlcData, Time } from "lightweight-charts";
import type { TIndicatorsState } from "../utils";

export function useBandData({
  timeScale,
  data,
  indicatorsState,
}: {
  timeScale: ITimeScaleApi<Time> | null;
  data: OhlcData<Time>[];
  indicatorsState: TIndicatorsState;
}) {
  const visibleData = useMemo(() => {
    if (!data || !data.length) return [];
    const bandEnabled = bandIndicatorEnabled(indicatorsState);

    if (!bandEnabled) return [];

    const range = timeScale?.getVisibleRange();
    if (!range) return [];

    const oldestPriceIndex = findClosestIndex(Number(range.from), data);
    const newestPriceIndex = findClosestIndex(Number(range.to), data);

    if (!oldestPriceIndex || !newestPriceIndex) return [];

    const padding = 100;
    const paddingExceedsData = newestPriceIndex + padding > data.length - 1;

    // if padding exceeds data, include all data after newestPriceIndex.
    if (paddingExceedsData) {
      return data.slice(oldestPriceIndex - padding);
    }

    const visibleData = data.slice(
      oldestPriceIndex - padding,
      newestPriceIndex + padding,
    );

    return visibleData;
  }, [data, timeScale, indicatorsState]);

  const bandData = useMemo(() => {
    if (!visibleData?.length || !bandIndicatorEnabled(indicatorsState)) {
      return {
        bb: emptyBandCalcData,
        kc: emptyBandCalcData,
      };
    }

    const highs: number[] = [];
    const lows: number[] = [];
    const closings: number[] = [];
    const times: Time[] = [];
    for (const d of visibleData) {
      highs.push(d.high);
      lows.push(d.low);
      closings.push(d.close);
      times.push(d.time);
    }

    const calcs = indicatorsState.reduce(
      (acc, indicator) => {
        if (!indicator.show) return acc;

        if (indicator.stateType === "BollingerBands") {
          const calc = bb(closings, { period: indicator.period });
          acc.bb = {
            ...calc,
            times,
          };
        }

        if (indicator.stateType === "KeltnerChannels") {
          const calc = kc(closings, highs, lows, { period: indicator.period });
          acc.kc = {
            ...calc,
            times,
          };
        }

        return acc;
      },
      {
        bb: emptyBandCalcData,
        kc: emptyBandCalcData,
      } as Record<"bb" | "kc", BandCalcData>,
    );

    return calcs;
  }, [visibleData, indicatorsState]);

  return bandData;
}

// Needs to be performant for charts.
export function findClosestIndex(targetTime: number, data: OhlcData<Time>[]) {
  if (!data?.length) return null;

  let closestIndex = 0;
  let smallestDifference = Math.abs(targetTime - Number(data[0].time));

  for (let i = 1; i < data.length; i++) {
    const difference = Math.abs(targetTime - Number(data[i].time));
    if (difference < smallestDifference) {
      smallestDifference = difference;
      closestIndex = i;
    }
  }

  return closestIndex;
}

export function bandIndicatorEnabled(indicators: TIndicatorsState) {
  return indicators.some(
    (i) =>
      i.show && ["BollingerBands", "KeltnerChannels"].includes(i.stateType),
  );
}

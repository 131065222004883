import { Auth0Provider } from "@auth0/auth0-react";
import type { ReactNode } from "react";
import posthog from "posthog-js";

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "../../globals";

export function AuthProvider({ children }: { children: ReactNode }) {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      useRefreshTokensFallback={true}
      onRedirectCallback={(state) => {
        posthog.capture("login", {
          $set_once: {
            first_login: new Date().toISOString(),
          },
        });
        if (state?.returnTo) {
          window.location.href = window.location.origin + state.returnTo;
        }
      }}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "hasura",
      }}
    >
      {children}
    </Auth0Provider>
  );
}

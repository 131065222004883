import { memo } from "react";

import { useGridConfigureMonthColumn } from "./useGridConfigureMonthColumn";
import { useDynamicCssExtraHeightRow } from "./useDynamicCssExtraHeightRow";
import { useLivePricesPlugin } from "./live-prices";

export const RegisterGridPlugins = memo(() => {
  useDynamicCssExtraHeightRow();
  useGridConfigureMonthColumn();
  useLivePricesPlugin();
  return null;
});

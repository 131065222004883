import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";

import { useGlobalEnv } from "../context/environment";
import { client } from "../triplit/triplit";
import { parse } from "../webapp/numbers";
import type { JWT } from "../globals";

// Doesn't actually provide anything but requires data from useAuth0
// in order to connect to the Triplit API
export const TriplitProvider = (p: { children: React.ReactNode }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const clientEnv = useGlobalEnv();

  useEffect(() => {
    if (!isAuthenticated) return;
    getAccessTokenSilently().then((token) => {
      const jwt = jwtDecode<JWT>(token);
      const auth0OrgId = jwt.org_id;
      const hasuraOrgId = parse(
        jwt?.["https://hasura.io/jwt/claims"]?.["x-hasura-org-id"],
      );
      const globals = {
        userId: jwt.sub,
        auth0OrgId,
        hasuraOrgId,
        env: clientEnv,
      };
      if (client.token) {
        client.updateSessionToken(token);
      } else {
        client.startSession(token, false);
      }
      if (client.connectionStatus !== "OPEN") {
        client.connect();
      }
      client.db.updateGlobalVariables({
        ...globals,
      });
    });
    return () => {
      console.log("Unmounting TriplitProvider");
      client.endSession();
    };
  }, [getAccessTokenSilently, isAuthenticated, clientEnv]);

  return p.children;
};

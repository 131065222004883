import {
  Box,
  Button,
  DialogTitle,
  Divider,
  Drawer,
  ModalClose,
  Option,
  Select,
  Sheet,
  Typography,
  Stack,
} from "@mui/joy";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useState } from "react";
import type React from "react";
import { useThemeMode } from "../../context/theme";
import {
  gridSettingsResetConfirmationZIndex,
  gridSettingsWidth,
  gridSettingsZIndex,
  sideBarWidth,
} from "../globals";
import { ColorPicker } from "./ColorPicker";
import {
  useGridSettings,
  useGridSettingsVisible,
  useSelectGridSettings,
  useUpdateGridSettings,
} from "./hooks";
import { useUserHasRole } from "../layout/hooks";
import { useUserId } from "../../context/auth";
import { initGridSettings } from "../market-grid/defaultSettings";
import Checkbox from "./Checkbox";
import { useWebappTheme } from "../../styles/extendTheme";
import { SettingsDateColumn } from "./SettingsDateColumn";
import { useUserSubscriptionTier } from "../market-pages/hooks";
import { useSetThemeMode } from "../../context/theme";

export function CollapsibleSettings() {
  const [isGridSettingsVisible, setIsGridSettingsVisible] =
    useGridSettingsVisible();
  const theme = useWebappTheme();
  const update = useUpdateGridSettings();
  const { liteUser } = useUserSubscriptionTier();
  const userId = useUserId();

  const scratchpadEnabled = useUserHasRole("scratchpad-beta");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    update.mutateAsync({
      id: userId,
      updaterFn: (x) => {
        const keys = Object.keys(
          initGridSettings,
        ) as (keyof typeof initGridSettings)[];
        keys.forEach((key) => {
          // As never is used here to avoid type errors, as the keys are guaranteed to be the same and as any does not work
          x[key] = initGridSettings[key] as never;
        });
      },
    });
    setOpen(false);
  };

  return (
    <Drawer
      open={isGridSettingsVisible}
      onClose={() => setIsGridSettingsVisible(false)}
      hideBackdrop
      slotProps={{
        root: {
          sx: {
            zIndex: gridSettingsZIndex,
            width: {
              xs: "100%",
              md: `${gridSettingsWidth}px`,
            },
          },
        },
        content: {
          sx: {
            left: {
              xs: 0,
              md: sideBarWidth,
            },
            width: {
              xs: "100%",
              md: `${gridSettingsWidth}px`,
            },
            backgroundColor: "transparent",
            borderTopRightRadius: "1em",
            borderBottomRightRadius: "1em",
          },
        },
      }}
      sx={{
        "--Drawer-horizontalSize": {
          sm: `${gridSettingsWidth}px`,
          xs: "100%",
        },
        zIndex: gridSettingsZIndex,
      }}
    >
      <Sheet
        sx={{
          borderTopRightRadius: "md",
          borderBottomRightRadius: "md",
          p: 2,
          display: "flex",
          position: "relative",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          height: "100%",
          overflow: "auto",
          backgroundColor: theme.palette.background.level1,
        }}
      >
        <DialogTitle>Settings</DialogTitle>
        <ModalClose sx={{ position: "absolute", top: 10, right: 10 }} />
        <Divider />

        <div className="flex justify-between">
          <div className="flex flex-col gap-4">
            <SettingsDateColumn />
            <SettingsAppearance />
            <Button
              disabled={liteUser}
              color="neutral"
              sx={{
                display: "flex",
                position: "relative",
                top: 10,
                width: "170px",
              }}
              onClick={handleOpen}
            >
              Restore default settings
            </Button>
            <ClickAwayListener
              disableReactTree
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={handleClose}
            >
              <BasePopup
                open={open}
                style={{
                  zIndex: gridSettingsResetConfirmationZIndex,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Sheet
                  variant="outlined"
                  sx={(theme) => ({
                    background: theme.palette.background.body,
                    padding: 2,
                    borderRadius: theme.radius.md,
                    zIndex: gridSettingsResetConfirmationZIndex + 1,
                  })}
                >
                  <h2>Reset Settings</h2>
                  <Typography
                    sx={{
                      marginTop: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    Reset all grid settings back to default?
                  </Typography>
                  <Button
                    onClick={handleConfirm}
                    sx={{ marginRight: 1 }}
                    color="primary"
                  >
                    Reset
                  </Button>
                  <Button color="neutral" onClick={handleClose}>
                    Cancel
                  </Button>
                </Sheet>
              </BasePopup>
            </ClickAwayListener>
          </div>
          {/* hidden on mobile */}
          <div className="hidden md:flex justify-around w-full">
            <div className="flex flex-col gap-8">
              <SettingsTimeSpreads />
              <SettingsFullMonthRow />
              <SettingsAlerts />
              {scratchpadEnabled && <SettingsScratchpad />}
            </div>
            <div className="flex flex-col gap-4 mr-5">
              <SettingsColorOptions />
              <SettingsColumnColorOptions />
            </div>
          </div>
        </div>
      </Sheet>
    </Drawer>
  );
}

function SettingsAppearance() {
  const setMode = useSetThemeMode();
  const mode = useThemeMode();
  const update = useUpdateGridSettings();
  const settings = useGridSettings();
  const { liteUser } = useUserSubscriptionTier();
  const userId = useUserId();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Checkbox
        disabled={liteUser}
        label="Flash cell updates"
        checked={Boolean(settings.flashCellUpdates)}
        onChange={(event) =>
          update.mutateAsync({
            id: userId,
            updaterFn: (x) => {
              x.flashCellUpdates = !!event.target.checked;
            },
          })
        }
      />
      <Checkbox
        disabled={liteUser}
        label="Alternating row shading"
        checked={Boolean(settings.alternatingRowColours)}
        onChange={(event) => {
          update.mutateAsync({
            id: userId,
            updaterFn: (x) => {
              x.alternatingRowColours = !!event.target.checked;
            },
          });
        }}
      />
      <Checkbox
        disabled={liteUser}
        label="Hide status row"
        checked={Boolean(settings.hideStatusRow)}
        onChange={(event) => {
          update.mutateAsync({
            id: userId,
            updaterFn: (x) => {
              x.hideStatusRow = !!event.target.checked;
            },
          });
        }}
      />
      <Checkbox
        disabled={liteUser}
        label="Dark mode"
        checked={mode === "dark"}
        onChange={() => {
          const newMode = mode === "dark" ? "light" : "dark";
          setMode(newMode);
        }}
      />
      <Checkbox
        disabled={liteUser}
        label="Disable EOD"
        checked={Boolean(settings.hideEOD)}
        onChange={(event) => {
          update.mutateAsync({
            id: userId,
            updaterFn: (x) => {
              x.hideEOD = !!event.target.checked;
            },
          });
        }}
      />
      <Checkbox
        disabled={liteUser}
        label="Disable Broadcast"
        checked={Boolean(settings.hideBroadcast)}
        onChange={(event) => {
          update.mutateAsync({
            id: userId,
            updaterFn: (x) => {
              x.hideBroadcast = !!event.target.checked;
            },
          });
        }}
      />
      <Checkbox
        disabled={liteUser}
        label="Disable Private"
        checked={Boolean(settings.hidePrivate)}
        onChange={(event) => {
          update.mutateAsync({
            id: userId,
            updaterFn: (x) => {
              x.hidePrivate = !!event.target.checked;
            },
          });
        }}
      />
      <Checkbox
        disabled={liteUser}
        label="Disable Global"
        checked={Boolean(settings.hideGlobal)}
        onChange={(event) =>
          update.mutateAsync({
            id: userId,
            updaterFn: (x) => {
              x.hideGlobal = !!event.target.checked;
            },
          })
        }
      />
    </Box>
  );
}

function SettingsAlerts() {
  const update = useUpdateGridSettings();
  const settings = useGridSettings();
  const { liteUser } = useUserSubscriptionTier();
  const userId = useUserId();

  return (
    <>
      <Box sx={{ justifyContent: "space-between" }}>
        <Typography level="h4" fontWeight="bold" sx={{ mt: 1 }}>
          Alert settings
        </Typography>

        <Checkbox
          disabled={liteUser}
          label="Enable Alert sound"
          checked={!!settings.sound}
          onChange={(event) =>
            update.mutateAsync({
              id: userId,
              updaterFn: (x) => {
                x.sound = !!event.target.checked;
              },
            })
          }
        />
      </Box>
    </>
  );
}

function SettingsFullMonthRow() {
  const update = useUpdateGridSettings();
  const fullMonthRow = useSelectGridSettings(
    (x) => x.enableFullMonthRowFeature,
  );
  const { liteUser } = useUserSubscriptionTier();
  const userId = useUserId();

  return (
    <>
      <Box sx={{ justifyContent: "space-between" }}>
        <Typography level="h4" fontWeight="bold" sx={{ mt: 1 }}>
          Full Month row
        </Typography>
        <Checkbox
          disabled={liteUser}
          label="Enable"
          checked={!!fullMonthRow}
          onChange={(event) =>
            update.mutateAsync({
              id: userId,
              updaterFn: (x) => {
                x.enableFullMonthRowFeature = !!event.target.checked;
              },
            })
          }
        />
      </Box>
    </>
  );
}

function SettingsTimeSpreads() {
  const userId = useUserId();
  const update = useUpdateGridSettings();
  const adhocSpreadsSwitch = useSelectGridSettings((x) => x.adhocSpreadsSwitch);
  const adhocSpreadsRows = useSelectGridSettings((x) => x.adhocSpreadsRows);
  const { liteUser } = useUserSubscriptionTier();
  return (
    <>
      <Stack gap={1}>
        <Box sx={{ justifyContent: "space-between" }}>
          <Typography level="h4" fontWeight="bold" sx={{ mt: 1 }}>
            Time Spreads
          </Typography>

          <Checkbox
            disabled={liteUser}
            label="Show"
            checked={!!adhocSpreadsSwitch}
            onChange={(event) =>
              update.mutateAsync({
                id: userId,
                updaterFn: (x) => {
                  x.adhocSpreadsSwitch = !!event.target.checked;
                },
              })
            }
          />
        </Box>
        <Stack justifyContent={"space-between"} flexDirection={"row"}>
          <Typography level="body-sm" sx={{ mt: 1, mr: 1 }}>
            Rows to Show
          </Typography>

          <Select
            sx={{ width: 60, height: 30 }}
            value={adhocSpreadsRows ?? 0}
            onChange={async (_e, newValue) =>
              newValue !== null &&
              update.mutateAsync({
                id: userId,
                updaterFn: (x) => {
                  x.adhocSpreadsRows = newValue;
                },
              })
            }
          >
            {Array.from({ length: 20 }, (_, i) => {
              const value = i + 1;
              return (
                <Option key={value} value={value}>
                  {value}
                </Option>
              );
            })}
          </Select>
        </Stack>
      </Stack>
    </>
  );
}

function SettingsScratchpad() {
  const hasScratchpadRole = useUserHasRole("scratchpad-beta");
  const { liteUser } = useUserSubscriptionTier();
  const userId = useUserId();

  const update = useUpdateGridSettings();
  const settings = useGridSettings();

  if (!hasScratchpadRole) {
    return null;
  }
  const NumberOptions = () => {
    return (
      <>
        {Array.from({ length: 20 }, (_, i) => {
          const value = i + 1;
          return (
            <Option key={value} value={value}>
              {value}
            </Option>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Box sx={{ justifyContent: "space-between" }}>
        <Typography level="h4" fontWeight="bold" sx={{ mt: 1 }}>
          Scratchpad
        </Typography>

        <Checkbox
          disabled={liteUser}
          label="Enable (beta)"
          checked={!!settings.gridScratchpadSwitch}
          onChange={(event) =>
            update.mutateAsync({
              id: userId,
              updaterFn: (x) => {
                x.gridScratchpadSwitch = !!event.target.checked;
              },
            })
          }
        />
        <Box
          sx={{
            width: 150,
            justifyContent: "space-between",
            display: "flex",
            marginTop: "6px",
          }}
        >
          <Typography
            sx={{
              verticalAlign: "middle",
              paddingTop: 1,
            }}
          >
            Rows
          </Typography>
          <Select
            sx={{ width: 60, height: 30 }}
            value={settings.scratchpadMaxRows ?? 0}
            onChange={async (_e, newValue) =>
              newValue !== null &&
              update.mutateAsync({
                id: userId,
                updaterFn: (x) => {
                  x.scratchpadMaxRows = newValue;
                },
              })
            }
            aria-label="Scratchpad max rows"
          >
            <NumberOptions />
          </Select>
        </Box>
        <Box
          sx={{
            width: 150,
            justifyContent: "space-between",
            display: "flex",
            marginTop: "5px",
          }}
        >
          <Typography sx={{ verticalAlign: "middle", paddingTop: 1 }}>
            Columns
          </Typography>
          <Select
            sx={{ width: 60, height: 30 }}
            value={settings.scratchpadMaxCols ?? 0}
            onChange={async (_e, newValue) =>
              newValue !== null &&
              update.mutateAsync({
                id: userId,
                updaterFn: (x) => {
                  x.scratchpadMaxCols = newValue;
                },
              })
            }
            aria-label="Max Scratchpad columns"
          >
            <NumberOptions />
          </Select>
        </Box>
      </Box>
    </>
  );
}

export type GridColorSetting = {
  title: string;
  key:
    | "indicatorColour"
    | "headerColumnColour"
    | "subheaderColumnColour"
    | "broadcastColour"
    | "hybridColour"
    | "listenColour"
    | "eodColour"
    | "localColour"
    | "globalColour";
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  value: string | null;
};

function SettingsColorOptions() {
  const update = useUpdateGridSettings();
  const settings = useGridSettings();
  const { liteUser } = useUserSubscriptionTier();
  const userId = useUserId();
  const [showIndicatorColour, setShowIndicatorColour] = useState(false);
  const [showHeaderColumnColour, setShowHeaderColumnColour] = useState(false);
  const [showSubheaderColumnColour, setShowSubheaderColumnColour] =
    useState(false);

  const [loadingStates, setLoadingStates] = useState({
    headerColumnColour: false,
    subheaderColumnColour: false,
    indicatorColour: false,
  });

  const storeColorCallback = async (
    key: GridColorSetting["key"],
    value: string | null,
  ) => {
    setLoadingStates((prev) => ({ ...prev, [key]: true }));
    try {
      await update.mutateAsync({
        id: userId,
        updaterFn: (x) => {
          x[key] = value;
        },
      });
    } catch (error) {
      console.error(`Failed to update grid setting ${key}:`, error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [key]: false }));
    }
  };

  const colorSettings = [
    {
      title: "Header",
      key: "headerColumnColour",
      show: showHeaderColumnColour,
      setShow: setShowHeaderColumnColour,
      value: settings.headerColumnColour ?? initGridSettings.headerColumnColour,
    },
    {
      title: "Subheader",
      key: "subheaderColumnColour",
      show: showSubheaderColumnColour,
      setShow: setShowSubheaderColumnColour,
      value:
        settings.subheaderColumnColour ??
        initGridSettings.subheaderColumnColour,
    },
    {
      title: "Row Indicator",
      key: "indicatorColour",
      show: showIndicatorColour,
      setShow: setShowIndicatorColour,
      value: settings.indicatorColour ?? null,
    },
  ] satisfies GridColorSetting[];

  return (
    <>
      <Typography
        level="h4"
        fontWeight="bold"
        sx={{ mt: 0 }}
        {...(liteUser ? { textColor: "text.disabled" } : {})}
      >
        Grid colours
      </Typography>

      <Box>
        {colorSettings.map(({ title, key, show, setShow, value }) => (
          <Box key={`setting-${key}`}>
            <Typography
              sx={{ flexShrink: 0 }}
              {...(liteUser ? { textColor: "text.disabled" } : {})}
            >
              {title}
            </Typography>
            <ColorPicker
              disabled={liteUser}
              setting={key}
              show={show}
              setShow={setShow}
              value={{
                color: value || undefined,
              }}
              isLoading={loadingStates[key]}
              onChange={(newValue) => {
                storeColorCallback(key, newValue.color || null);
              }}
            />
          </Box>
        ))}
      </Box>
    </>
  );
}

function SettingsColumnColorOptions() {
  const settings = useGridSettings();
  const update = useUpdateGridSettings();
  const { liteUser } = useUserSubscriptionTier();
  const userId = useUserId();

  const [showBroadcastColour, setShowBroadcastColour] = useState(false);
  const [showHybridColour, setShowHybridColour] = useState(false);
  const [showListenColour, setShowListenColour] = useState(false);
  const [showEodColour, setShowEodColour] = useState(false);
  const [showLocalColour, setShowLocalColour] = useState(false);
  const [showGlobalColour, setShowGlobalColour] = useState(false);

  const [loadingStates, setLoadingStates] = useState({
    broadcastColour: false,
    hybridColour: false,
    listenColour: false,
    eodColour: false,
    localColour: false,
    globalColour: false,
  });

  const storeColorCallback = async (
    key: GridColorSetting["key"],
    value: string | null,
  ) => {
    setLoadingStates((prev) => ({
      ...prev,
      [key]: true,
    }));

    try {
      await update.mutateAsync({
        id: userId,
        updaterFn: (x) => {
          x[key] = value;
        },
      });
    } catch (error) {
      console.error(`Failed to update grid setting ${key}:`, error);
    } finally {
      setLoadingStates((prev) => ({
        ...prev,
        [key]: false,
      }));
    }
  };

  const colorSettings = [
    {
      title: "Hybrid",
      key: "hybridColour",
      show: showHybridColour,
      setShow: setShowHybridColour,
      value: settings.hybridColour ?? null,
    },
    {
      title: "Listen",
      key: "listenColour",
      show: showListenColour,
      setShow: setShowListenColour,
      value: settings.listenColour ?? null,
    },

    {
      title: "Broadcast",
      key: "broadcastColour",
      show: showBroadcastColour,
      setShow: setShowBroadcastColour,
      value: settings.broadcastColour ?? null,
    },
    {
      title: "Private",
      key: "localColour",
      show: showLocalColour,
      setShow: setShowLocalColour,
      value: settings.localColour ?? null,
    },

    {
      title: "EOD",
      key: "eodColour",
      show: showEodColour,
      setShow: setShowEodColour,
      value: settings.eodColour ?? null,
    },
    {
      title: "Global",
      key: "globalColour",
      show: showGlobalColour,
      setShow: setShowGlobalColour,
      value: settings.globalColour ?? null,
    },
  ] satisfies GridColorSetting[];

  return (
    <>
      <Typography
        level="h4"
        fontWeight="bold"
        sx={{ mt: 0 }}
        {...(liteUser ? { textColor: "text.disabled" } : {})}
      >
        Status colours
      </Typography>
      <Box>
        {colorSettings.map(({ title, key, show, setShow, value }) => (
          <Box key={`setting-${key}`}>
            <Typography
              sx={{ flexShrink: 0 }}
              {...(liteUser ? { textColor: "text.disabled" } : {})}
            >
              {title}
            </Typography>
            <ColorPicker
              disabled={liteUser}
              setting={key}
              show={show}
              setShow={setShow}
              value={{
                color: value || undefined,
              }}
              isLoading={loadingStates[key]}
              onChange={(newValue) => {
                storeColorCallback(key, newValue.color || null);
              }}
            />
          </Box>
        ))}
      </Box>
    </>
  );
}

import { QueryClient } from "@tanstack/react-query";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { atom } from "jotai";
import dayjs from "dayjs";

import type {
  TFetchedData,
  TInfiniteQueryData,
  TQuestResponse,
  TRegularQueryData,
} from "./types";
import { questDbUrl } from "../../../globals";
import dayjsRoundPlugin from "../dayjsRoundPlugin";
import { isNullish } from "remeda";
import type { UTCTimestamp } from "lightweight-charts";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(dayjsRoundPlugin);

export const date = dayjs;

export const chartFetchingAtom = atom(false);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Number.POSITIVE_INFINITY,
    },
  },
});

const oldestDataTimestamp = new Date("2024-05-22T15:00:33.423715Z");

export async function fetchQuestData({
  fromUTCDate,
  toUTCDate,
  product,
  field,
  token,
  sampleTime,
  periodFrom,
  isCalc,
  fn,
}: {
  fromUTCDate: string;
  toUTCDate: string | null;
  product: string;
  field: string;
  token: string;
  sampleTime: string;
  periodFrom: string;
  isCalc: boolean;
  fn?: (res: Response) => unknown;
}) {
  const fromDate = dayjs.utc(fromUTCDate);
  const toDate = toUTCDate ? dayjs.utc(toUTCDate) : dayjs.utc();

  if (!fromDate.isValid() || !toDate.isValid()) {
    throw new Error(
      `Invalid date format: fromDate=${fromUTCDate} (${fromDate.isValid()}), toDate=${toUTCDate} (${toDate.isValid()})`,
    );
  }

  if (toUTCDate && new Date(toUTCDate) < oldestDataTimestamp) {
    console.log("No more historical data to fetch.");
    return {
      data: [],
      fromUTCDate,
      toUTCDate,
      timeTaken: 0,
    } satisfies TFetchedData;
  }

  // Ensure valid timestamps
  const fromTimestamp = Math.floor(Date.parse(fromUTCDate));
  const toTimestamp = toUTCDate
    ? Math.floor(Date.parse(toUTCDate))
    : Math.floor(Date.now());

  if (Number.isNaN(fromTimestamp) || Number.isNaN(toTimestamp)) {
    throw new Error("Invalid date format");
  }

  const query = {
    id: product,
    period_from: periodFrom,
    from: fromTimestamp,
    to: toTimestamp,
    sample: sampleTime,
    field,
    isCalc,
  };

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const questUrl = questDbUrl() || "";

  const url = new URL(questUrl);
  url.searchParams.append("query", JSON.stringify(query));

  const options: RequestInit = {
    method: "GET",
    headers,
  };

  const startTime = performance.now();

  try {
    const res = await fetch(url.toString(), options);

    const endTime = performance.now();
    const timeTaken = endTime - startTime;

    if (!res.ok) {
      if (res.status === 503) {
        throw new Error("Service Unavailable");
      }
      const errorText = await res.text();
      throw new Error(`Failed to fetch data: ${errorText}`);
    }

    const resData = (await res.json()) as TQuestResponse;
    const candles = resData;
    if (!candles) throw new Error("Invalid response data");
    const data = [candles] as TQuestResponse;

    if (fn) {
      fn(res);
    }

    return {
      data,
      fromUTCDate,
      toUTCDate,
      timeTaken,
    } satisfies TFetchedData;
  } catch (error) {
    console.error("Failed to fetch data", error, {
      query,
      fromUTCDate,
      toUTCDate,
    });
    throw error;
  }
}

export function checkCacheForData({
  productId,
  sampleTime,
  periodFrom,
  fromUTCDate,
  toUTCDate,
}: {
  productId: string;
  sampleTime: string;
  periodFrom: string | undefined;
  fromUTCDate: string;
  toUTCDate: string;
}) {
  const cachedData = queryClient.getQueryData(
    buildHistoricalChartDataQueryKey({
      productId,
      sampleTime,
      periodFrom,
    }),
  ) as TInfiniteQueryData & { pages: TRegularQueryData[] };

  if (cachedData != null) {
    const page = cachedData.pages.find((page: TRegularQueryData) => {
      if (
        (dayjs.utc(fromUTCDate).isSame(page.fromUTCDate) ||
          dayjs.utc(fromUTCDate).isAfter(page.fromUTCDate)) &&
        (dayjs.utc(toUTCDate).isSame(page.toUTCDate) ||
          dayjs.utc(toUTCDate).isBefore(page.toUTCDate))
      ) {
        return page;
      }
    });

    if (page != null) {
      return page;
    }
  }
}

export function buildHistoricalChartDataQueryKey({
  productId,
  sampleTime,
  periodFrom,
}: {
  productId: string;
  sampleTime: string;
  periodFrom: string | undefined;
}) {
  return ["historicalChartData", productId, sampleTime, periodFrom];
}

function fromUTCToUnix(d: string) {
  return Math.floor(new Date(d).getTime() / 1000) as UTCTimestamp;
}

export function parseQuestDBData(
  data: [string, number, number, number, number][],
) {
  return data.map(([timestamp, open, close, min, max]) => {
    const isEmptyEntry = isNullish(open);
    const time = fromUTCToUnix(timestamp);
    return isEmptyEntry ? { time } : { time, open, close, low: min, high: max };
  });
}

import { useCallback, useRef } from "react";
import {
  pressedKeysAtom,
  rangeSelectionAtom,
  refreshTargetCells,
  store,
  type TRangeSelection,
} from "../sharedHooks";
import type { RangeSelectionChangedEvent } from "ag-grid-community";
import { selectedCellsByRange } from "../../tableUtils";
import { gridApiAtom } from "./stores";

export function useRangeSelectionChanged() {
  const groupRanges = useRef<Set<number>>(new Set());

  return useCallback((_: RangeSelectionChangedEvent) => {
    const api = store.get(gridApiAtom);
    if (!api) return;
    const pressedKeys = store.get(pressedKeysAtom);
    const selectedRanges = selectedCellsByRange(
      api,
      [] as TRangeSelection,
      ({ column, rowNode, store, rangeIdx }) => {
        if (
          !column ||
          !rowNode.id ||
          rowNode?.rowIndex === undefined ||
          rowNode?.rowIndex === null
        )
          return;
        store[rangeIdx] = store[rangeIdx] ?? [];
        store[rangeIdx].push({
          columnId: column.getId(),
          rowId: rowNode.id,
          rowIndex: rowNode.rowIndex,
          headerName: column?.getColDef()?.headerName || column.getId(),
        });
      },
    );

    const oldRowIdsToUpdate = store
      .get(rangeSelectionAtom)
      .flat()
      ?.map((x) => x.rowId);
    const newSelectedRange =
      selectedRanges[selectedRanges.length - 1]?.map((x) => x.rowId) || [];

    if (selectedRanges.length === 1) {
      const newIdsToUpdate = selectedRanges[0]?.map((x) => x.rowId);

      store.set(rangeSelectionAtom, selectedRanges);
      groupRanges.current = new Set();
      refreshTargetCells([...oldRowIdsToUpdate, ...newIdsToUpdate], api);
      return;
    }

    const addLastRangeToExistingGroup =
      (pressedKeys.has("Control") || pressedKeys.has("Meta")) &&
      pressedKeys.has("Alt");

    if (addLastRangeToExistingGroup) {
      const lastGroupIdx = selectedRanges.length - 1;
      groupRanges.current.add(lastGroupIdx);
    }

    const groupedRanges: TRangeSelection = [];

    selectedRanges?.map((range, idx) => {
      const isPartOfCurrentGroup = groupRanges.current.has(idx);

      // we push an empty array to be populated with the current range
      // so length - 1 works as new group
      if (!isPartOfCurrentGroup) {
        groupedRanges.push([]);
      }

      // Add the current range to the last group in the groupedRanges
      groupedRanges[groupedRanges.length - 1].push(...range);
    });
    store.set(rangeSelectionAtom, groupedRanges);
    refreshTargetCells([...oldRowIdsToUpdate, ...newSelectedRange], api);
  }, []);
}

import { shallow } from "@tanstack/react-router";
import { useAtomValue, type Atom } from "jotai";
import { selectAtom } from "jotai/utils";
import { useMemo, useRef } from "react";

export const useSelectAtomValue = <A, B>(
  atom: Atom<A>,
  select: (x: A) => B,
) => {
  const selectRef = useRef(select);
  selectRef.current = select;
  return useAtomValue(
    useMemo(() => selectAtom(atom, (x) => selectRef.current(x)), [atom]),
  );
};

export const useSelectShallowAtomValue = <A, B>(
  atom: Atom<A>,
  select: (x: A) => B,
) => {
  const selectRef = useRef(select);
  selectRef.current = select;
  return useAtomValue(
    useMemo(
      () => selectAtom(atom, (x) => selectRef.current(x), shallow),
      [atom],
    ),
  );
};

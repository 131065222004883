import { Box, Select, Option } from "@mui/joy";
import { useSideBar, useEnvLabel } from "./hooks";
import { environments, useUrlEnv } from "../../context/environment";
import { useResetUserFilters } from "../components/tableHelpers";

export function EnvSelect() {
  const { collapsedSidebar, toggleSidebar } = useSideBar();
  const [selectedEnv, setSelectedEnv] = useUrlEnv();
  const envLabel = useEnvLabel();
  const { resetUserFilters } = useResetUserFilters();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      <Select
        renderValue={(_) => envLabel}
        onChange={(_e, value) => {
          resetUserFilters();
          return setSelectedEnv(value || "dev");
        }}
        value={selectedEnv}
        variant="soft"
        sx={{
          flex: "1",
          ...(collapsedSidebar && {
            fontSize: "0.8rem",
            "& .MuiSelect-indicator": {
              display: "none",
            },
            "& .MuiSelect-button": {
              display: "flex",
              justifyContent: "center",
            },
          }),
        }}
        size="sm"
      >
        {environments.map(({ label, value }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>

      <Box
        onClick={() => toggleSidebar(!collapsedSidebar)}
        sx={{
          ...(collapsedSidebar && {
            width: "100%",
          }),
          display: {
            xs: collapsedSidebar ? "none" : "flex",
            md: "flex",
          },
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            opacity: 0.8,
          },
          cursor: "pointer",
          transform: collapsedSidebar ? "rotate(180deg)" : "rotate(0deg)",
        }}
      >
        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          width="20"
          height="20"
        >
          <title>{"Toggle sidebar"}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.59 6.47a.75.75 0 0 0-1.06 0l-5.06 5a.75.75 0 0 0 0 1.06l5.06 5a.75.75 0 0 0 1.05-1.06l-3.76-3.72H21a.75.75 0 0 0 0-1.5H9.82l3.76-3.72c.3-.29.3-.76 0-1.06Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3.25a.75.75 0 0 0-.75.75v16a.75.75 0 0 0 1.5 0V4A.75.75 0 0 0 3 3.25Z"
          />
        </svg>
      </Box>
    </Box>
  );
}

import { z } from "zod";
import { client } from "../triplit/triplit";

const subscriptionTier = z.enum([
  "artis_enhanced",
  "artis_enhanced_noexch",
  "artis_lite",
  "artis_professional",
]);

export const rolesSchema = z.preprocess(
  (input) => {
    let strArray: unknown[] = [];

    if (typeof input === "undefined") {
      return;
    }
    if (typeof input === "string") {
      strArray = input.split(",");
    } else if (Array.isArray(input)) {
      strArray = input;
    } else if (input instanceof Set) {
      strArray = Array.from(input);
    }

    return new Set(strArray);
  },
  z.set(z.string()).nonempty({ message: "Must have at least one role" }),
);

export const defaultUserSubscriptionTier =
  subscriptionTier.enum.artis_professional;

export const orderedSubscriptionTiers = [
  subscriptionTier.enum.artis_lite,
  subscriptionTier.enum.artis_enhanced_noexch,
  subscriptionTier.enum.artis_enhanced,
  subscriptionTier.enum.artis_professional,
];

export function parseRoles(roles?: unknown) {
  const parseResult = rolesSchema.safeParse(roles);
  if (parseResult.success) {
    return parseResult.data;
  }
  return new Set(["user"]);
}

export function parseSubscriptionTier(subscription_tier?: unknown) {
  const parseResult = subscriptionTier.safeParse(subscription_tier);
  if (parseResult.success) {
    return parseResult.data;
  }
  return defaultUserSubscriptionTier;
}

export const userEditValidation = z.object({
  firstname: z.string().min(1).nullable(),
  lastname: z.string().min(1).nullable(),
  username: z.string().min(2).nullable(),
  subscription_tier: z.string().nullable().optional(),
  roles: z.set(z.string()).nullish(),
  blocked: z.boolean().nullable(),
});

export type FormPendingEditUser = z.infer<typeof userEditValidation>;

export type TPendingEditUser = z.infer<typeof userEditValidation> & {
  updatedBy: string | null | undefined;
  updatedAt: Date | undefined;
};

export type TEditablePendingUserKeys = keyof TPendingEditUser;
export type TFormPendingEditUserKeys = keyof FormPendingEditUser;

export function tableEditableKeys(pendingEditUser: TPendingEditUser) {
  return Object.keys(pendingEditUser).filter(
    (key) => key !== "updatedAt" && key !== "updatedBy",
  ) as TFormPendingEditUserKeys[];
}

export function upsertExistingUser<K extends TEditablePendingUserKeys>({
  key,
  value,
  updatePendingUser,
  userId,
  pendingEditUser,
  updatedBy,
}: {
  key: K;
  value: TPendingEditUser[K];
  updatePendingUser: (k: K, v: TPendingEditUser[K]) => void;
  userId: string;
  pendingEditUser: TPendingEditUser | undefined;
  updatedBy: string | null;
}) {
  const existing = pendingEditUser;

  if (!existing) {
    return client.insert("pendingEditUser", {
      id: userId,
      firstname: null,
      updatedBy: updatedBy,
      lastname: null,
      username: null,
      subscription_tier: defaultUserSubscriptionTier.toString(),
      roles: null,
      blocked: null,
      groups: null,
      [key]: value,
    });
  }

  updatePendingUser(key, value);
}

import { Box } from "@mui/joy";
import { HeaderArtisLogo } from "./HeaderArtisLogo";
import { agGridCellAutoPadding, headerHeight } from "../../globals";
import { calculateTextColor } from "../../colorUtils";
import { getColorFromVarName } from "../classRules";

export function ColumnMonthTimespread() {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignSelf: "flex-start",
        justifyContent: "flex-end",
        flexDirection: "column",
        backgroundColor: "var(--ag-header-background-color)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderArtisLogo />
        <Box
          sx={{
            width: "100%",
            fontWeight: "700",
            height: headerHeight,
            display: "flex",
            alignItems: "center",
            pl: agGridCellAutoPadding,
            backgroundColor: "var(--headerColumnColour)",
            color: calculateTextColor(
              getColorFromVarName("--headerColumnColour"),
              false,
            ),
          }}
        >
          Spread
        </Box>
      </Box>
    </Box>
  );
}

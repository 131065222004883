import { Box, Button, Chip, IconButton, Tooltip } from "@mui/joy";
import { type ReactNode, useState } from "react";
import {
  FaInfoCircle,
  FaLongArrowAltRight,
  FaMinus,
  FaPlus,
} from "react-icons/fa";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useAtomValue } from "jotai";

import { isSavingAtom } from "../hooks/useFloatingSave";
import { useThemeMode } from "../../context/theme";
import { useUserByUserId } from "../users";
import { useSideBar } from "../nav/hooks";

export function FloatingContainer({ children }: { children: ReactNode }) {
  const { sideBarWidth } = useSideBar();
  const mode = useThemeMode();
  const isSaving = useAtomValue(isSavingAtom);
  if (isSaving) {
    return null;
  }

  return (
    <Box
      sx={{
        zIndex: 1000,
        width: "fit-content",
        background: (theme) => {
          if (mode === "dark") {
            return theme.palette.neutral[700];
          }
          return theme.palette.neutral[200];
        },
        p: 1,
        borderRadius: 6,
        position: "fixed",
        left: `calc(50% + ${sideBarWidth / 2}px)`,
        transform: "translateX(-50%)",
        bottom: "1rem",
      }}
    >
      {children}
    </Box>
  );
}

export function UndoSaving({ cancelSaveFn }: { cancelSaveFn: () => void }) {
  return (
    <Button
      type="button"
      color="neutral"
      onClick={(e) => {
        e.preventDefault();
        cancelSaveFn();
      }}
    >
      Undo...
    </Button>
  );
}

export function ManageEdits({
  children,
  isWaiting,
  editsCount,
}: {
  isWaiting: boolean;
  children: ReactNode;
  editsCount?: number;
}) {
  const [openEdits, setOpenEdits] = useState(false);

  return (
    <>
      {openEdits && (
        <ClickAwayListener
          onClickAway={() => {
            return setOpenEdits(false);
          }}
        >
          <Box
            data-testid="manage-edits"
            sx={{
              width: "fit-content",
              boxShadow: (theme) => theme.shadow.md,
              color: (theme) => theme.palette.text.primary,
              background: (theme) => theme.palette.background.level2,
              border: (theme) => `1px solid ${theme.palette.divider}`,
              maxHeight: "80vh",
              overflow: "auto",
              position: "fixed",
              left: "50%",
              borderRadius: 6,
              transform: "translateX(-50%)",
              bottom: "4rem",
            }}
          >
            {children}
          </Box>
        </ClickAwayListener>
      )}
      <Button
        onClick={() => setOpenEdits(!openEdits)}
        type="button"
        color="neutral"
        disabled={isWaiting}
      >
        {editsCount ? `${editsCount} Pending Edits` : "Pending Edits"}
      </Button>
    </>
  );
}

type TEdit =
  | {
      key: string;
      prevValue: string;
      newValue: string;
    }
  | {
      key: string;
      removedValues: string[];
      addedValues: string[];
    };

export function CurrentEdits({
  primaryKey,
  onCancel,
  onSave,
  edits,
  label,
  updatedBy,
  updatedAt,
}: {
  primaryKey: string | number;
  label?: string;
  onCancel: () => void;
  onSave: () => void;
  edits: TEdit[];
  updatedBy?: string | null;
  updatedAt?: Date;
}) {
  const userData = useUserByUserId(updatedBy ?? "");
  const user = userData?.user;

  return (
    <Box
      key={primaryKey}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        pb: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          whiteSpace: "nowrap",
          p: 1,
          gap: 10,
          background: (theme) => theme.palette.background.surface,
        }}
      >
        <Box>
          <Box>{label ?? primaryKey}</Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Tooltip
            title={`Edited by ${updatedBy}: ${user?.firstname} ${
              user?.lastname
            } (${user?.email} - ${
              user?.username
            }) at ${updatedAt?.toLocaleString()}`}
            placement="top"
            variant="solid"
            arrow
          >
            <IconButton variant="outlined">
              <FaInfoCircle />
            </IconButton>
          </Tooltip>
          <Button variant="outlined" color="warning" onClick={onCancel}>
            Delete
          </Button>
          <Button variant="outlined" color="success" onClick={onSave}>
            Save
          </Button>
        </Box>
      </Box>
      {edits.map((edit) => {
        const key = edit.key;
        return (
          <Box
            key={key}
            sx={{
              px: 1,
              display: "flex",
              fontFamily: "monospace",
              width: "100%",
              overflow: "auto",
              gap: 1,
              alignItems: "center",
              fontSize: 14,
            }}
          >
            {"removedValues" in edit ? (
              <>
                <Box>{key}:</Box>
                {edit.removedValues.length ? (
                  <Chip
                    variant="outlined"
                    color="danger"
                    startDecorator={<FaMinus />}
                  >
                    {edit.removedValues.join(", ")}
                  </Chip>
                ) : null}
                {edit.addedValues.length ? (
                  <Chip
                    variant="outlined"
                    color="success"
                    startDecorator={<FaPlus />}
                  >
                    {edit.addedValues.join(", ")}
                  </Chip>
                ) : null}
              </>
            ) : (
              <>
                <Box>{key}:</Box>
                <Box sx={{ flexShrink: 0 }}>{edit.prevValue}</Box>
                <FaLongArrowAltRight style={{ flexShrink: 0 }} />

                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    flexShrink: 0,
                  }}
                >
                  {edit.newValue}
                </Box>
              </>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

import { Typography } from "@mui/joy";

export function StatusModalTitle({ label }: { label: string }) {
  return (
    <Typography
      fontWeight="bold"
      fontSize="sm"
      sx={{
        px: 2,
        py: 1,
        textTransform: "capitalize",
        background: (theme) =>
          theme.palette.mode === "dark"
            ? theme.palette.neutral[700]
            : theme.palette.neutral[200],
      }}
    >
      {label}
    </Typography>
  );
}

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { atom, useAtom, useAtomValue, useStore } from "jotai";
import { useMemo, useEffect } from "react";

import {
  client,
  reportSyncError,
  type TriplitEntity,
} from "../../triplit/triplit";
import { initGridSettings } from "../market-grid/defaultSettings";
import { calcWorker } from "../calculations-worker/hooks";
import { useSelectAtomValue } from "../../utils/jotai";
import { gridSettingsWidth } from "../globals";
import { useUserId } from "../../context/auth";
import { tryParse } from "../utils";

export const gridSettingsAtom = atom<TriplitEntity<"gridSettings">>({
  id: "",
  ...initGridSettings,
});
gridSettingsAtom.debugLabel = "gridSettingsAtom";

export const useGridSettings = () =>
  useAtomValue<TriplitEntity<"gridSettings">>(gridSettingsAtom);

export const useSelectGridSettings = <T,>(
  select: (x: TriplitEntity<"gridSettings">) => T,
) => useSelectAtomValue(gridSettingsAtom, select);

export const useUpdateGridSettings = () => {
  const qry = useQueryClient();
  return useMutation({
    networkMode: "always",
    mutationKey: ["gridSettings"],
    mutationFn: async (arg: {
      id: string;
      updaterFn: (page: TriplitEntity<"gridSettings">) => void;
    }) => client.update("gridSettings", arg.id, arg.updaterFn),
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["gridSettings"] });
      reportSyncError(trx?.txId, "gridSettings");
    },
  });
};

export const useGridSettingsStatusMap = () => {
  const statusMapStr = useSelectGridSettings((x) => x.statusMap);
  return useMemo(
    () => tryParse<Record<string, string>>(statusMapStr, {}),
    [statusMapStr],
  );
};

export const useGridSettingsIncrementMap = () => {
  const incrementMapStr = useSelectGridSettings((x) => x.incrementMap);
  return useMemo(
    () => tryParse<Record<string, number>>(incrementMapStr, {}),
    [incrementMapStr],
  );
};

export const eodDateAtom = atom<string | null>(null);
eodDateAtom.debugLabel = "eodDateAtom";
export const useGridSettingsEodDate = () => useAtomValue(eodDateAtom);

export const gridSettingsVisibleAtom = atom<boolean>(false);
gridSettingsVisibleAtom.debugLabel = "gridSettingsVisibleAtom";
export const useGridSettingsVisible = () => useAtom(gridSettingsVisibleAtom);

export const gridSettingsWidthAtom = atom((get) =>
  get(gridSettingsVisibleAtom) ? gridSettingsWidth : 0,
);
gridSettingsWidthAtom.debugLabel = "gridSettingsWidthAtom";
export const useGridSettingsWidth = () => useAtomValue(gridSettingsWidthAtom);

export const SyncGridSettings = () => {
  const eodDate = useSelectGridSettings((x) => x.eodDate);
  const userId = useUserId();
  const store = useStore();

  useEffect(() => {
    if (!userId) return;
    client.fetchById("gridSettings", userId).then((data) =>
      store.set(gridSettingsAtom, {
        ...store.get(gridSettingsAtom),
        ...data,
      }),
    );

    const query = client.query("gridSettings").id(userId).build();
    return client.subscribe(
      query,
      (data) =>
        store.set(gridSettingsAtom, {
          ...store.get(gridSettingsAtom),
          ...data[0],
        }),
      console.error,
    );
  }, [store, userId]);

  useEffect(() => {
    const getPriorEodDate = calcWorker?.()?.proxy?.getPriorEodDate;
    if (eodDate !== "prior" || !getPriorEodDate) {
      store.set(eodDateAtom, eodDate ?? null);
      return;
    }
    getPriorEodDate().then((date) => store.set(eodDateAtom, date));
  }, [store, eodDate]);

  return null;
};

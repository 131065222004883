import { Box, Option, Radio, RadioGroup, Select } from "@mui/joy";
import dayjs from "dayjs";
import {
  genLiveUpdatesTimeKey,
  liveUpdatesTimeAtom,
  liveUpdatesTimeStore,
  type TLiveChart,
  useChartSettings,
  usePanelResize,
} from "./hooks";
import { type TSample, bottomBarHeight, sampleByQuestOptions } from "./utils";
import { Provider, useAtomValue } from "jotai";

const smallScreenBreakpoint = 360;

function SampleTimeSelector({ chartMetadata }: { chartMetadata: TLiveChart }) {
  const { sampleTime } = chartMetadata;
  const { panelWidth } = usePanelResize(chartMetadata.id);
  const [settings, setSettings] = useChartSettings(chartMetadata.id);

  function sampleOnChange(sample: TSample) {
    if (!settings) return;
    setSettings({ ...settings, sampleTime: sample });
  }

  const selectStyle = {
    background: "transparent",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      background: "transparent",
    },
    display: panelWidth
      ? panelWidth <= smallScreenBreakpoint
        ? "inherit"
        : "none"
      : "none",
  };

  const radioGroupDisplayStyle = {
    display: panelWidth
      ? panelWidth > smallScreenBreakpoint
        ? "inherit"
        : "none"
      : "none",
  };
  return (
    <Box
      sx={{
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Select
        size="sm"
        sx={selectStyle}
        value={sampleTime}
        onChange={(_e, newValue) => {
          if (!newValue) return;
          sampleOnChange(newValue);
        }}
      >
        {sampleByQuestOptions.map(({ label, value }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>
      <RadioGroup
        size="sm"
        orientation="horizontal"
        value={sampleTime}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value as typeof sampleTime;
          if (!newValue) return;
          sampleOnChange(newValue);
        }}
        sx={{
          height: "100%",
          borderRadius: 0,
          "--RadioGroup-gap": "0px",
          "--Radio-actionRadius": "0px",
          ...radioGroupDisplayStyle,
        }}
      >
        {sampleByQuestOptions.map(({ label, value }) => (
          <Radio
            key={value}
            value={value}
            disableIcon
            label={label}
            variant="plain"
            sx={{
              alignItems: "center",
              textAlign: "center",
              width: 35,
            }}
            slotProps={{
              action: ({ checked }) => ({
                sx: {
                  "&:hover": {
                    boxShadow: "none",
                  },
                  ...(checked && {
                    boxShadow: "none",
                  }),
                },
              }),
            }}
          />
        ))}
      </RadioGroup>
    </Box>
  );
}

function CurrentTimestamp({ chartMetadata }: { chartMetadata: TLiveChart }) {
  const { id: chartId, productId, sampleTime } = chartMetadata;

  if (!chartId || !productId || !sampleTime)
    throw new Error("chartId, productId, or sampleTime is missing");

  const id = genLiveUpdatesTimeKey({
    chartId,
    productId,
    sampleTime,
  });
  const liveUpdates = useAtomValue(liveUpdatesTimeAtom);

  const time = liveUpdates[id];

  return (
    <>
      {time && (
        <Box
          sx={{
            background: "var(--livePriceBg)",
            color: "white",
            height: "100%",
            px: 0.5,
            display: "flex",
            alignItems: "center",
            fontSize: "0.8em",
            whiteSpace: "nowrap",
            fontFamily: "monospace",
          }}
        >
          {dayjs(time).utc().format("HH:mm:ss")} UTC
        </Box>
      )}
    </>
  );
}

export function TradingBottomPanel({
  chartMetadata,
}: {
  chartMetadata: TLiveChart;
}) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
        height: bottomBarHeight,
        backgroundColor: (theme) => theme.palette.background.artis,
      }}
    >
      <SampleTimeSelector chartMetadata={chartMetadata} />
      <Provider store={liveUpdatesTimeStore}>
        <CurrentTimestamp chartMetadata={chartMetadata} />
      </Provider>
    </Box>
  );
}

import { concat, pipe } from "remeda";
import { useProductOptions } from "../../components/ProductSelect";
import { useGlobalProductOptions } from "../../curve-management";

export function useChartProducts() {
  const products =
    useProductOptions({
      disableListPermission: true,
    }) || [];
  const { data } = useGlobalProductOptions();
  const globalProducts = data || [];
  const allProducts = pipe(products, concat(globalProducts));
  const brtProducts = allProducts.filter(
    (p) => p.label === "BRT FT (CME BRENT CRUDE FUT): NYMEX",
  );
  console.log("brtProducts", brtProducts);
  return allProducts;
}

import { useApolloClient } from "@apollo/client";
import { useQuery } from "@tanstack/react-query";

import { graphql } from "../graphql";

export const useSources = () => {
  const apolloClient = useApolloClient();
  return useQuery({
    queryKey: ["sources"],
    queryFn: async () => {
      const res = await apolloClient.query({
        query: sourcesQuery,
        fetchPolicy: "no-cache",
      });
      return res.data.source;
    },
  });
};

const sourcesQuery = graphql(`
  query Sources {
    source {
      id
      name
    }
  }
`);

import {
  type CollectionNameFromModels,
  TriplitClient,
  type ClientOptions,
  type Entity,
} from "@triplit/client";

import { schema, type TSchema } from "./schema";
import { triplitServerUrl } from "../globals";

export const triplitStorage = import.meta.env.DEV ? "memory" : "indexeddb";

const clientOpts = {
  schema,
  claimsPath: "triplitClaims",
  serverUrl: triplitServerUrl(),
  storage: triplitStorage,
  experimental: {
    entityCache: {
      capacity: 10000,
    },
  },
} satisfies ClientOptions<typeof schema>;

export const client = new TriplitClient(clientOpts);

export type TriplitEntity<K extends keyof TSchema> = Entity<TSchema, K>;

export const reportSyncError = (
  trxId: string | undefined,
  collection: CollectionNameFromModels<TSchema>,
  action?: string,
) => {
  if (!trxId) return;
  client.onTxFailureRemote(trxId, () =>
    console.error(
      `Failed commit action: ${action ?? ""} for collection ${collection}`,
    ),
  );
};

import {
  addPeriodToSelection,
  fieldNameToLabel,
  selector,
} from "./contextMenuHelpers";
import type { TColumnHeaderProps } from "./components";
import { insert } from "../utils";
import type {
  GridApi,
  IMenuActionParams,
  ProcessDataFromClipboardParams,
} from "ag-grid-community";
import { parse } from "../numbers";
import type { TPageProduct } from "../../triplit/schema";

export function insertLinesIntoClipboardWithPeriodHeader({
  insertPos,
  lines,
}: {
  insertPos: number;
  lines: string[];
}) {
  const clipboardText = navigator.clipboard.readText();
  clipboardText.then((text) => {
    const newText = lines.reduceRight((acc, line) => {
      return insert(acc, insertPos, line);
    }, text.split("\n"));
    const periodHeader = newText[lines.length];
    const updatedText = [
      ...newText.slice(0, lines.length),
      periodHeader,
      ...newText.slice(lines.length + 1),
    ];
    navigator.clipboard.writeText(updatedText.join("\n"));
  });
}

export function copyWithHeadersAndMonths(params: IMenuActionParams) {
  const api = params.api;
  if (api) {
    const rangeUpdateMap = addPeriodToSelection({ api });
    api.clearRangeSelection();
    api.addCellRange(rangeUpdateMap);
    api.copySelectedRangeToClipboard({ includeHeaders: true });
  }
}

export function copyRangeToClipboard(api: GridApi) {
  api.copySelectedRangeToClipboard({
    includeHeaders: false,
    includeGroupHeaders: false,
  });
}

export function copyWithMetadata({
  params,
  products,
}: {
  params: IMenuActionParams;
  products: TPageProduct[];
}) {
  const api = params.api;

  if (!api) throw new Error("No grid api provided");

  const rangeUpdateMap = addPeriodToSelection({ api });

  const existingColumns = api
    .getCellRanges()
    ?.flatMap((cellRange) => cellRange.columns)
    .filter((column) => column?.getColDef()?.field !== "period");

  const headerComponents = existingColumns?.map(
    (column) => column?.getColDef()?.headerComponentParams,
  ) as TColumnHeaderProps[];

  const columnLabels =
    headerComponents?.map((headerComponent) => headerComponent?.columnLabels) ||
    [];

  const artisType: string[] =
    headerComponents
      ?.map((headerComponent) => headerComponent?.artisType)
      .filter(Boolean) || [];

  const gridId: string[] =
    headerComponents?.map((headerComponent) => headerComponent?.gridId) || [];

  const uoms: string[] =
    headerComponents
      ?.map((headerComponent) => headerComponent?.columnLabels?.uom)
      .filter(Boolean) || [];

  const selectorFn = selector({ products });

  const fields = gridId
    ?.map((gridId, index) => {
      if (artisType?.length) {
        return selectorFn({ gridId, artisType: artisType[index] });
      }
    })
    .filter(Boolean);

  const fieldLabels = fields?.map((field) => fieldNameToLabel(field));

  const attrToStr = (attr: string[]) => {
    return `\t${attr.join("\t")}`;
  };

  api.clearRangeSelection();
  api.addCellRange(rangeUpdateMap);
  api.copySelectedRangeToClipboard({ includeHeaders: true });

  const descriptions =
    columnLabels
      ?.map((columnLabel) => columnLabel?.commodityParentGroup)
      .filter(Boolean) || [];
  const productIds =
    headerComponents
      ?.map((headerComponent) => headerComponent?.productId)
      .filter(Boolean) || [];
  const packages =
    columnLabels?.map((columnLabel) => columnLabel?.package).filter(Boolean) ||
    [];

  const lines = [
    attrToStr(descriptions),
    attrToStr(productIds),
    attrToStr(packages),
    attrToStr(fieldLabels),
    attrToStr(uoms),
  ];

  insertLinesIntoClipboardWithPeriodHeader({ insertPos: 1, lines });
}

function processParentheticalExplanations(
  col: (string | null | undefined)[][],
) {
  return col.map((values) =>
    values.map((value) => {
      if (value?.match(/.*\(.*\)/)) {
        return `-${value.match(/[\d\.]+/g)?.join("")}`;
      }
      return value?.match(/[-\d\.]+/g)?.join("");
    }),
  );
}

function pastedValueStringToFloat(col: (string | null | undefined)[][]) {
  return col.map((values) =>
    values.map((value) => parse(value)?.toString() || ""),
  );
}

function removeTrailingEmptyStrings(col: string[][]): string[][] {
  return col
    .map((subArray) => {
      const reversedSubArray = [...subArray].reverse();
      const reducedSubArray = reversedSubArray.reduce((acc, value) => {
        acc.unshift(value.trim());
        return acc;
      }, [] as string[]);

      return reducedSubArray;
    })
    .filter((row) => row.length > 0);
}

export function processDataFromClipboard(
  params: ProcessDataFromClipboardParams,
): string[][] {
  const data = params.data;

  const processedParentheticalExplanations =
    processParentheticalExplanations(data);
  const floatValue = pastedValueStringToFloat(
    processedParentheticalExplanations,
  );
  const cleanedValues = removeTrailingEmptyStrings(floatValue);

  return cleanedValues;
}

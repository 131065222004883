import ReactDOM from "react-dom";
import { CssBaseline, Stack, Typography } from "@mui/joy";
import type { ClientMessage } from "../calculations-worker/workerToClientLayer";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import { Box } from "@mui/system";
import { Link, Outlet } from "@tanstack/react-router";
import { memo, useEffect, useState, type ReactNode } from "react";
import { useWebappTheme, webappTheme } from "../../styles/extendTheme";
import {
  CreateCurvesIcon,
  EditCurvesIcon,
  InfoIcon,
  ManagePagesIcon,
  MarketIcon,
  SignOutIcon,
} from "../icons";
import { useCurrentUser, useSetupCalcWorker } from "./hooks";
import {
  CollapsibleSettings,
  useGridSettingsWidth,
  SideBarButton,
  SyncGridSettings,
} from "../grid-settings";
import {
  sideBarWidth,
  topBarHeight,
  bottomBarHeight,
  sideBarZIndex,
  triplitStatusBarZIndex,
  gridSettingsTransitionMatch,
} from "../globals";
import { CustomTooltip } from "../components/Tooltip";
import { ToasterContainer } from "../components/Toaster";
import { store, logoutRequestAtom } from "../sharedHooks";
import { isMobile } from "../../shared/hooks";
import Loading from "../components/Loading";
import {
  hasuraProductInfoAtom,
  pageProductsByPageIdAtom,
  updatePageProducts,
  useRefreshTimeout,
  useSubscribePageProducts,
  useUserSubscriptionTier,
  useUserSubscriptionTierByUserId,
} from "../market-pages";
import { ModalWrapper } from "../components/Modal";
import { client } from "../../triplit/triplit";
import { getAccessTokenAtom, useUserId } from "../../context/auth";
import { isDev } from "../../globals";
import { useConnectionStatus } from "@triplit/react";
import { useDynamicCssClassRules } from "../market-grid/useDynamicCssClassRules";
import {
  useConditionalFormattingRules,
  usePageFormatting,
} from "../market-grid/modals/formatCellHelpers";
import { calcWorker, useSessionState } from "../calculations-worker/hooks";
import { useAuthToken } from "../../context/auth";
import { SessionView } from "../sessions/sessions";
import { useSetAtom } from "jotai";

import { GridModalWrapper } from "../market-grid/modals/GridModal";
import { useSingleUserById } from "../../admin/users/hooks";
import { testUserIdsDev, testUserIdsStaging } from "../../testUtils";
import { useNewFeatures, usePricing } from "../../sanity/queries";
import { DocumentationContextMenu } from "../infoSection/InfoSectionMenu";
import { AnnouncementModal } from "../infoSection/Announcements";
import { PricingModal } from "../infoSection/Pricing";
import { ContactUsModal } from "../infoSection/ContactUs";
import {
  useViewedAnnouncements,
  insertViewedAnnouncement,
  hasUnviewedAnnouncements,
} from "../infoSection/hooks";
import type { TViewedAnnouncement } from "../../triplit/schema";
import type {
  NewFeature,
  Pricing,
} from "../../../src/__generated__/sanity/graphql-request";
import { KeyboardShortcutsModal } from "../infoSection/KeyboardShortcuts";
import { ThemeModeProvider, useThemeMode } from "../../context/theme";
import { useShortcuts } from "../market-grid/keyboardShortcuts";
import {
  useOptionalActivePageId,
  usePageProductsSuspended,
  useAllProductsSuspended,
  useSyncPages,
} from "../../data";
import { LicenceAgreement } from "../licensing";

function Sidebar() {
  const theme = useWebappTheme();
  const { user } = useCurrentUser();
  const { disabledFeatures } = useUserSubscriptionTier();

  const username = user?.username;
  const email = user?.email;
  const userId = user?.id || "";

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<NewFeature | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPricingOpen, setIsPricingOpen] = useState(false);
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isKeyboardShortcutsOpen, setIsKeyboardShortcutsOpen] = useState(false);
  const { results: viewedAnnouncements } = useViewedAnnouncements(userId);
  const viewedAnnouncementsMap =
    viewedAnnouncements instanceof Map
      ? viewedAnnouncements
      : new Map(viewedAnnouncements.map((item) => [item.id, item]));

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopperOpen(!isPopperOpen);
  };

  const handleClose = () => {
    setIsPopperOpen(false);
    setIsPopupOpen(false);
  };

  const { data } = useNewFeatures();
  const newFeatures = data?.allNewFeature || [];

  const { data: pricingResult } = usePricing();
  const pricingData = pricingResult?.allPricing || [];

  const pricingTable = pricingData.find(
    (item: Pricing) => item.title === "Pricing Table",
  );

  const handleAnnouncementClick = async (announcement: NewFeature) => {
    if (!userId) {
      console.error("User ID is undefined, cannot record viewed announcement.");
      return;
    }
    if (!announcement || !announcement._id || !announcement.title) {
      console.error("Invalid announcement or missing _id");
      return;
    }

    try {
      await insertViewedAnnouncement(userId, {
        announcementId: announcement._id,
        id: announcement._id,
        title: announcement.title,
        viewedAt: new Date(),
        userId: userId,
      });
      setSelectedAnnouncement(announcement);
      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error recording viewed announcement:", error);
    }
  };
  const unviewedAnnouncements = hasUnviewedAnnouncements(
    newFeatures,
    viewedAnnouncementsMap,
  );
  const popperContent = (
    <Box
      sx={{
        position: "absolute",
        top: anchorEl
          ? anchorEl.getBoundingClientRect().bottom + window.scrollY
          : 0,
        left: anchorEl
          ? anchorEl.getBoundingClientRect().left + window.scrollX
          : 0,
        transform: "translateX(27%) translateY(-90%)",
        p: 1.5,
        bgcolor: "background.surface",
        borderRadius: "sm",
        width: 160,
        boxShadow: "lg",
        zIndex: 100,
      }}
      onClick={handleClose}
    >
      <DocumentationContextMenu
        announcements={newFeatures}
        onAnnouncementClick={(announcement) => {
          handleAnnouncementClick(announcement);
        }}
        onHelpClick={() => console.log("Help clicked")}
        onKeyboardShortcutsClick={() => setIsKeyboardShortcutsOpen(true)}
        onContactUsClick={() => setIsContactUsOpen(true)}
        onPricingClick={() => setIsPricingOpen(true)}
        viewedAnnouncements={
          viewedAnnouncementsMap instanceof Map
            ? viewedAnnouncementsMap
            : new Map<string, TViewedAnnouncement>()
        }
        theme={theme}
      />
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        position: "fixed",
        zIndex: sideBarZIndex,
        backgroundColor: theme.palette.background.sideBar,
        width: sideBarWidth,
        borderRight: `1px solid ${theme.palette.divider}`,
        display: {
          xs: "none",
          md: "flex",
        },
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack sx={{ gap: 2 }}>
        <CustomTooltip title="Market">
          <Link to="/app/market">
            <MarketIcon />
          </Link>
        </CustomTooltip>
        <CustomTooltip
          title={
            disabledFeatures?.managePages
              ? "Please upgrade your subscription to access this feature"
              : "Manage Pages"
          }
        >
          <Box
            onClick={() => {
              window.open(
                `${import.meta.env.VITE_OLD_WEBAPP_URL}/manage-pages`,
                "_blank",
                "location=yes,scrollbars=yes,status=yes",
              );
            }}
          >
            <ManagePagesIcon disabled={disabledFeatures?.managePages} />
          </Box>
        </CustomTooltip>
        <CustomTooltip
          title={
            disabledFeatures?.createCurve
              ? "Please upgrade your subscription to access this feature"
              : "Create Curves"
          }
        >
          <Box
            onClick={() => {
              window.open(
                `${import.meta.env.VITE_OLD_WEBAPP_URL}/create-curve`,
                "_blank",
                "location=yes,scrollbars=yes,status=yes",
              );
            }}
          >
            <CreateCurvesIcon disabled={disabledFeatures?.createCurve} />
          </Box>
        </CustomTooltip>
        <CustomTooltip
          title={
            disabledFeatures?.editCurve
              ? "Please upgrade your subscription to access this feature"
              : "Edit Curves"
          }
        >
          <Box
            onClick={() => {
              window.open(
                `${import.meta.env.VITE_OLD_WEBAPP_URL}/edit-curve`,
                "_blank",
                "location=yes,scrollbars=yes,status=yes",
              );
            }}
          >
            <EditCurvesIcon disabled={disabledFeatures?.editCurve} />
          </Box>
        </CustomTooltip>
      </Stack>
      <Stack sx={{ gap: 2 }}>
        <CustomTooltip title={email}>
          <Typography
            id="nav-username"
            fontSize={12}
            textAlign={"center"}
            color="neutral"
            textTransform="uppercase"
          >
            {username}
          </Typography>
        </CustomTooltip>
        <SideBarButton />
        <CustomTooltip title="Info">
          <Box onClick={handleOpen}>
            <InfoIcon
              unviewedAnnouncements={unviewedAnnouncements}
              theme={theme}
            />
          </Box>
        </CustomTooltip>
        <CustomTooltip title="Log Out">
          <SignOutIcon />
        </CustomTooltip>
      </Stack>
      {isPopperOpen &&
        anchorEl &&
        ReactDOM.createPortal(
          <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
            <Box>{popperContent}</Box>
          </ClickAwayListener>,
          document.body,
        )}
      {selectedAnnouncement && (
        <AnnouncementModal
          open={isPopupOpen}
          setClose={() => setIsPopupOpen(false)}
          announcement={selectedAnnouncement}
          theme={theme}
        />
      )}
      {isKeyboardShortcutsOpen && (
        <KeyboardShortcutsModal
          open={isKeyboardShortcutsOpen}
          setClose={() => setIsKeyboardShortcutsOpen(false)}
          theme={theme}
        />
      )}
      {isContactUsOpen && (
        <ContactUsModal
          open={isContactUsOpen}
          setClose={() => setIsContactUsOpen(false)}
          theme={theme}
        />
      )}
      {isPricingOpen && (
        <PricingModal
          open={isPricingOpen}
          setClose={() => setIsPricingOpen(false)}
          theme={theme}
          pricingData={pricingTable}
          email={email}
        />
      )}{" "}
    </Box>
  );
}

export function TopNavigation({ children }: { children: ReactNode }) {
  const theme = useWebappTheme();
  const left = useGridSettingsWidth();

  return (
    <Stack
      sx={{
        height: `${topBarHeight}px`,
        left,
        transition: gridSettingsTransitionMatch,
        width: "100%",
        backgroundColor: theme.palette.background.topBar,
        borderBottom: `1px solid ${theme.palette.divider}`,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        top: 0,
        color: theme.palette.text.primary,
      }}
    >
      {children}
    </Stack>
  );
}

export function BottomBar({ children }: { children: ReactNode }) {
  const theme = useWebappTheme();
  const left = useGridSettingsWidth();
  const isStandalone = (navigator as Navigator & { standalone?: boolean })
    .standalone;
  return (
    <Stack
      sx={{
        height: isStandalone
          ? `calc(${bottomBarHeight}px + env(safe-area-inset-bottom))`
          : `${bottomBarHeight}px`,
        width: {
          sm: "100%",
          md: `calc(100% - ${sideBarWidth}px)`,
        },
        backgroundColor: theme.palette.background.bottomBar,
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        bottom: 0,
        paddingBottom: "env(safe-area-inset-bottom)",
        transition: gridSettingsTransitionMatch,
        left: {
          sm: 0,
          md: `${sideBarWidth + left}px`,
        },
      }}
    >
      {children}
    </Stack>
  );
}

const broadcastChannel = new BroadcastChannel("worker->clients");
broadcastChannel.addEventListener(
  "message",
  async (event: MessageEvent<ClientMessage>) => {
    console.log("Worker message", event.data.type);
    if (event.data.type === "logoutRequest") {
      store.set(logoutRequestAtom, true);
    }
    if (event.data.type === "renewToken") {
      const getAccessTokenSilently = store.get(getAccessTokenAtom).getToken;
      if (!getAccessTokenSilently) return;

      // Wait 5 seconds. This is because when we update the permission in Hasura, the Auth0 token is not updated immediately, so fetching it right away will return the old token.
      await new Promise((resolve) => setTimeout(resolve, 5000));

      const token = await getAccessTokenSilently({
        cacheMode: "off",
      });

      console.log("Renewing token", token);
      calcWorker?.()?.proxy?.updateJWT(token);
    }
  },
);

export function Layout() {
  const { session, isLoading: tokenLoading } = useAuthToken();
  const existsOtherSession = useSessionState();
  const shouldCheckSession = existsOtherSession.existingSession;
  const currentSession = session?.auth0Session;
  const deviceType = isMobile ? "mobile" : "desktop";
  const userId = useUserId();
  const hasuraUser = useSingleUserById(shouldCheckSession ? userId : "");
  const hasuraSession = hasuraUser?.sessions.find(
    (s) => s.id !== currentSession?.id && s.is_mobile === isMobile,
  );
  const sessionType = hasuraSession?.is_mobile ? "mobile" : "desktop";
  const lastSeenStr = hasuraSession?.last_seen
    ? new Date(hasuraSession.last_seen).toLocaleString()
    : null;

  if (
    shouldCheckSession &&
    lastSeenStr &&
    ![...testUserIdsDev, ...testUserIdsStaging].includes(userId)
  ) {
    return (
      <SessionView
        currentSession={currentSession}
        userId={userId}
        deviceType={deviceType}
        sessionType={sessionType}
        lastSeenStr={lastSeenStr}
        session={hasuraSession}
      />
    );
  }
  return <LayoutMemo isLoading={tokenLoading} />;
}

const LayoutMemo = memo(LayoutInner);

function LoadingWrapper({
  workerLoading,
  syncing,
}: {
  workerLoading: boolean;
  syncing: boolean;
}) {
  const mode = useThemeMode();
  return (
    <Loading
      showLogo
      workerLoading={workerLoading}
      syncing={syncing}
      mode={mode}
    />
  );
}

function TriplitStatusBar() {
  const connectionStatus = useConnectionStatus(client);
  if (!isDev || connectionStatus === "OPEN") return null;
  return (
    <Box
      sx={(theme) => ({
        position: "fixed",
        top: 0,
        right: 0,
        height: topBarHeight,
        zIndex: triplitStatusBarZIndex,
        backgroundColor:
          connectionStatus === "CLOSED"
            ? theme.palette.danger[500]
            : theme.palette.warning[500],
        color: "white",
        padding: 1,
        textAlign: "center",
      })}
    >
      {connectionStatus === "CLOSED" && "Server connection offline"}
      {connectionStatus === "CONNECTING" && "Connecting to server..."}
    </Box>
  );
}

const useSyncTriplit = () => {
  const pageId = useOptionalActivePageId();
  const pageProducts = usePageProductsSuspended(pageId);
  const productsInfo = useAllProductsSuspended();

  const setPageProducts = useSetAtom(pageProductsByPageIdAtom);
  const setHasuraProducts = useSetAtom(hasuraProductInfoAtom);

  useEffect(() => {
    updatePageProducts(pageProducts.data, setPageProducts);
  }, [pageProducts.data, setPageProducts]);

  useEffect(() => {
    setHasuraProducts(productsInfo.data ?? []);
  }, [productsInfo.data, setHasuraProducts]);
};

function LayoutInner({ isLoading }: { isLoading: boolean }) {
  const loaded = !isLoading;

  return (
    <JoyCssVarsProvider
      modeStorageKey="webapp"
      theme={webappTheme}
      defaultMode="light"
    >
      <ThemeModeProvider section="webappTheme">
        <RegisterListeners />
        <SyncGridSettings />
        <CssBaseline enableColorScheme />
        <ToasterContainer />

        <Sidebar />
        <TriplitStatusBar />

        {isMobile && <GridModalWrapper />}

        {!loaded && <LoadingWrapper workerLoading={false} syncing={false} />}
        <LicenceAgreement />
        <Box
          className="webapp"
          sx={{
            width: {
              xs: "100%",
              md: `calc(100% - ${sideBarWidth}px)`,
            },
            backgroundColor: webappTheme.palette.common,
            position: "relative",
            left: {
              xs: 0,
              md: sideBarWidth,
            },
          }}
        >
          <CollapsibleSettings />
          <Outlet />
        </Box>

        <ModalWrapper />
      </ThemeModeProvider>
    </JoyCssVarsProvider>
  );
}

const RegisterListeners = () => {
  const { session } = useAuthToken();
  const pageId = useOptionalActivePageId() ?? "";
  const userId = useUserId();

  useShortcuts();
  useSyncPages();
  useSyncTriplit();
  useRefreshTimeout({});
  useSubscribePageProducts();
  useSetupCalcWorker(session);
  useDynamicCssClassRules();
  usePageFormatting(pageId);
  useConditionalFormattingRules(pageId);
  useUserSubscriptionTierByUserId(userId);

  return null;
};

import { useDeepCompareMemo } from "@react-hookz/web";
import type { OhlcData, Time } from "lightweight-charts";
import { isNullish } from "remeda";

export function useMergeHistoricalAndLiveData(
  historicalData: Partial<OhlcData>[] | undefined,
  currentCandle: OhlcData | undefined | null,
  currentCandleCache: OhlcData | undefined | null,
) {
  const data = useDeepCompareMemo(() => {
    if (historicalData == null) return [];

    if (currentCandleCache != null) {
      const existing = historicalData.find(
        (d) => d.time === currentCandleCache.time,
      );
      if (existing == null) {
        historicalData.push(currentCandleCache);
      }
    }

    const latest = historicalData[historicalData.length - 1];

    const uniqueDataMap = new Map<number, OhlcData>();

    if (
      currentCandle != null &&
      historicalData.length > 0 &&
      currentCandle.time === latest.time
    ) {
      const merged = mergeHistoricalAndLiveData(latest, currentCandle);
      if (merged == null) {
        historicalData.forEach((candle) => {
          if (candle != null && candle.time != null) {
            uniqueDataMap.set(Number(candle.time), candle as OhlcData);
          }
        });
        return Array.from(uniqueDataMap.values()).sort(
          (a, b) => Number(a.time) - Number(b.time),
        );
      }
      historicalData.slice(0, -1).forEach((candle) => {
        if (candle != null && candle.time != null) {
          uniqueDataMap.set(Number(candle.time), candle as OhlcData);
        }
      });
      uniqueDataMap.set(Number(merged.time), merged as OhlcData);
    } else {
      historicalData.forEach((candle) => {
        if (candle != null && candle.time != null) {
          uniqueDataMap.set(Number(candle.time), candle as OhlcData);
        }
      });
      if (currentCandle && currentCandle.time != null) {
        uniqueDataMap.set(Number(currentCandle.time), currentCandle);
      }
    }

    const uniqueData = Array.from(uniqueDataMap.values()).sort(
      (a, b) => Number(a.time) - Number(b.time),
    );

    return uniqueData;
  }, [historicalData, currentCandle]);

  return data as OhlcData[];
}

export function mergeHistoricalAndLiveData(
  latest: Partial<OhlcData<Time>> | undefined,
  currentCandle: Partial<OhlcData<Time>> | undefined | null,
) {
  if (isNullish(currentCandle) || isNullish(currentCandle.close))
    return latest ?? null;
  if (isNullish(latest)) return currentCandle ?? null;

  const merged = {
    time: currentCandle.time,
    open: isNullish(latest.open) ? currentCandle.open : latest.open,
    close: currentCandle.close,
    high:
      !isNullish(latest.high) && !isNullish(currentCandle.high)
        ? Math.max(latest.high, currentCandle.high)
        : !isNullish(latest.high)
          ? latest.high
          : currentCandle.high,
    low:
      !isNullish(latest.low) && !isNullish(currentCandle.low)
        ? Math.min(latest.low, currentCandle.low)
        : !isNullish(latest.low)
          ? latest.low
          : currentCandle.low,
  };
  return merged;
}

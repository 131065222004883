import type { GridApi } from "ag-grid-community";
import { useCallback } from "react";

import { client } from "../../triplit/triplit";
import { useGrid } from "../../webapp/market-grid/stores";

export function resetApiFilters({
  stateTableId,
  api,
}: {
  stateTableId?: string;
  api: GridApi;
}) {
  if (stateTableId) {
    client.delete("tableState", stateTableId);
    client.delete("tableColumnState", stateTableId);
  }

  for (const col of api.getColumns() || []) {
    api.setColumnFilterModel(col, null);
  }

  api.setFilterModel(null);
  api.resetQuickFilter();

  api.setGridOption("quickFilterText", "");
  api.onFilterChanged();

  api.setAdvancedFilterModel(null);
  api.deselectAll();
}

export function useResetUserFilters() {
  const [gridApi] = useGrid();

  const resetUserFilters = useCallback(() => {
    if (!!gridApi && !gridApi.isDestroyed()) {
      resetApiFilters({ api: gridApi });
    }
  }, [gridApi]);

  return { resetUserFilters };
}

import { Box, Link, Stack, Table } from "@mui/joy";
import { ModalButtons } from "../Buttons";
import { GridModalContainer } from "../GridModal";
import type { TAlert } from "../../../../triplit/schema";
import type { GridApi } from "ag-grid-community";
import {
  type TAlertForm,
  alertValidation,
  requestNotificationPermission,
  useAlerts,
} from "./hooks";
import {
  relativeRowToRowId,
  rowIdToRelativeRow,
  rowStringToCode,
} from "../../periodHelpers";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getNodePeriod,
  headerStyle,
  useResetLimitSelection,
} from "../ConditionalFormatting/helpers";
import { client } from "../../../../triplit/triplit";
import { parse } from "../../../numbers";
import toast from "react-hot-toast";
import { AlertHeader, AlertRow } from "./CreateAlert";
import { gridModalDockviewAtom } from "../../../calculations-worker/sharedStores";
import { GridModalSelectionInfo } from "../modalComponents";
import { currentPageProductsAtom } from "../../../market-pages/pageProductHooks";
import { useGrid } from "../../stores";
import { useActivePageId } from "../../../../data";

function alertChanged(existing: TAlert, alert: TAlert) {
  return (
    existing.limit !== alert.limit ||
    existing.note !== alert.note ||
    existing.sound !== alert.sound
  );
}

type TAlertCell = {
  value: ReturnType<GridApi["getCellValue"]>;
  period: string | number | undefined;
  rowId: string | undefined;
  productId: string;
  name: string;
};

function formatAlerts(
  gridApi: GridApi | null,
  alerts: ReturnType<typeof useAlerts>["alerts"] | null,
  filter: "all" | "range",
  cells: TAlertCell[],
) {
  if (!gridApi || !alerts) return [];

  const alertValues = Object.values(alerts).flat();

  const formatted = alertValues
    ?.map((alert) => {
      if (filter === "range") {
        if (
          !cells.find(
            (cell) =>
              cell.productId === alert.productId && cell.rowId === alert.rowId,
          )
        ) {
          return;
        }
      }

      const parts = alert.limit.includes(":")
        ? alert.limit.split(":")
        : undefined;

      const limitRef = parts
        ? {
            columnId: parts[0],
            productId: parts[1],
            fieldSelector: parts[2],
            rowId: parts[3],
            name: cells.find((cell) => cell.productId === parts[0])?.name || "",
            period: getNodePeriod(
              gridApi?.getRowNode(
                rowStringToCode(relativeRowToRowId[parts[1]]),
              ),
            ),
          }
        : undefined;

      return {
        id: alert.id,
        limit: alert.limit.includes(":") ? "" : alert.limit,
        limitRef,
        note: alert.note,
        valueBelowLimit: alert.valueBelowLimit,
        recurring: alert.recurring ?? false,
        sound: alert?.sound ?? false,
        status: alert.status,
        productId: alert.productId,
        columnId: alert.columnId,
        rowId: alert.rowId,
      } satisfies TAlertForm;
    })
    .filter(Boolean);

  return formatted;
}

export function AlertsManager({
  alerts,
}: {
  alerts: ReturnType<typeof useAlerts>["alerts"];
}) {
  const pageId = useActivePageId();
  const [gridApi] = useGrid();
  const resetLimitSelection = useResetLimitSelection();

  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.alerts?.selectedRange || [];

  const [alertsToShow, setAlertsToShow] = useState<"all" | "range">("range");

  const cells =
    rangeSelection
      .flatMap((range) => {
        return range.map((cell) => {
          const rowNode = gridApi?.getRowNode(cell.rowId);

          if (!rowNode) return;

          const value = gridApi?.getCellValue({
            rowNode,
            colKey: cell.columnId,
          });
          const period = getNodePeriod(rowNode);
          const rowId = period ? rowIdToRelativeRow[period] : rowNode?.id;

          if (rowId && cell.productId) {
            return {
              productId: cell.productId,
              columnId: cell.columnId,
              name:
                gridApi?.getColumn(cell.columnId)?.getColDef()?.headerName ||
                "",
              rowId,
              period,
              value,
            };
          }
        });
      })
      .filter(Boolean) || [];

  const form = useForm<{
    alerts: TAlertForm[];
  }>({
    defaultValues: {
      alerts: formatAlerts(gridApi, alerts, alertsToShow, cells),
    },
    resolver: zodResolver(alertValidation),
  });

  const { fields, remove } = useFieldArray({
    control: form.control,
    name: "alerts",
  });

  const formData = useWatch({
    control: form.control,
    name: "alerts",
  });

  const auth = useAuth0();
  const userId = auth.user?.sub;

  function filterAlertsBy(filter: "all" | "range") {
    setAlertsToShow(filter);
    form.reset({
      alerts: formatAlerts(gridApi, alerts, filter, cells),
    });
  }

  const currentPageProducts = useAtomValue(currentPageProductsAtom);

  async function handleSave() {
    try {
      const permission = await requestNotificationPermission();
      if (!permission) {
        toast.error("Notification permission denied", { duration: 1000 });
      }
      resetLimitSelection();

      if (!pageId) throw new Error("pageId is not defined");
      if (!userId) throw new Error("userId is not defined");
      if (!gridApi) throw new Error("gridApi is not defined");
      if (!cells?.length) throw new Error("cells is not defined");

      const query = client.query("alerts").where("pageId", "=", pageId).build();
      const existingAlertsResponse = await client.fetch(query);
      const allExistingAlerts = existingAlertsResponse || [];

      const cellIds = cells.map((cell) => `${cell.productId}-${cell.rowId}`);

      // Filter existing rules to only include ones in cellIds.
      const existingAlerts =
        alertsToShow === "all"
          ? allExistingAlerts
          : allExistingAlerts.filter((alert) =>
              cellIds.includes(`${alert.productId}-${alert.rowId}`),
            );

      // Delete all alerts that are not in formData.
      if (!formData?.length) {
        const deletes = existingAlerts.map((alert) => {
          return client.delete("alerts", alert.id);
        });

        await Promise.all(deletes);
      }

      const formDataIds = formData.map((data) => data.id);

      // Check if any alerts have been removed (not in "formDataIds" but in existingAlerts).
      const alertsToDelete = existingAlerts
        .filter((alert) => {
          const alertId = alert.id;
          return !formDataIds.includes(alertId);
        })
        .map((alert) => alert.id);

      // Sort the existing alerts according to the order of "fields", and filter out the ones that are to be deleted.
      const sortedExistingAlerts = formDataIds
        .map((id) => existingAlerts.find((alert) => alert.id === id))
        .filter(Boolean)
        .filter((alert) => !alertsToDelete.includes(alert.id));

      // Update sortedExistingAlerts with data in "formData".
      const updatedAlerts = sortedExistingAlerts
        .map((existing) => {
          if (
            existing.status !== "Active" ||
            (alertsToShow === "range" &&
              !cellIds.includes(`${existing.productId}-${existing.rowId}`))
          )
            return;

          const alert = formData.find((data) => data.id === existing.id);

          if (alert) {
            const cell = cells.find(
              (cell) =>
                cell.productId === alert.productId &&
                cell.rowId === alert.rowId,
            );

            const limit = alert?.limitRef
              ? `${alert.limitRef.productId}:${alert.limitRef.rowId}`
              : parse(alert.limit);

            if (!limit || !cell) return;

            const limitRefRowNode = alert?.limitRef
              ? gridApi.getRowNode(rowStringToCode(alert.limitRef?.period))
              : undefined;

            const limitRefCellValue =
              limitRefRowNode && alert.limitRef?.productId
                ? gridApi.getCellValue({
                    rowNode: limitRefRowNode,
                    colKey: alert.limitRef?.productId,
                  })
                : undefined;

            const product = currentPageProducts.find(
              (product) => product.productId === alert.productId,
            );

            if (!product) return;

            const fieldSelector =
              alert.limitRef?.fieldSelector || product.columnFieldSelector;

            const updated = {
              id: existing.id,
              limit: limit.toString(),
              note: alert.note,
              valueBelowLimit: limitRefCellValue
                ? cell.value < limitRefCellValue
                : cell.value < limit,
              status: alert.status,
              recurring: alert.recurring,
              sound: alert.sound,
              triggeredAt: existing.triggeredAt,
              productId: existing.productId,
              columnId: alert.columnId,
              rowId: existing.rowId || "",
              userId,
              pageId,
              fieldSelector,
            } satisfies TAlert;

            if (alertChanged(existing, updated)) {
              return updated;
            }
          }
        })
        .filter(Boolean);

      const deletes = alertsToDelete.map((id) => {
        return client.delete("alerts", id);
      });

      const updates = updatedAlerts
        .map((alert) => {
          return client.update("alerts", alert.id, (existing) => {
            existing.limit = alert.limit;
            existing.valueBelowLimit = alert.valueBelowLimit;
            existing.note = alert.note;
            existing.sound = alert.sound;
          });
        })
        .filter(Boolean);

      toast.promise(Promise.all([...deletes, ...updates]), {
        loading: "Saving alert(s)...",
        success: "Alert(s) saved",
        error: (error) => {
          if (error instanceof Error) {
            return error.message;
          }

          return "Failed to save alert(s)";
        },
      });
    } catch (e) {
      console.error("Failed to save alerts", e);
    }
  }

  requestNotificationPermission();

  return (
    <GridModalContainer
      panel="alerts"
      body={
        <>
          <GridModalSelectionInfo selectedRange={rangeSelection} />
          <form onSubmit={form.handleSubmit(handleSave)}>
            <Box display="flex" gap={1} alignItems={"center"} mt={1}>
              <Link
                onClick={() => {
                  filterAlertsBy("all");
                }}
                fontSize="sm"
                sx={(theme) => ({
                  color:
                    alertsToShow === "all"
                      ? "var(--artis-orange)"
                      : theme.palette.neutral[400],
                  textDecoration:
                    alertsToShow === "range" ? "underline" : "none",
                  cursor: "pointer",
                  display: "inline",
                  "&:hover": {
                    color: "var(--artis-orange)",
                    textDecoration:
                      alertsToShow === "range" ? "underline" : "none",
                  },
                })}
              >
                All
              </Link>
              |
              <Link
                onClick={() => {
                  filterAlertsBy("range");
                }}
                fontSize="sm"
                sx={(theme) => ({
                  color:
                    alertsToShow === "range"
                      ? "var(--artis-orange)"
                      : theme.palette.neutral[400],
                  textDecoration: alertsToShow === "all" ? "underline" : "none",
                  cursor: "pointer",
                  display: "inline",
                  "&:hover": {
                    color: "var(--artis-orange)",
                    textDecoration:
                      alertsToShow === "all" ? "underline" : "none",
                  },
                })}
              >
                Range
              </Link>
            </Box>
            <Box pt={2}>
              <Stack gap={2} mb={4}>
                <Table
                  sx={(theme) => ({
                    tableLayout: "fixed",
                    minWidth: 400,
                    td: {
                      minWidth: 40,
                      textAlign: "center",
                    },
                    "tr:first-of-type td": {
                      paddingTop: 1.5,
                    },
                    th: headerStyle(theme),
                    borderSpacing: "6px 4px",
                  })}
                  noWrap
                  borderAxis="none"
                >
                  <AlertHeader type="manage" />
                  <tbody>
                    {fields.map((alert, index) => (
                      <AlertRow
                        key={alert.id}
                        form={form}
                        alert={alert}
                        index={index}
                        remove={remove}
                        type="manage"
                      />
                    ))}
                  </tbody>
                </Table>
              </Stack>
            </Box>
          </form>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel={"alerts"}
          onCancel={() => {
            resetLimitSelection();
          }}
          onSave={() => handleSave()}
        />
      }
    />
  );
}

export function ManageAlerts() {
  const { alerts, fetching } = useAlerts();

  if (fetching) return "Loading...";

  return <AlertsManager alerts={alerts} />;
}

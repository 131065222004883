import { useAuth0 } from "@auth0/auth0-react";
import { graphql } from "../../__generated__/gql";
import { readFragment } from "../../graphql";
import { parseRoles } from "../../utils/users";
import { useEffect, useMemo, useRef } from "react";
import { calcWorker } from "../calculations-worker/hooks";
import type { TSession } from "../../context/auth";
import { isMobile } from "../../shared/hooks";
import { useQuery } from "@apollo/client";
import { useState } from "react";

export const CurrentUserFragment = graphql(`
  fragment CurrentUserFragment on folio_user {
    __typename
    id
    username
    email
    roles
    subscription_tier
  }
`);

const currentUserQuery = graphql(`
  query CurrentUser($id: String!) {
    folio_user_by_pk(id: $id) {
      ...CurrentUserFragment
    }
  }
`);

function useUser(id: string) {
  return useQuery(currentUserQuery, {
    skip: !id,
    variables: { id },
  });
}

export function useCurrentUser() {
  const { user: authUser } = useAuth0();
  const id = authUser?.sub || "";
  const res = useUser(id);
  const user = readFragment(CurrentUserFragment, res.data?.folio_user_by_pk);
  const roles = user?.roles ? parseRoles(user.roles) : undefined;
  const username = user?.username;
  return { user, roles, username, ...res };
}

export function useUserHasRole(role: string) {
  const { roles: userRoles } = useCurrentUser();
  return Boolean(userRoles?.has(role));
}

export function useSetupCalcWorker(session: TSession) {
  const rawWorker = calcWorker?.();
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const worker = useMemo(() => rawWorker, []);

  const { user: authuser } = useAuth0();

  const { username } = useCurrentUser();
  const user = useMemo(
    () => ({
      id: authuser?.sub,
      username: username,
      firstname: authuser?.given_name,
      lastname: authuser?.family_name,
    }),
    [authuser, username],
  );

  const workerInited = useRef(false);
  const workerStarted = useRef(false);
  const portTransferred = useRef(false);

  useEffect(() => {
    async function start() {
      if (worker && user && !workerInited.current) {
        console.log("starting worker", {
          worker,
          user,
          session,
          workerStarted,
        });
        if (session) {
          const token = session.jwt;
          if (worker.worker instanceof Worker) {
            worker.worker.postMessage({ type: "wakeup" });
          } else {
            worker.worker.port.postMessage({ type: "wakeup" });
          }
          try {
            console.log("trying initWorker");
            await worker.proxy
              .initWorker(import.meta.env, user, session, isMobile)
              .then(() => {
                console.log("worker inited");
              });
          } catch (e) {
            console.error("failed to init worker", e);
          }
          console.log(
            "worker1 inited",
            workerInited.current,
            workerStarted.current,
            !!token,
          );
          workerInited.current = true;
          if (!workerStarted.current && token) {
            console.log("starting worker1");
            await worker.proxy.start(token);
            workerStarted.current = true;
          } else {
            console.log("worker not started", {
              workerInited: workerInited.current,
              workerStarted: workerStarted.current,
            });
          }
        } else {
          console.error("session is null");
        }
      }
    }

    if (!session) {
      console.debug("session is null");
      return;
    }

    if (worker && user) {
      start().catch((e) => {
        console.error("failed to start worker", e);
      });
    } else {
      console.error("user not ready", user);
    }
  }, [user, worker, session]);
}

const SLOW_THRESHOLD = 5000;

export function useConnectionStatus(lastUpdatedAt: Date | null) {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [isSlowConnection, setIsSlowConnection] = useState(false);

  useEffect(() => {
    const handleOffline = () => {
      setIsOffline(true);
      setIsSlowConnection(false);
      console.log("Connection went offline");
    };

    const handleOnline = () => {
      setIsOffline(false);
      console.log("Connection came online");
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  useEffect(() => {
    if (isOffline) return;

    const intervalId = setInterval(() => {
      if (!lastUpdatedAt) {
        setIsSlowConnection(false);
        return;
      }

      const timeSinceLastUpdate = Date.now() - lastUpdatedAt.getTime();
      if (timeSinceLastUpdate > SLOW_THRESHOLD) {
        setIsSlowConnection(true);
        console.log("Connection is slow");
      } else {
        setIsSlowConnection(false);
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [isOffline, lastUpdatedAt]);

  return { isOffline, isSlowConnection };
}

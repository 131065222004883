import { Box, IconButton, Sheet, Stack, Typography, Button } from "@mui/joy";
import type { TAlert } from "../../../../triplit/schema";
import { FaBell, FaTimes } from "react-icons/fa";
import { getAlertDetails, getProductName } from "./hooks";
import { client } from "../../../../triplit/triplit";
import toast from "react-hot-toast";
import { alertSounds } from "../../../globals";
import { store } from "../../../sharedHooks";
import { gridSettingsAtom } from "../../../grid-settings";
import type { TApolloClient } from "../../../../context/apollo";
import { useState } from "react";
import { nanoid } from "nanoid";
import type { useApi } from "../../../../admin/hooks/useApi";
import { vapidKeys } from "../../../../globals";

function AlertToast({
  alert,
  productName,
  limitName,
}: {
  alert: TAlert;
  productName: string;
  limitName?: string | null;
}) {
  const { formattedMessage } = getAlertDetails(alert, productName, limitName);
  const [isHidden, setIsHidden] = useState(false);
  const duplicateAlert: TAlert = {
    id: nanoid(),
    columnId: alert.columnId,
    rowId: alert.rowId,
    limit: alert.limit,
    note: alert.note,
    recurring: alert.recurring,
    sound: alert.sound,
    valueBelowLimit: alert.valueBelowLimit,
    status: "Active",
    triggeredAt: null,
    productId: alert.productId,
    userId: alert.userId,
    pageId: alert.pageId,
  };

  return (
    <Sheet
      key={alert.id}
      slotProps={{
        root: {
          id: alert.id,
        },
      }}
      sx={(theme) => ({
        display: isHidden ? "none" : "flex",
        pointerEvents: "all",
        p: 3,
        pl: 4,
        boxShadow: "0 0 10px gray",
        border: theme.palette.neutral,
        borderRadius: "md",
      })}
    >
      <Stack gap={2}>
        <Stack direction="row" justifyContent={"space-between"} gap={6}>
          <Stack direction="row" alignItems="center" gap={1}>
            <FaBell color="var(--artis-orange)" size={20} />
            <Typography
              level="h3"
              fontWeight="normal"
              sx={{ color: "var(--artis-orange)" }}
            >
              {alert.note || "Alert"} triggered
            </Typography>
          </Stack>
          <Box>
            <IconButton
              onClick={() => {
                setIsHidden(true);
                toast.dismiss(alert.id);
                client
                  .delete("alerts", alert.id)
                  .then(() => {
                    console.log("Alert dismissed", alert);
                  })
                  .catch((error) => {
                    console.error("Error dismissing alert", error);
                  });
                if (alert.recurring) {
                  client.insert("alerts", duplicateAlert);
                }
              }}
              size="sm"
              variant="plain"
            >
              <FaTimes />
            </IconButton>
          </Box>
        </Stack>
        <Typography
          sx={{
            textWrap: "nowrap",
          }}
        >
          {formattedMessage}
        </Typography>
        {!alert.recurring ? (
          <Button
            color="neutral"
            sx={{ width: "90px" }}
            onClick={() => {
              // because otherwise its a bit slow to go away
              setIsHidden(true);

              toast.dismiss(alert.id);
              client
                .delete("alerts", alert.id)
                .then(() => {
                  console.log("Alert dismissed", alert);
                })
                .catch((error) => {
                  console.error("Error dismissing alert", error);
                });
              client.insert("alerts", duplicateAlert);
            }}
          >
            Re-set alert
          </Button>
        ) : null}
      </Stack>
    </Sheet>
  );
}

export async function sendNotification(
  alert: TAlert,
  graphqlClient: TApolloClient,
  makeApiRequest: ReturnType<typeof useApi>,
) {
  const sound = store.get(gridSettingsAtom)?.sound;

  if ((sound || alert.sound) && !document.getElementById(alert.id)) {
    new Audio(alertSounds.huthut).play();
  }
  const productName =
    (await getProductName(alert.columnId, graphqlClient)) || "";
  const limitId = alert.limit.includes(":") ? alert.limit.split(":")[0] : null;
  const limitName = limitId
    ? await getProductName(limitId, graphqlClient)
    : null;

  console.log("Alert triggered", {
    alert,
    productName,
    limitName,
  });

  const alertExists = await client.fetchById("alerts", alert.id);

  if (
    alertExists &&
    (alertExists.status !== "Triggered" || !alertExists.triggeredAt)
  ) {
    client.update("alerts", alert.id, (row) => {
      row.status = "Triggered";
      row.triggeredAt = alert.triggeredAt || new Date();
    });
  }

  toast.custom(
    <AlertToast
      alert={alert}
      productName={productName || ""}
      limitName={limitName}
    />,
    {
      id: alert.id,
      position: "bottom-right",
      duration: Number.POSITIVE_INFINITY,
    },
  );

  if (!Notification) {
    console.warn("Desktop notifications not available.");
    return;
  }

  if (Notification.permission !== "granted") {
    console.warn("Notification permission not granted.");
    return;
  }

  const pushSubscriptions = await client.fetchById(
    "pushSubscriptions",
    alert.userId,
  );

  if (pushSubscriptions && !alert.pushNotificationSent) {
    const body = {
      publicKey: vapidKeys.publicKey,
      privateKey: vapidKeys.privateKey,
      subscriptions: pushSubscriptions.subscriptions,
      title: `${alert.note || "Alert"} triggered`,
      body: getAlertDetails(alert, productName, limitName).formattedMessage,
    };

    console.log("Attempting to send push notification...", body);

    await client.update("alerts", alert.id, (row) => {
      row.pushNotificationSent = true;
    });

    makeApiRequest("/api/send-notification", "POST", JSON.stringify(body));
  }

  if (!alert.desktopNotificationSent) {
    await client.update("alerts", alert.id, (row) => {
      row.desktopNotificationSent = true;
    });

    const notification = new Notification(
      `${alert.note || "Alert"} triggered`,
      {
        body: getAlertDetails(alert, productName, limitName).formattedMessage,
      },
    );

    notification.onclick = () => {
      window.focus();
      notification.close();
      client.update("alerts", alert.id, (row) => {
        row.status = "Triggered";
        row.triggeredAt = null;
      });
    };

    return notification;
  }
}

import { useNavigate, useSearch } from "@tanstack/react-router";
import { atom, useAtomValue, useStore } from "jotai";
import { useEffect } from "react";
import { z } from "zod";

export const environments = [
  { label: "DEV", value: "dev", initials: "DEV" },
  { label: "UAT", value: "uat", initials: "UAT" },
  { label: "PROD", value: "global-Prod", initials: "PROD" },
  { label: "TPICAP-PROD", value: "TPICAP-Prod", initials: "TP" },
] as const;

export type TEnv = (typeof environments)[number]["value"];

export const envSchema = z.enum([
  "dev",
  "uat",
  "global-Prod",
  "TPICAP-Prod",
] satisfies [TEnv, TEnv, TEnv, TEnv]);

const envAtom = atom("dev" as TEnv);

export const useGlobalEnv = () => useAtomValue(envAtom);

export const useEnvLabel = () => {
  const env = useAtomValue(envAtom);
  return environments.find((e) => e.value === env)?.label;
};

export function useUrlEnv() {
  const search = useSearch({ strict: false });
  const env = safeEnv(search.env);
  const navigate = useNavigate();
  const store = useStore();

  const setEnv = (env: TEnv) => {
    navigate({
      to: ".",
      search: { env },
      replace: false,
    });
  };

  useEffect(() => {
    if (env !== store.get(envAtom)) store.set(envAtom, env);
  }, [store, env]);

  return [env, setEnv] as const;
}

export function safeEnv(env: unknown): TEnv {
  const envString = env ?? "dev";
  const parseResult = envSchema.safeParse(envString);
  if (!parseResult.success) {
    console.error(parseResult.error);
  }
  return parseResult.success ? parseResult.data : "dev";
}

import type { GridApi } from "ag-grid-community";
import { atom, getDefaultStore, useAtom } from "jotai";
import { useEffect } from "react";
import type { TRangeCell } from "../tableUtils";
import { useNetworkState } from "@react-hookz/web";
import type { TData } from "./calculations-worker/sharedStores";

import type { TPageSettings } from "../triplit/schema";
import { calcWorker } from "./calculations-worker/hooks";

export const store = getDefaultStore();

export const logoutRequestAtom = atom(false);

export type TAddProductsModal = {
  modalId: "add-products";
  pageId: string;
};

export type TModalState = TAddProductsModal | null;

export type ModalIds = NonNullable<TModalState>["modalId"];

export const modalAtom = atom<TModalState>(null);

export function useModal() {
  return useAtom(modalAtom);
}

export const pageSettingsAtom = atom<TPageSettings[] | null>(null);
pageSettingsAtom.debugLabel = "pageSettingsAtom";

export type TRangeSelection = TRangeCell[][];
export const rangeSelectionAtom = atom<TRangeSelection>([]);
rangeSelectionAtom.debugLabel = "rangeSelectionAtom";

export function useRangeSelection() {
  return useAtom(rangeSelectionAtom);
}

export const pressedKeysAtom = atom(new Set<string>());
pressedKeysAtom.debugLabel = "pressedKeysAtom";

const eventsToRegister = new Set<string>(["Meta", "Control", "Alt"]);

export function usePressedKeys() {
  const [pressedKeys, setPressedKeys] = useAtom(pressedKeysAtom);

  const addRemoveKey = {
    add: (key: string) => {
      if (!eventsToRegister.has(key)) {
        return;
      }
      setPressedKeys((prev) => {
        const newSet = new Set(prev);
        newSet.add(key);
        return newSet;
      });
    },
    delete: (key: string) => {
      if (!eventsToRegister.has(key)) {
        return;
      }
      setPressedKeys((prev) => {
        const newSet = new Set(prev);
        newSet.delete(key);
        return newSet;
      });
    },
  };

  return [pressedKeys, addRemoveKey] as const;
}

export function refreshTargetCells(rowIds: string[], api?: GridApi<TData>) {
  if (!api || api.isDestroyed()) {
    return;
  }
  const rowNodes = rowIds.map((id) => api.getRowNode(id)).filter(Boolean);
  api.refreshCells({ force: true, rowNodes });
}
const root = document.documentElement;

export function useCssVar() {
  const callback = (name: string, value: string) => {
    root.style.setProperty(name, value);
  };
  return callback;
}

export function useNetworkStatusListener() {
  const networkStatus = useNetworkState();
  const worker = calcWorker?.().proxy;
  useEffect(() => {
    if (!worker) return;
    console.log("network status effect");
    worker.networkStatusChange(networkStatus);
  }, [worker, networkStatus]);
}

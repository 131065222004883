import type { AddComponentOptions, DockviewApi } from "dockview";
import { useCallback } from "react";

export function useChangeLayoutCharts() {
  const changeLayout = useCallback(
    (panels: number, dockview: DockviewApi | null) => {
      if (!dockview) throw new Error("No dockview api found");

      // Sometimes the dockview api is disposed
      try {
        dockview.clear();
      } catch (e) {
        console.error(e);
        return;
      }

      const firstPanel = dockview.addPanel({
        id: "0",
        component: "default",
      });

      firstPanel.group.header.hidden = true;

      for (let i = 1; i < panels; i++) {
        let position: AddComponentOptions["position"];
        if (i === 1) {
          position = {
            direction: "right",
            referencePanel: "0",
          };
        } else if (i === 2) {
          position = {
            direction: "below",
            referencePanel: "0",
          };
        } else if (i === 3) {
          position = {
            direction: "below",
            referencePanel: "1",
          };
        }

        const panel = dockview.addPanel({
          id: i.toString(),
          component: "default",
          position,
        });

        panel.group.header.hidden = true;
      }
    },
    [],
  );
  return changeLayout;
}

import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { datadogRum } from "@datadog/browser-rum";
import { Box } from "@mui/joy";
import { z } from "zod";

import { loadMarketPageData } from "../data";
import { Layout } from "./layout/Skeleton";
import { rootRoute } from "../baseRoutes";

export const rootWebappRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "app",
  component: Layout,
});

export const marketSearchSchema = z.object({
  columnSettingsModal: z
    .object({
      columnId: z.string().optional(),
      initialColumnWidth: z.number().optional(),
      initialThousandsSeparator: z.boolean().optional(),
      initialDecimalPlaces: z.number().optional(),
      increment: z.number().optional(),
    })
    .optional(),
});

export const userGuideSchema = z.object({
  id: z.string().optional(),
});

export const marketHomeRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "market",
  validateSearch: marketSearchSchema,
  onEnter: () => datadogRum.startView({ name: "Market Home" }),
  component: lazyRouteComponent(() => import("./MarketView"), "MarketHome"),
});

export const marketPageRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "market/$id",
  validateSearch: marketSearchSchema,
  onEnter: () => datadogRum.startView({ name: "Market Page" }),
  loader: (route) => loadMarketPageData(route.context, route.params.id),
  component: lazyRouteComponent(() => import("./MarketView"), "MarketPage"),
});

export const managePagesRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "manage-pages",
  onEnter: () => datadogRum.startView({ name: "Manage Pages" }),
  component: () => <Box> manage pages </Box>,
});

export const userGuideRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "user-guide",
  validateSearch: userGuideSchema,
  onEnter: () => datadogRum.startView({ name: "User Guide" }),
  component: lazyRouteComponent(() => import("./UserGuide"), "UserGuide"),
});

export const tradingChartRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "trading-chart",
  onEnter: () => datadogRum.startView({ name: "Trading Chart" }),
  component: lazyRouteComponent(
    () => import("./live-charts/ChartsLayout"),
    "ChartsLayout",
  ),
});

import { atomFamily, atomWithStorage } from "jotai/utils";
import { useMemo } from "react";
import { useAtom } from "jotai";

import type { ChartSettings } from "./types";

const chartSettingsFamily = atomFamily((chartId: string) =>
  atomWithStorage<ChartSettings | null>(`chart-settings-${chartId}`, null),
);

export const useChartSettings = (chartId: string) =>
  useAtom(useMemo(() => chartSettingsFamily(chartId), [chartId]));

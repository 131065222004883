import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

import { atom, useAtomValue } from "jotai";

import { SANITY_URL } from "../globals";

export const sanityClientAtom = atom(
  new ApolloClient<object>({
    link: createHttpLink({ uri: SANITY_URL }),
    cache: new InMemoryCache(),
  }),
);

export const useSanityClient = () => useAtomValue(sanityClientAtom);

import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Option, Select, Typography } from "@mui/joy";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  DockviewReact,
  type IDockviewReactProps,
  type IDockviewPanelProps,
} from "dockview";
import "dockview/dist/styles/dockview.css";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useRef } from "react";
import { topBarHeight } from "../globals";
import { TradingView, UnselectedTradingView } from "./LiveChart";
import {
  chartFetchingAtom,
  queryClient,
  useChangeLayoutCharts,
  useChartSettings,
} from "./hooks";
import "../../styles/liveCharts.css";
import { dockviewAtom, genChartId } from "./utils";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import { useThemeMode } from "../../context/theme";
import { liveChartsTheme } from "../../styles/extendTheme";
import { QueryClientProvider } from "@tanstack/react-query";
import { ThemeModeProvider, useSetThemeMode } from "../../context/theme";
import { useLayoutState, useProductArtisType } from "./hooks";
import { useUserId } from "../../context/auth";

function LayoutSelector() {
  const [layout, setLayout] = useLayoutState();

  return (
    <>
      <Select<number>
        size="sm"
        placeholder="Select Layout"
        value={layout?.pannels ?? 1}
        onChange={(_, v) => {
          if (!v) return;
          setLayout((x) => ({ ...x, pannels: v }));
        }}
      >
        <Option value={1}>1 Chart</Option>
        <Option value={2}>2 Charts</Option>
        <Option value={4}>4 Charts</Option>
      </Select>
    </>
  );
}

function ThemeSelector() {
  const setMode = useSetThemeMode();
  const mode = useThemeMode();

  return (
    <>
      <Select<"light" | "dark">
        size="sm"
        value={mode}
        onChange={async (_, v) => {
          if (!v) return;
          await setMode(v);
        }}
      >
        <Option value="light">Light</Option>
        <Option value="dark">Dark</Option>
      </Select>
    </>
  );
}

function TopBar({ close }: { close?: () => void }) {
  const isFetching = useAtomValue(chartFetchingAtom);
  return (
    <Box
      sx={{
        height: topBarHeight,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2,
        borderBottom: "1px solid",
        borderColor: "divider",
        backgroundColor: (theme) => theme.palette.background.surface,
      }}
    >
      <Typography>Live Charts</Typography>
      {isFetching && <Typography>Fetching Data...</Typography>}
      <Box sx={{ display: "flex", gap: 1 }}>
        <LayoutSelector />
        <ThemeSelector />
        {close && (
          <Button
            onClick={() => {
              close();
            }}
            sx={{
              display: {
                xs: "block",
                sm: "none",
              },
            }}
            color="neutral"
            variant="outlined"
          >
            Close
          </Button>
        )}
      </Box>
    </Box>
  );
}

function ThemeComponent(_props: IDockviewPanelProps) {
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) throw new Error("No userId in UnselectedTradingView");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      hello
    </Box>
  );
}

export function ChartComponent({ api }: IDockviewPanelProps) {
  const chartIdx = api.id;
  const userId = useUserId();
  const chartId = genChartId({ chartIdx, userId });

  const [settings] = useChartSettings(chartId);
  const artisType = useProductArtisType(settings?.productId);

  const metadata = useMemo(() => {
    if (!settings) return null;
    return { ...settings, artisType };
  }, [settings, artisType]);

  if (metadata?.productId && metadata?.sampleTime && metadata?.periodFrom) {
    return <TradingView chartMetadata={metadata} />;
  }

  return <UnselectedTradingView chartIdx={chartIdx} />;
}

const components = {
  default: ChartComponent,
  theme: ThemeComponent,
} satisfies IDockviewReactProps["components"];

export function ChartsLayoutInner({ close }: { close?: () => void }) {
  const [dockview, setDockview] = useAtom(dockviewAtom);
  const [layout, setLayout] = useLayoutState();
  const changeLayout = useChangeLayoutCharts();

  const mode = useThemeMode();

  useEffect(() => {
    if (!dockview) return;
    const disposable = dockview.onDidLayoutChange(() => {
      setLayout((x) => ({ ...x, state: dockview.toJSON() }));
    });
    return () => disposable.dispose();
  }, [dockview, setLayout]);

  useEffect(() => {
    if (!dockview) return;
    changeLayout(layout?.pannels || 1, dockview);
  }, [layout?.pannels, dockview, changeLayout]);

  // Only load layout once to avoid infinite loop caused by onDidLayoutChange
  const initialized = useRef(false);
  useEffect(() => {
    if (!dockview || !layout?.state || initialized.current) return;
    initialized.current = true;
    try {
      dockview.fromJSON(layout.state);
    } catch (e) {
      return;
    }
  }, [layout?.state, dockview]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <TopBar close={close} />
      <Box
        sx={{
          height: `calc(100% - ${topBarHeight}px)`,
        }}
      >
        <DockviewReact
          className={
            mode === "dark" ? "dockview-theme-dark" : "dockview-theme-light"
          }
          onWillDrop={() => setDockview(null)}
          onReady={(event) => setDockview(event.api)}
          components={components}
        />
      </Box>
    </Box>
  );
}

export function ChartsLayout() {
  // persistOptions={{
  //   buster: "v1.4",
  //   persister,
  //   maxAge: Number.POSITIVE_INFINITY,
  //   dehydrateOptions: {
  //     // do not persist queries that are not marked as persist i.e. live data
  //     shouldDehydrateQuery: (query) => !!query.meta?.persist,
  //   },
  return (
    <JoyCssVarsProvider
      disableNestedContext
      theme={liveChartsTheme}
      attribute="data-live-charts-color-scheme"
      defaultMode="light"
    >
      <ThemeModeProvider section="liveChartsTheme">
        <QueryClientProvider client={queryClient}>
          <div className="h-dvh">
            <ChartsLayoutInner />
          </div>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeModeProvider>
    </JoyCssVarsProvider>
  );
}

import type {
  TPendingOrganisation,
  TPendingPackage,
  TPendingUser,
} from "../../triplit/schema";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Box,
  Button,
  ListItemContent,
  Stack,
  Typography,
} from "@mui/joy";
import { FaBoxOpen, FaPencilAlt } from "react-icons/fa";
import Link from "../../utils/Link";
import { formatKebabCase } from "../../utils";
import { useOrgId } from "../orgs";
import { useThemeMode } from "../../context/theme";

export function PartiallyCreatedEntities({
  pendingItems,
  orgSpecific = true,
  valuesToShow,
  entityName = "Items",
  icon = <FaBoxOpen />,
  newItemUrl,
  onDelete,
}: {
  pendingItems?: (TPendingPackage | TPendingUser | TPendingOrganisation)[];
  orgSpecific?: boolean;
  valuesToShow?: Array<string>;
  entityName?: string;
  icon?: React.ReactNode;
  newItemUrl: string;
  onDelete: (id: string) => void;
}) {
  const orgId = useOrgId();

  const orgPendingItems = orgSpecific
    ? pendingItems?.filter((value) => {
        return value.orgId === orgId?.toString();
      })
    : pendingItems;

  const mode = useThemeMode();

  if (!orgPendingItems?.length) return null;

  return (
    <Box>
      <Box
        sx={{
          p: 1,
          pb: 0,
        }}
      >
        <AccordionGroup
          disableDivider
          sx={{
            background: (theme) => theme.palette.background.surface,
            borderRadius: 8,
            overflow: "hidden",
          }}
        >
          <Accordion>
            <AccordionSummary>
              <Stack>{icon}</Stack>
              <ListItemContent sx={{ py: 1 }}>
                <Typography level="title-md">
                  Partially Created {entityName}
                </Typography>
              </ListItemContent>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  gap: 1,
                  py: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {orgPendingItems.map((value) => {
                  const key = value.id;
                  return (
                    <Box key={key} sx={{ display: "flex", gap: 2 }}>
                      <Link
                        to={newItemUrl}
                        params={{ id: key, org: orgId?.toString() }}
                        sx={{
                          display: "flex",
                          gap: 1.5,
                          alignItems: "center",
                          background: (theme) =>
                            theme.palette.background.surface,
                          p: 1,
                          borderRadius: 8,
                          "&:hover": {
                            textDecoration: "none",
                            background: (theme) =>
                              theme.palette.background.popup,
                          },
                        }}
                      >
                        <FaPencilAlt
                          color={mode === "dark" ? "white" : "black"}
                        />
                        {Object.entries(value)
                          .sort((a, b) => {
                            if (!valuesToShow) return 0;
                            return (
                              valuesToShow.indexOf(a[0]) -
                              valuesToShow.indexOf(b[0])
                            );
                          })
                          .map(([property, value]) => {
                            if (
                              !valuesToShow?.includes(property) ||
                              value === undefined ||
                              value === null
                            )
                              return null;

                            return (
                              <Typography level="body-md" key={property} mr={1}>
                                {formatKebabCase(property)}: {value?.toString()}
                              </Typography>
                            );
                          })
                          .filter(Boolean)}
                      </Link>
                      <Button
                        size="sm"
                        variant="outlined"
                        color="danger"
                        onClick={() => onDelete(key)}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </Box>
    </Box>
  );
}

import posthog from "posthog-js";

type Info = Record<string, unknown>;

const captureEvent = (eventName: string, info: Info = {}) => {
  // Check if posthog is blocked or unavailable
  if (typeof posthog === "undefined" || !posthog.capture) {
    console.debug("Analytics tracking is disabled or blocked");
    return;
  }

  try {
    const eventData = {
      ...info,
    };
    posthog.capture(eventName, eventData);
  } catch (error) {
    console.error("Error capturing event:", error);
  }
};

export default captureEvent;

import { useCurrentUsername } from "../../context/auth";
import { LicenceAgreementForm } from "./licensing";
import { useLicenceAgreement } from "./hooks";

export const LicenceAgreement = () => {
  const { licenceAgreement, sourceId } = useLicenceAgreement();
  const username = useCurrentUsername();
  if (!licenceAgreement) return null;
  return (
    <LicenceAgreementForm
      agreementType={licenceAgreement}
      username={username}
      sourceId={sourceId}
    />
  );
};

import type {
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  IMenuActionParams,
  MenuItemDef,
} from "ag-grid-community";
import { library, icon } from "@fortawesome/fontawesome-svg-core";
import {
  faInputPipe,
  faTrash,
  faCog,
  faBell,
  faDiagramCells,
  faColumns3,
  faRuler,
  faPalette,
  faFunction,
  faCopy,
  faFileExport,
  faMemoCircleInfo,
  faLineHeight,
  faCheck,
  faKeyboard,
  faChevronRight,
  faChartCandlestick,
} from "@fortawesome/pro-solid-svg-icons";
import { faLineColumns } from "@fortawesome/pro-duotone-svg-icons";
import { unique } from "remeda";
import { client } from "../../triplit";
import { columnWidth } from "../globals";
import {
  type TSelectorExchange,
  type TSelectorMaturity,
  clearCellFormatting,
  exportToExcelWithMetadata,
  getFormatMenuItemName,
  getSelectors,
  insertShadowColumn,
  openFormattingDockview,
  createFormatMenuItem,
  selectorSubgroup,
  upsertQuickAccess,
  getQuickAccessName,
  plotLiveChart,
  insertCurveDuplicate,
} from "./contextMenuHelpers";
import type { useUserSubscriptionTier } from "../market-pages";
import { potentiallyInvisibleColumns } from "../market-grid/columnDefs";
import { gridSettingsVisibleAtom, gridSettingsAtom } from "../grid-settings";
import { requestDockviewModal } from "./modals/modalComponents";
import { modalAtom, pageSettingsAtom, store } from "../sharedHooks";
import {
  copyWithHeadersAndMonths,
  copyWithMetadata,
  copyRangeToClipboard,
} from "./copyRangeSelectionHelpers";
import { copyAsPng } from "./copyAsPng";
import { getCellIds } from "./modals/formatCellHelpers";
import { getProcessedSelectedCellsByRange } from "../../tableUtils";
import { captureEvent } from "../../context/ph";
import { colParams } from "../utils";
import { isMobile } from "../../shared/hooks";
import { toast } from "react-hot-toast";
import { quickAccessAtom } from "./keyboardShortcuts";
import type { TPageProduct } from "../../triplit/schema";

library.add([
  faInputPipe,
  faLineColumns,
  faDiagramCells,
  faTrash,
  faBell,
  faCog,
  faColumns3,
  faRuler,
  faPalette,
  faCopy,
  faFileExport,
  faMemoCircleInfo,
  faLineHeight,
  faCheck,
  faFunction,
  faKeyboard,
  faChevronRight,
  faChartCandlestick,
]);

type ContextMenuItems = MenuItemDef & {
  requirements?: Array<boolean>;
};

const icons = {
  field: icon({ prefix: "fas", iconName: "input-pipe" }),
  column: icon({ prefix: "fad", iconName: "line-columns" }),
  type: icon({ prefix: "fas", iconName: "diagram-cells" }),
  remove: icon({ prefix: "fas", iconName: "trash" }),
  settings: icon({ prefix: "fas", iconName: "cog" }),
  width: icon({ prefix: "fas", iconName: "columns-3" }),
  size: icon({ prefix: "fas", iconName: "ruler" }),
  format: icon({ prefix: "fas", iconName: "palette" }),
  alerts: icon({ prefix: "fas", iconName: "bell" }),
  copy: icon({ prefix: "fas", iconName: "copy" }),
  export: icon({ prefix: "fas", iconName: "file-export" }),
  details: icon({ prefix: "fas", iconName: "memo-circle-info" }),
  header: icon({ prefix: "fas", iconName: "line-height" }),
  check: icon({ prefix: "fas", iconName: "check" }),
  function: icon({ prefix: "fas", iconName: "function" }),
  keyboard: icon({ prefix: "fas", iconName: "keyboard" }),
  chevron: icon({ prefix: "fas", iconName: "chevron-right" }),
  chartCandlestick: icon({ prefix: "fas", iconName: "chart-candlestick" }),
};

const getIcon = (iconName: keyof typeof icons) => {
  return icons[iconName].html.join();
};

function getFieldMenuItems({
  subItemAction,
  productId,
  exchange,
  maturity,
}: {
  subItemAction: (subItem: string) => void;
  productId: string;
  exchange: TSelectorExchange;
  maturity: TSelectorMaturity<TSelectorExchange>;
}) {
  const selectorsIntoSubgroups = selectorSubgroup();
  const selectors = getSelectors({ exchange, maturity, productId });

  const groups = unique(Object.values(selectorsIntoSubgroups));

  const fieldMenuItems = groups
    .map((group) => ({
      name: group,
      showInHeader: false,
      subMenu:
        selectors
          .map((selector) => {
            const groupName = selectorsIntoSubgroups[selector.value];

            if (groupName === group) {
              return {
                name: selector.label,
                action: () => subItemAction(selector.value),
              };
            }
          })
          .filter(Boolean) || [],
    }))
    .filter((group) => group.subMenu.length > 0);

  return fieldMenuItems.length
    ? fieldMenuItems
    : [
        {
          name: "VALUE",
          showInHeader: false,
          subMenu: [
            {
              name: "VALUE",
              action: () => subItemAction("value"),
            },
          ],
        },
      ];
}

const formatSubMenu = [
  { item: "cell", type: "range" },
  { item: "column", type: "column" },
  { item: "period", type: "period" },
] satisfies Array<{
  item: "cell" | "column" | "period";
  type: "range" | "column" | "period";
}>;

export function getContextMenuItems({
  params,
  userId,
  isHeaderMenu,
  disabledFeatures,
  pageId,
  products,
}: {
  params: GetContextMenuItemsParams | GetMainMenuItemsParams;
  userId: string;
  isHeaderMenu: boolean;
  disabledFeatures: ReturnType<
    typeof useUserSubscriptionTier
  >["disabledFeatures"];
  pageId: string;
  products: TPageProduct[];
}): Array<MenuItemDef | string> {
  const api = params.api;
  const columnParams = {
    headerComponentParams: params.column?.getColDef().headerComponentParams,
    width: params.column?.getActualWidth(),
  };
  const pageSettings = store.get(pageSettingsAtom);
  const quickAccess = store.get(quickAccessAtom);

  const settings = pageSettings?.find((p) => p.id === pageId);

  const extraHeaderHeightEnabled = settings?.extraHeaderHeightEnabled || false;

  const headerParams = columnParams?.headerComponentParams;
  const artisType = headerParams?.artisType;
  const exchange = headerParams?.exchange;
  const maturity = headerParams?.maturity;
  const productId = headerParams?.productId;
  const columnId = headerParams?.gridId || "";
  const cannedOrSourced = ["canned", "sourced"].includes(artisType);
  const isProduct = Boolean(productId);
  const isShadowCurve = Boolean(columnId?.includes("shadow"));
  const isBlank = headerParams?.shadowType === "blank";
  const hasFormula = headerParams?.hasFormula ?? false;

  const gridSettingsForVisibleColumns = store.get(gridSettingsAtom);
  const selectedRange = getProcessedSelectedCellsByRange({ api });
  const rangeSelection = selectedRange[0] || [];
  function isRangeWithinSingleColumn(
    data: Array<{ columnId: string }>,
  ): boolean {
    if (data.length === 0) return false;

    const firstColumnId = rangeSelection[0].columnId;
    return data.every((item) => item.columnId === firstColumnId);
  }

  const setColumnWidthsToCurrent = async () => {
    if (!columnId) return;

    const currentWidth = columnParams?.width || columnWidth;

    const changes =
      api
        ?.getColumns()
        ?.map((column) => {
          const col = colParams(column);
          const gridId = col?.gridId;
          if (!gridId || !col?.artisType) return null;
          return client.update("pageProducts", gridId, (current) => {
            current.columnWidth = currentWidth;
          });
        })
        .filter(Boolean) || [];

    await Promise.all(changes);
  };

  const removeColumn = (e: IMenuActionParams) => {
    const id = colParams(e.column)?.gridId;
    if (!id) {
      console.error("No column ID found", e);
      return;
    }
    client.delete("pageProducts", id);
  };

  const headerMenuItem = isHeaderMenu || isMobile;

  const items: Array<ContextMenuItems | string> = [
    {
      name: "Grid Settings",
      requirements: [isMobile],
      icon: getIcon("settings"),
      action: () => store.set(gridSettingsVisibleAtom, true),
    },
    {
      disabled: disabledFeatures?.curveDetails,
      name: "Curve Details",
      tooltip: disabledFeatures?.curveDetails
        ? "Please upgrade your subscription to access this feature"
        : undefined,
      requirements: [headerMenuItem, isProduct],
      icon: getIcon("details"),
      subMenu: potentiallyInvisibleColumns.map(
        ({ name, label, defaultVisible }) => {
          const selected =
            gridSettingsForVisibleColumns?.[name] ?? defaultVisible;
          return {
            name: label,
            icon: selected ? getIcon("check") : "",
            action: () => {
              const newValue = !selected;
              client.update("gridSettings", userId, (x) => {
                x[name] = newValue;
              });
            },
          };
        },
      ),
    },
    {
      disabled: disabledFeatures?.headerSize,
      name: "Header Size",
      tooltip: disabledFeatures?.headerSize
        ? "Please upgrade your subscription to access this feature"
        : undefined,
      requirements: [headerMenuItem, isProduct],
      icon: getIcon("header"),
      subMenu: [
        {
          name: "Compact",
          icon: !extraHeaderHeightEnabled ? getIcon("check") : "",
          action: () => {
            client.update("pages", pageId, (old) => {
              old.extraHeaderHeightEnabled = false;
            });
          },
        },
        {
          name: "Full",
          icon: extraHeaderHeightEnabled ? getIcon("check") : "",
          action: () => {
            client.update("pages", pageId, (old) => {
              old.extraHeaderHeightEnabled = true;
            });
          },
        },
      ],
    },
    ...(isHeaderMenu && isProduct ? ["separator"] : []),
    {
      name: "Field",
      requirements: [isProduct],
      icon: getIcon("field"),
      subMenu: getFieldMenuItems({
        subItemAction: (subItem) => {
          if (columnId) {
            client.update("pageProducts", columnId, (current) => {
              current.columnFieldSelector = subItem;
            });
            if (cannedOrSourced) {
              captureEvent("Field change");
            }
          }
        },
        exchange,
        maturity,
        productId,
      }),
    },
    {
      name: "Column Type",
      requirements: [isShadowCurve],
      icon: getIcon("type"),
      subMenu: [
        {
          name: "Month",
          action: () =>
            client.update("pageProducts", columnId, (current) => {
              current.columnType = "month";
            }),
        },
        {
          name: "Time Spread",
          action: () =>
            client.update("pageProducts", columnId, (current) => {
              current.columnType = "month-timespread";
            }),
        },
        {
          name: "Blank",
          action: () =>
            client.update("pageProducts", columnId, (current) => {
              current.columnType = "blank";
            }),
        },
      ],
    },
    {
      name: "Insert Column",
      icon: getIcon("column"),
      subMenu: [
        {
          name: "Curve",
          disabled: disabledFeatures?.insertCurve,
          tooltip: disabledFeatures?.insertCurve
            ? "Please upgrade your subscription to access this feature"
            : undefined,
          action: () => {
            store.set(modalAtom, { modalId: "add-products", pageId });
          },
        },
        {
          name: "Duplicate",
          disabled:
            disabledFeatures?.insertCurve ||
            !isRangeWithinSingleColumn(rangeSelection),
          tooltip: disabledFeatures?.insertCurve
            ? "Please upgrade your subscription to access this feature"
            : undefined,
          action: (params) =>
            insertCurveDuplicate({
              params,
              userId,
              pageId,
              currentPageProducts: products,
            }),
        },

        "separator",
        {
          name: "Month",
          action: (params) =>
            insertShadowColumn({
              params,
              userId,
              pageId,
              currentPageProducts: products,
              type: "month",
            }),
        },
        {
          name: "Time Spread",
          action: (params) =>
            insertShadowColumn({
              params,
              userId,
              pageId,
              currentPageProducts: products,
              type: "month-timespread",
            }),
        },
        {
          name: "Blank",
          action: (params) =>
            insertShadowColumn({
              params,
              userId,
              pageId,
              currentPageProducts: products,
              type: "blank",
            }),
        },
      ],
    },
    {
      name: "Show Formula",
      disabled: disabledFeatures?.showFormula,
      requirements: [hasFormula],
      icon: getIcon("function"),
      action: () =>
        requestDockviewModal({
          parentPanel: "formula-viewer",
          params: {
            height: 350,
            width: 600,
            selectedRange,
          },
        }),
    },
    {
      name: "Remove Column",
      requirements: [isShadowCurve],
      icon: getIcon("remove"),
      action: removeColumn,
    },
    {
      name: "Remove Curve",
      requirements: [isProduct],
      disabled: disabledFeatures?.removeCurve,
      tooltip: disabledFeatures?.removeCurve
        ? "Please upgrade your subscription to access this feature"
        : undefined,
      icon: getIcon("remove"),
      action: removeColumn,
    },
    {
      name: "Column Settings",
      requirements: [isProduct],
      icon: getIcon("settings"),
      action: () => {
        openFormattingDockview({
          pageId,
          type: "column",
          columnId,
          currentTab: "settings",
          selectedRange,
          productId,
        });
      },
    },
    {
      name: "Set All Column Widths",
      icon: getIcon("width"),
      action: () => {
        requestDockviewModal({
          parentPanel: "set-all-column-widths",
          params: {
            width: 400,
          },
        });
      },
    },
    {
      name: "Set Column Widths to Current",
      icon: getIcon("size"),
      action: setColumnWidthsToCurrent,
    },
    {
      name: "Format",
      requirements: [!isHeaderMenu, isProduct || (isShadowCurve && !isBlank)],
      icon: getIcon("format"),
      subMenu: formatSubMenu.map(({ item, type }) => ({
        name: getFormatMenuItemName({ api, selection: selectedRange, item }),
        action: () =>
          openFormattingDockview({
            pageId,
            type,
            columnId,
            currentTab: "color",
            selectedRange,
            productId,
          }),
      })),
    },
    {
      name: "Clear Formatting",
      requirements: [!isHeaderMenu, isProduct || (isShadowCurve && !isBlank)],
      icon: getIcon("format"),
      subMenu: [
        createFormatMenuItem({
          api,
          item: "cell",
          type: "range",
          selection: selectedRange,
          pageId,
        }),
        createFormatMenuItem({
          api,
          item: "column",
          type: "column",
          selection: selectedRange,
          pageId,
        }),
        createFormatMenuItem({
          api,
          item: "period",
          type: "period",
          selection: selectedRange,
          pageId,
        }),
        {
          name: "All",
          action: (params) => {
            const api = params.api;
            if (!api) return;
            return clearCellFormatting({
              pageId,
              type: "all",
              cellIds: getCellIds({
                type: "range",
                ranges: selectedRange,
                api,
              }).cellIds,
              colIds: getCellIds({
                type: "column",
                ranges: selectedRange,
                api,
              }).cellIds,
              periods: getCellIds({
                type: "period",
                ranges: selectedRange,
                api,
              }).cellIds,
            });
          },
        },
      ],
    },
    {
      name: "Conditional Formatting",
      disabled: disabledFeatures?.conditionalFormatting,
      tooltip: disabledFeatures?.conditionalFormatting
        ? "Please upgrade your subscription to access this feature"
        : undefined,
      requirements: [!isHeaderMenu, isProduct],
      icon: getIcon("format"),
      subMenu: [
        {
          name: "Add Rule",
          action: () => {
            requestDockviewModal({
              parentPanel: "conditional",
              currentTab: "conditional-format-add",
              params: {
                width: 1000,
                height: 470,
                selectedRange,
                type: "range",
              },
            });
          },
        },
        {
          name: "Manage",
          action: () => {
            requestDockviewModal({
              parentPanel: "conditional",
              currentTab: "conditional-format-manage",
              params: {
                width: 1000,
                height: 470,
                selectedRange,
                type: "range",
              },
            });
          },
        },
      ],
    },
    {
      name: "Alerts",
      requirements: [!isHeaderMenu, isProduct],
      disabled:
        rangeSelection.some((r) => r.rowId.startsWith("Adhoc")) ||
        disabledFeatures?.priceAlerts,
      tooltip: disabledFeatures?.priceAlerts
        ? "Please upgrade your subscription to access this feature"
        : undefined,
      icon: getIcon("alerts"),
      subMenu: [
        {
          name: "Add Alert",
          disabled: rangeSelection?.length > 1,
          tooltip:
            rangeSelection?.length > 1
              ? "Select a single cell to add an alert"
              : undefined,
          action: () => {
            requestDockviewModal({
              parentPanel: "alerts",
              currentTab: "alert-add",
              params: {
                width: 900,
                height: 420,
                selectedRange,
                type: "range",
              },
            });
          },
        },
        {
          name: "Manage",
          action: () => {
            requestDockviewModal({
              parentPanel: "alerts",
              currentTab: "alert-manage",
              params: {
                width: 900,
                height: 420,
                selectedRange,
                type: "range",
              },
            });
          },
        },
      ],
    },
    {
      name: "Add to Quick Access",
      requirements: [isProduct],
      icon: getIcon("keyboard"),
      subMenu: new Array(5).fill(null).map((_, i) => ({
        icon: quickAccess.find((q) => q.shortcutNumber === i + 1)
          ? getIcon("chevron")
          : undefined,
        name: `Quick Access Curve ${i + 1} (Alt + Shift + ${i + 1})`,
        tooltip: getQuickAccessName(i + 1, quickAccess),
        action: () => {
          upsertQuickAccess({
            userId,
            columnId,
            productId,
            pageId,
            shortcutNumber: i + 1,
          });
        },
      })),
    },
    {
      name: "Copy",
      shortcut: "Ctrl + C",
      icon: getIcon("copy"),
      action: (params) => copyRangeToClipboard(params.api),
      subMenu: [
        "copyWithHeaders",
        {
          name: "Copy with Headers + Months",
          shortcut: "Shift + Ctrl + C",
          action: (params) => copyWithHeadersAndMonths(params),
        },
        {
          name: "Copy with Metadata",
          action: (params) => {
            copyWithMetadata({ products, params });
          },
        },
        {
          name: "Copy selection as PNG",
          action: (params) => {
            if (rangeSelection.length > 0) {
              copyRangeToClipboard(params.api);
              const toastId = toast.loading(
                "Copying... please keep this window open",
              );
              copyAsPng(rangeSelection, params, toastId);
            } else {
              // TODO bug where a single cell doesn't count as a range
              // probably should use api to get selected cell in that case
              console.error("No cells selected", rangeSelection, selectedRange);
            }
          },
        },
        // copy ID,
        {
          name: "Copy ID",
          action: (_params) => {
            navigator.clipboard.writeText(productId);
          },
        },
      ],
    },
    {
      name: "Plot Live Chart",
      disabled: disabledFeatures?.plotLiveChart,
      tooltip: disabledFeatures?.plotLiveChart
        ? "Please upgrade your subscription to access this feature"
        : undefined,
      requirements: [isProduct],
      icon: getIcon("chartCandlestick"),
      action: () => {
        const cell = rangeSelection?.[0];
        if (!cell) return;

        const productId = cell?.productId;
        const rowId = cell?.rowId;
        if (!productId || !rowId) return;

        plotLiveChart({ userId, rowId, productId });
      },
    },
    "chartRange",
    {
      name: "Export",
      icon: getIcon("export"),
      subMenu: [
        {
          name: "Excel",
          action: () => api?.exportDataAsExcel(),
        },
        {
          name: "Excel with Metadata",
          action: (params) => exportToExcelWithMetadata(params),
        },
      ],
    },
  ];

  // Filter out items that don't meet requirements.
  return items.filter(
    (item) =>
      typeof item === "string" ||
      !item?.requirements ||
      item?.requirements?.every(Boolean),
  );
}

import { createContext } from "react";
import type { GridApi } from "ag-grid-community";
import { atom } from "jotai";

type ApiContextType = {
  api: GridApi | null;
  setApi: (api: GridApi | null) => void;
};

export const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const apiAtom = atom<GridApi | null>(null);

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isAndroid = /android/i.test(navigator.userAgent);

export const isMobile = isIOS || isAndroid;

import { Box, DialogTitle, Typography } from "@mui/joy";
import type { GridApi } from "ag-grid-community";
import type { MouseEventHandler } from "react";
import { useSetAtom } from "jotai";
import { Button } from "@mui/joy";
import { nanoid } from "nanoid";

import type { TOption } from "../../components/Autocomplete/Autocomplete";
import { useAutocomplete } from "../../components/Autocomplete/hooks";
import type { TData } from "../../calculations-worker/sharedStores";
import { ProductSelect } from "../../components/ProductSelect";
import { useUserSubscriptionTier } from "../../market-pages";
import type { TPageProduct } from "../../../triplit/schema";
import { defaultFieldNameSelector } from "../../utils";
import { useUserId } from "../../../context/auth";
import { modalAtom } from "../../sharedHooks";
import { columnWidth } from "../../globals";
import { generateIdxs } from "../../utils";
import { useGrid } from "../stores";
import {
  useActivePageId,
  useInsertPageProducts,
  usePageProductsSuspended,
  useProductsByIds,
} from "../../../data";

function CancelButton({
  onClick,
  label = "Cancel",
}: {
  onClick?: MouseEventHandler<HTMLElement>;
  label?: string;
}) {
  const setModal = useSetAtom(modalAtom);
  return (
    <Button
      size="sm"
      variant="outlined"
      color="neutral"
      type="button"
      onClick={(e) => {
        onClick?.(e);
        setModal(null);
      }}
    >
      {label}
    </Button>
  );
}

function SaveButton({
  onClick,
  disabled,
  loading,
  label = "Save",
  maxWidth = "100%",
}: {
  onClick: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  loading?: boolean;
  label: string;
  maxWidth?: number | string;
}) {
  const setModal = useSetAtom(modalAtom);
  return (
    <Button
      type="submit"
      disabled={disabled}
      loading={loading}
      onClick={(e) => {
        onClick(e);
        setModal(null);
      }}
      variant="solid"
      color="primary"
      size="sm"
      sx={{
        width: "100%",
        maxWidth,
      }}
    >
      {label}
    </Button>
  );
}

interface HandleAddProductsToPageParams {
  api?: GridApi<TData> | null;
  userId: string;
  pageId: string;
  currentPageProducts: TPageProduct[];
  selectedValues: TOption[];
}

function getPageProducts({
  api,
  userId,
  pageId,
  currentPageProducts,
  selectedValues,
}: HandleAddProductsToPageParams) {
  const idxs = generateIdxs({
    api,
    currentPageProducts,
    amount: selectedValues.length,
  });

  return selectedValues.map((product, index) => {
    const artis_type = product.metadata?.artis_type;

    return {
      id: nanoid(),
      idx: idxs[index],
      productId: product.value,
      userId,
      pageId,
      columnType: "product",
      columnWidth,
      columnFieldSelector: artis_type
        ? defaultFieldNameSelector(artis_type)
        : undefined,
      decimalPlaces: undefined,
      thousandsSeparator: undefined,
    } satisfies TPageProduct;
  });
}

export default function InsertCurveModal({ allowCancel = true }) {
  const pageId = useActivePageId();
  const userId = useUserId();
  const [api] = useGrid();

  const { liteUser } = useUserSubscriptionTier();
  const products = usePageProductsSuspended(useActivePageId());
  const insert = useInsertPageProducts();

  const productIds = products.data
    .map((product) => product.productId)
    .filter(Boolean);

  const dbProducts = useProductsByIds(productIds);

  const customerCurveIds = dbProducts.data
    ?.map((product) => {
      if (product?.artis_type === "customer_curve") return product.id;
    })
    .filter(Boolean);

  const useAutocompleteProps = useAutocomplete({ multiple: true });
  const {
    multipleState: [selectedValues, setSelectedValues],
  } = useAutocompleteProps;

  const handleSave = () => {
    const pageProducts = getPageProducts({
      api,
      userId,
      pageId,
      currentPageProducts: products.data,
      selectedValues,
    });
    return insert.mutateAsync(pageProducts);
  };

  return liteUser ? (
    "This Page has no Curves. Please contact Support."
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "22dvw",
        maxWidth: 400,
        minWidth: 300,
      }}
    >
      <DialogTitle sx={{ alignItems: "center" }}>
        Add to current Page
      </DialogTitle>
      <Typography>Select Curve(s)</Typography>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: 1,
        }}
      >
        <ProductSelect
          multiple
          {...useAutocompleteProps}
          excludeIds={customerCurveIds}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          justifyContent: "flex-start",
        }}
      >
        <SaveButton
          label="Add"
          disabled={!userId || insert.isPending || !selectedValues.length}
          loading={insert.isPending}
          onClick={handleSave}
        />
        {api && allowCancel && <CancelButton />}
      </Box>
    </Box>
  );
}

import { useApolloClient, useQuery as useApolloQuery } from "@apollo/client";
import { atom, getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import posthog from "posthog-js";
import { useState } from "react";
import { z } from "zod";

import { DeleteSessionAndLogoutRequest } from "../../webapp/calculations-worker/graphql";
import type { UserDetailsFragmentFragment } from "../../__generated__/gql/graphql";
import { calcWorker } from "../../webapp/calculations-worker/hooks";
import { client, client as triplitClient } from "../../triplit/triplit";
import { ORG_BY_ID_QUERY } from "../../graphqlOperations";
import { useUrlEnv } from "../../context/environment";
import { isMobile } from "../../shared/hooks";
import { parse } from "../../webapp/numbers";
import {
  DEV_AUTH0_USERS_URL,
  type JWT,
  PROD_AUTH0_USERS_URL,
  UAT_AUTH0_USERS_URL,
  getAuthOrgId,
} from "../../globals";

export const subscriptionTierSchema = z.enum([
  "artis_enhanced",
  "artis_enhanced_noexch",
  "artis_lite",
  "artis_professional",
]);

export function useUserId(encoded = false) {
  const { user } = useAuth0();
  const userId = user?.sub ?? "";

  if (encoded) {
    return encodeURIComponent(userId);
  }
  return userId;
}

const claimsAtom = atom<JWT | null>(null);
claimsAtom.debugLabel = "claimsAtom";

export const getAccessTokenAtom = atom<{
  getToken: ReturnType<typeof useAuth0>["getAccessTokenSilently"] | undefined;
}>({
  getToken: undefined,
});

export const userAtom = atom<UserDetailsFragmentFragment | null>(null);
userAtom.debugLabel = "userAtom";

export const userDetailsAtom = atom((get) => {
  const user = get(userAtom);
  const subscriptionTier = user?.subscription_tier;
  const liteUser = subscriptionTier === subscriptionTierSchema.enum.artis_lite;
  const enhancedUser =
    subscriptionTier === subscriptionTierSchema.enum.artis_enhanced;
  const enhancedNoExchUser =
    subscriptionTier === subscriptionTierSchema.enum.artis_enhanced_noexch;

  // Based on: https://artis.works/#pricing
  const disabledFeatures =
    !liteUser && !enhancedUser
      ? undefined
      : {
          showFormula: liteUser,
          headerSize: liteUser,
          curveDetails: liteUser,
          spreader: liteUser,
          managePages: liteUser,
          pauseExchangeData: liteUser,
          changeSource: liteUser,
          statusRow: liteUser,
          charts: liteUser,
          createPage: liteUser,
          createCurve: liteUser || enhancedUser,
          editCurve: liteUser || enhancedUser,
          insertCurve: liteUser,
          removeCurve: liteUser,
          priceAlerts: liteUser || enhancedUser,
          plotLiveChart: liteUser,
          conditionalFormatting: liteUser,
        };
  const jwt = get(claimsAtom);

  return {
    ...user,
    jwt,
    subscriptionTier: {
      liteUser,
      enhancedUser,
      enhancedNoExchUser,
      disabledFeatures,
    },
  };
});

export type TSession =
  | {
      jwt: string;
      auth0Session: { id: string; start: string };
    }
  | null
  | undefined;

export const useAuthToken = () => {
  const { getAccessTokenSilently, isLoading, loginWithRedirect } = useAuth0();
  const setClaims = useSetAtom(claimsAtom);
  const [session, setSession] = useState<TSession>(null);

  // Token refresh handler
  useEffect(() => {
    let refreshTimeout: NodeJS.Timeout;

    const scheduleTokenRefresh = (expirationTime: number) => {
      // Get current time in seconds
      const now = Math.floor(Date.now() / 1000);
      // Calculate time until expiration in seconds
      const timeUntilExpiration = expirationTime - now;
      // Refresh 5 minutes before expiration
      const refreshDelay = Math.max(0, (timeUntilExpiration - 300) * 1000);

      refreshTimeout = setTimeout(async () => {
        try {
          const response = await getAccessTokenSilently({
            detailedResponse: true,
            cacheMode: "off",
          });
          const { access_token, id_token } = response;
          const newToken = jwtDecode<JWT>(id_token);

          triplitClient.updateSessionToken(access_token);

          // Schedule next refresh based on new token
          if (newToken.exp) {
            scheduleTokenRefresh(newToken.exp);
          }
        } catch (error) {
          console.error("Error refreshing token", error);
        }
      }, refreshDelay);
    };

    // Initial token setup
    const fetchToken = async () => {
      try {
        const response = await getAccessTokenSilently({
          detailedResponse: true,
        });

        const { access_token, id_token } = response;
        const parsedToken = jwtDecode<JWT>(id_token);
        const sessionId = parsedToken.sid;
        const sessionStart = new Date(parsedToken.iat * 1000).toISOString();

        setSession({
          jwt: access_token,
          auth0Session: {
            id: sessionId,
            start: sessionStart,
          },
        });

        const updatedClaims = {
          ...parsedToken,
          auth0Session: {
            id: sessionId,
            start: sessionStart,
          },
        };

        client.updateSessionToken(access_token);

        setClaims(updatedClaims);

        // Schedule refresh based on token expiration
        if (parsedToken.exp) {
          scheduleTokenRefresh(parsedToken.exp);
        }
      } catch (error) {
        console.error("Error fetching the token", error);
        loginWithRedirect({ appState: { returnTo: "/" } });
      }
    };

    fetchToken();

    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [getAccessTokenSilently, setClaims, loginWithRedirect]);

  return { isLoading, session };
};

export function useClaims() {
  const { getAccessTokenSilently } = useAuth0();
  const setClaims = useSetAtom(claimsAtom);

  useEffect(() => {
    const fetchClaims = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken = jwtDecode<JWT>(token);
        setClaims(decodedToken);
      } catch (error) {
        console.error("Error decoding token", error);
      }
    };

    fetchClaims();
  }, [getAccessTokenSilently, setClaims]);

  return useAtomValue(claimsAtom);
}

export function useCurrentUserOrg() {
  const claims = useClaims();
  const orgId = parse(
    claims?.["https://hasura.io/jwt/claims"]?.["x-hasura-org-id"],
  );
  return useApolloQuery(ORG_BY_ID_QUERY, {
    variables: { id: orgId ?? 7 },
    skip: !orgId,
  });
}

export function useCurrentUsername() {
  const { user } = useAuth0();
  return `${user?.given_name} ${user?.family_name}`;
}

export function useRoles() {
  const claims = useClaims();

  if (!claims) {
    return [];
  }

  const claimsRoles =
    claims?.["https://hasura.io/jwt/claims"]?.["x-hasura-allowed-roles"] || [];
  if (claimsRoles.includes("umi-internal-super-admin")) {
    return [
      "umi-internal-super-admin",
      "umi-internal-admin",
      "umi-internal-write",
      "umi-internal-read",
    ];
  }
  if (claimsRoles.includes("umi-internal-admin")) {
    return ["umi-internal-admin", "umi-internal-write", "umi-internal-read"];
  }
  if (claimsRoles.includes("umi-internal-write")) {
    return ["umi-internal-write", "umi-internal-read"];
  }
  return claimsRoles;
}

export function useAuth0Config() {
  const [env] = useUrlEnv();
  const auth0_url =
    !env || env === "dev"
      ? DEV_AUTH0_USERS_URL
      : env === "uat"
        ? UAT_AUTH0_USERS_URL
        : PROD_AUTH0_USERS_URL;

  const auth0_org_id = getAuthOrgId(env);

  return { auth0_url, auth0_org_id, env } as const;
}

export function useLogout() {
  const { logout, user } = useAuth0();
  const worker = calcWorker?.()?.proxy;
  const apollo = useApolloClient();
  const logoutFn = useCallback(async () => {
    try {
      const userId = user?.sub;
      const store = getDefaultStore();
      if (!userId || !worker) {
        console.log("no user id or worker", userId, worker);
        return;
      }
      const isUmi = window.location.pathname.includes("admin");
      if (!isUmi) {
        const groups = await triplitClient.fetchById(
          "userGroups",
          "multisession",
        );

        if (
          apollo &&
          !groups?.users?.has(userId) &&
          // TODO fix multisession and remove this line to reenable
          userId === "disable for now"
        ) {
          try {
            await worker.requestLogout();
            const { data } = await apollo.mutate({
              mutation: DeleteSessionAndLogoutRequest,
              variables: {
                user: userId,
                mobile: isMobile,
                context: isMobile ? "mobile" : "desktop",
              },
            });
            if (data?.delete_session && data.delete_session.affected_rows > 0) {
              console.log("Logout request deleted successfully");
            } else {
              console.warn("No logout request was deleted");
            }
          } catch (error) {
            console.error("Error executing logout mutation:", error);
          }
        }
      }
      posthog.capture("user clicked logout");
      posthog.reset();
      await triplitClient.endSession();
      await triplitClient.reset();
      if (apollo) {
        await apollo.clearStore();
      }

      localStorage.clear();
      sessionStorage.clear();

      console.log(`Data cleared - Logging out - isUmi ${isUmi}`);
      return logout({
        logoutParams: {
          returnTo: `${window.location.origin}${isUmi ? "/admin/orgs" : ""}`,
        },
      });
    } catch (error) {
      console.error("Error logging out", error);
    }
  }, [logout, user, worker, apollo]);
  return logoutFn;
}

export async function fetchUserGroups() {
  const groupsQuery = triplitClient
    .query("userGroups")
    .select(["id"])
    .where(["users", "has", "$session.SESSION_USER_ID"])
    .build();
  return await triplitClient.fetch(groupsQuery).catch((error) => {
    console.error("Error fetching user groups", error);
    return null;
  });
}

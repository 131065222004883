import { Grid, type GridProps, Option, Select, Typography } from "@mui/joy";

import { useGridSettings, useUpdateGridSettings } from "./hooks";
import { capitalizeFirstLetter } from "../../utils";
import { useUserId } from "../../context/auth";
import { londonDate } from "../utils";
import Checkbox from "./Checkbox";
import {
  defaultMonthsRangeSettings,
  genMonthList,
  nthNextCals,
  nthNextHlvs,
  nthNextMonths,
  nthNextQtrs,
  nthNextSeasons,
} from "../market-grid";

const DateColumnGridSx = {
  container: true,
  wrap: "nowrap",
  spacing: 1,
  sx: {
    width: 300,
  },
} satisfies GridProps;

export function SettingsDateColumn() {
  const userId = useUserId();
  const orderedDateColumnSettings = useDateColumnSettings();
  const update = useUpdateGridSettings();

  const keyMappings = {
    qtrs: "Quarters",
    hlvs: "Halves",
    months: "Months",
    cals: "Calendars",
    seasons: "Seasons",
  } satisfies Record<
    (typeof orderedDateColumnSettings)[number]["valueKey"],
    string
  >;

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <Typography level="h4" fontWeight="bold" sx={{ mb: 2 }}>
            Grid appearance
          </Typography>

          <div className="flex flex-col gap-2">
            <Grid {...DateColumnGridSx}>
              <Grid xs={4}>
                <Typography>Period</Typography>
              </Grid>
              <Grid xs={4}>
                <Typography>To</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography>Enable</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography>Current</Typography>
              </Grid>
            </Grid>

            {orderedDateColumnSettings.map((x) => (
              <SettingsDateColumnRow
                key={x.valueKey}
                title={
                  keyMappings[x.valueKey] || capitalizeFirstLetter(x.valueKey)
                }
                value={x.value}
                onValue={(v) =>
                  update.mutate({
                    id: userId,
                    updaterFn: (e) => {
                      e[x.valueKey] = v;
                    },
                  })
                }
                enabled={x.show ?? null}
                onEnabled={(v) =>
                  x.showKey &&
                  update.mutate({
                    id: userId,
                    updaterFn: (e) => {
                      e[x.showKey] = v;
                    },
                  })
                }
                current={x.current ?? null}
                onCurrent={(v) =>
                  x.currentKey &&
                  update.mutate({
                    id: userId,
                    updaterFn: (e) => {
                      e[x.currentKey] = v;
                    },
                  })
                }
                options={x.options.map(([value, label]) => ({ value, label }))}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

const SettingsDateColumnRow = (p: {
  title: string;
  value: number;
  onValue: (v: number) => void;
  enabled: null | boolean;
  onEnabled?: (v: boolean) => void;
  current: null | boolean;
  onCurrent?: (v: boolean) => void;
  options: { value: number; label: string }[];
}) => {
  return (
    <Grid {...DateColumnGridSx}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        xs={4}
      >
        <Typography>{p.title}</Typography>
      </Grid>
      <Grid xs={4}>
        <Select
          value={p.value.toString()}
          renderValue={(newValue) => newValue?.label}
          onChange={(_e, newValue) => {
            newValue !== null && p.onValue(Number.parseInt(newValue, 10));
          }}
          sx={{ width: "100%" }}
        >
          {p.options.map((o) => {
            return (
              <Option key={o.value} value={o.value.toString()}>
                {o.label}
              </Option>
            );
          })}
        </Select>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        xs={2}
      >
        {p.enabled !== null && (
          <Checkbox
            label=""
            checked={p.enabled}
            onChange={(event) => p.onEnabled?.(!!event.target.checked)}
          />
        )}
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        xs={2}
      >
        {p.current !== null && (
          <Checkbox
            label=""
            checked={p.current}
            onChange={(event) => p.onCurrent?.(!!event.target.checked)}
          />
        )}
      </Grid>
    </Grid>
  );
};

const useDateColumnSettings = () => {
  const settings = useGridSettings();

  const date = londonDate();
  const monthList = genMonthList(defaultMonthsRangeSettings, date);

  const monthOptions = nthNextMonths(monthList || settings.months);
  const monthValue = settings.months ?? monthOptions[0][0];

  const qtrsOptions = nthNextQtrs(monthList, settings.qtrCurrent);
  const qtrsValue = settings.qtrs ?? qtrsOptions[0][0];

  const hlvsOptions = nthNextHlvs(monthList, settings.hlvCurrent);
  const hlvsValue = settings.hlvs ?? hlvsOptions[0][0];

  const seasonsOptions = nthNextSeasons(monthList, settings.seasonCurrent);
  const seasonsValue = settings.seasons ?? seasonsOptions[0][0];

  const calsOptions = nthNextCals(monthList, settings.calCurrent);
  const calsValue = settings.cals ?? calsOptions[0][0];

  const orderedDateColumnSettings = [
    {
      options: monthOptions,
      value: monthValue,
      valueKey: "months",
      current: null,
      show: null,
      showKey: null,
      currentKey: null,
    },
    {
      options: qtrsOptions,
      value: qtrsValue,
      current: settings.qtrCurrent,
      show: settings.qtrSwitch,
      valueKey: "qtrs",
      showKey: "qtrSwitch",
      currentKey: "qtrCurrent",
    },
    {
      options: hlvsOptions,
      value: hlvsValue,
      current: settings.hlvCurrent,
      show: settings.hlvSwitch,
      valueKey: "hlvs",
      showKey: "hlvSwitch",
      currentKey: "hlvCurrent",
    },
    {
      options: seasonsOptions,
      value: seasonsValue,
      current: settings.seasonCurrent,
      show: settings.seasonSwitch,
      valueKey: "seasons",
      showKey: "seasonSwitch",
      currentKey: "seasonCurrent",
    },
    {
      options: calsOptions,
      value: calsValue,
      current: settings.calCurrent,
      show: settings.calSwitch,
      valueKey: "cals",
      showKey: "calSwitch",
      currentKey: "calCurrent",
    },
  ] as const;

  return orderedDateColumnSettings;
};

import { useSubscription, useMutation } from "@apollo/client";

import { graphql } from "../graphql";

export const useUserPermissions = (userId: string) => {
  return useSubscription(userPermissionsSub, {
    variables: { folio_user: userId },
  });
};

const userPermissionsSub = graphql(`
  subscription userPermissionsSub($folio_user: String!) {
    permission(where: { folio_user: { _eq: $folio_user } }) {
      id
      packageByPackage {
        id
        name
        sourceBySource {
          id
          name
        }
      }
    }
  }
`);

export const useUserExchangeUsage = (userId: string) => {
  return useSubscription(lastExchangeUsageForSingleUser, {
    variables: { userId },
  });
};

const lastExchangeUsageForSingleUser = graphql(`
  subscription lastExchangeUsageForSingleUser($userId: String!) {
    exchange_usage_last(
      where: { folio_user: { _eq: $userId } }
      order_by: { event_date: desc }
    ) {
      source
      event
    }
  }
`);

export const useUserAmendExchangeUsage = () => {
  return useMutation(amendExchangeUsage);
};

export const amendExchangeUsage = graphql(`
  mutation AmendExchangeUsage(
    $exchangeUsages: [exchange_usage_insert_input!]!
  ) {
    insert_exchange_usage(objects: $exchangeUsages) {
      affected_rows
    }
  }
`);

import type {
  ISeriesApi,
  ITimeScaleApi,
  Time,
  UTCTimestamp,
} from "lightweight-charts";
import {
  getNumberOfBars,
  type useHistoricalChartData,
} from "./useHistoricalChartData";
import { genDatesBasedOnBars, sampleConfig, type TSample } from "../utils";
import { useQueryClient } from "@tanstack/react-query";
import { useThrottledCallback } from "@react-hookz/web";
import { checkCacheForData } from "./helpers";

// 2022-04-29
// without some hardcoded value for the historical limit
// it becomes very tricky to determine when to stop fetching
// without having the grid blow up due to messed up timestamps
const historicalLimitUnix = 1651190400;

export function useFetchHistoricalData({
  fetchPreviousPage,
  chart,
  timeScale,
  productId,
  sampleTime,
  periodFrom,
}: {
  fetchPreviousPage: ReturnType<typeof useHistoricalChartData>["fetchNextPage"];
  chart: ISeriesApi<"Custom"> | null;
  timeScale: ITimeScaleApi<Time> | null;
  productId: string | undefined;
  sampleTime: TSample | undefined;
  periodFrom: string | undefined;
}) {
  const client = useQueryClient();

  // we can't pass dependecies that change more often than productId or sampleTime
  // as it will cause the throttled callback to be reacreated and the
  // chart to jump back to the initial position.
  return useThrottledCallback(
    async () => {
      if (!productId || !sampleTime) return;

      const isFetching = client.isFetching({
        queryKey: ["historicalChartData", productId, sampleTime],
      });

      if (isFetching) {
        console.log("Already fetching");
        return;
      }

      const visibleRange = timeScale?.getVisibleLogicalRange();

      if (!chart || !visibleRange) return;

      const currentData = chart?.data();
      const firstEntryTime = currentData[0]?.time as UTCTimestamp | undefined;

      if (firstEntryTime && firstEntryTime <= historicalLimitUnix) {
        console.log("No more historical data to fetch.");
        return;
      }

      const bars = chart?.barsInLogicalRange(visibleRange);
      const numberOfBarsBeforeLeftEdge = bars?.barsBefore;
      const barsAfter = bars?.barsAfter;

      if (currentData?.length) {
        const currentNumberOfBars = currentData.length;

        // issue with this is it doesn't count gaps. if you query e.g 5s at the weekend you won't get many if any bars depending on zoom level
        const minBarsInView = sampleConfig?.[sampleTime]?.minBars || 50;

        if (
          numberOfBarsBeforeLeftEdge &&
          numberOfBarsBeforeLeftEdge <= minBarsInView
        ) {
          const numberOfBars = getNumberOfBars(chart, timeScale, sampleTime);
          const { fromDate: fromUTCDate, toDate: toUTCDate } =
            genDatesBasedOnBars(numberOfBars, sampleTime);

          const cachedData = checkCacheForData({
            productId,
            sampleTime,
            periodFrom,
            fromUTCDate,
            toUTCDate,
          });

          if (cachedData?.data?.length) {
            return cachedData.data;
          }

          console.log("Fetching...");

          const { data } = await fetchPreviousPage();

          console.log("From total bars:", currentNumberOfBars);
          console.log("To total bars:", data?.length);
        }
      }
    },
    [chart, productId, sampleTime],
    300,
  );
}

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { type ReactNode, useMemo } from "react";
import { type ApolloClient, ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { atom, useAtom } from "jotai";

import { createApolloClient } from "./client";
import { useGlobalEnv } from "../environment";

const isAppAtom = atom(false);
export const useIsApp = () => useAtom(isAppAtom);

export type TApolloClient = ApolloClient<object>;

if (process.env.NODE_ENV === "development") {
  loadDevMessages();
  loadErrorMessages();
}

export const ApolloClientProvider = ({ children }: { children: ReactNode }) => {
  const [isApp] = useIsApp();
  const auth = useAuth0();
  const env = useGlobalEnv();

  const client = useMemo(
    () => createApolloClient(env, auth.getAccessTokenSilently, isApp),
    [auth.getAccessTokenSilently, isApp, env],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

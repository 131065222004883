import { Box, Checkbox, Divider, Input, Stack, Typography } from "@mui/joy";
import type { useRangeSelection } from "../../sharedHooks";
import { HexColorPicker } from "react-colorful";
import { useCallback, useEffect, useState } from "react";

import { ModalButtons } from "./Buttons";
import {
  cancelCellFormatting,
  getCellIds,
  initialFormattingAndSettingsAtom,
  modalTypeToHighlightType,
  setCellFormatting,
  usePageFormatting,
} from "./formatCellHelpers";
import { unique } from "remeda";
import { useDebouncedCallback } from "@react-hookz/web";
import { gridModalDockviewAtom } from "../../calculations-worker/sharedStores";
import { useAtom, useAtomValue } from "jotai";
import { GridModalContainer } from "./GridModal";
import { saveColumnSettings } from "./ColumnSettings";
import { GridModalSelectionInfo } from "./modalComponents";
import { validHexChars } from "../../utils";
import { useGrid } from "../stores";

const swatches = [
  "#c6d9f0",
  "#ebf1dd",
  "#fdeada",
  "#fcdad9",
  "#ffffff",
  "#0039bb",
  "#00ff00",
  "#4f81bd",
  "#9bbb59",
  "#f79646",
  "#c0504d",
  "#c8c8c8",
  "#41eefa",
  "#ff0000",
];

function Swatch({
  color,
  onClick,
}: {
  color: string;
  onClick: (hex: string) => void;
}) {
  return (
    <Box
      onClick={() => onClick(color)}
      sx={{
        width: "20px",
        height: "20px",
        backgroundColor: color,
        border: color === "#ffffff" ? "1px inset black" : "none",
        margin: "4px",
        borderRadius: "4px",
        cursor: "pointer",
        ":hover": {
          border: "2px solid black",
          borderStyle: "inset",
        },
      }}
    />
  );
}

export type TFormatTypes = "range" | "column" | "period";

export type TFormatProps = {
  initialFormatting: ReturnType<typeof usePageFormatting>;
  pageFormatting: ReturnType<typeof usePageFormatting>;
  pageId: string;
  type: TFormatTypes;
};

export type TRangeSelection = ReturnType<typeof useRangeSelection>[0];

function FormatCellColorsWithState({
  initialFormatting,
  pageId,
  pageFormatting,
  type,
}: TFormatProps) {
  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const ranges = dockviewValue?.formatting?.selectedRange || [];

  const initialSettings = useAtomValue(
    initialFormattingAndSettingsAtom,
  )?.settings;

  const highlightType = modalTypeToHighlightType(type);
  const highlights = pageFormatting[highlightType];

  const [api] = useGrid();

  const { cellIds } = getCellIds({ type, ranges, api });

  const cellColors = unique(
    cellIds.map((cellId) => highlights[cellId]?.color) || [],
  ).filter(Boolean);

  const initialValue = cellColors?.length === 1 ? cellColors[0] : "#fff";

  const [value, setValue] = useState<string | null>(initialValue);
  const [hexValue, setHexValue] = useState<string>(initialValue);

  const handleColorPickerChange = useDebouncedCallback(
    (hex: string) => {
      cellIds.map((cellId) => {
        highlights[cellId] = {
          color: hex,
          boldText: highlights[cellId]?.boldText || false,
          invertTextColor: highlights[cellId]?.invertTextColor || false,
        };
      });

      setCellFormatting(pageId, highlightType, highlights);
    },
    [cellIds, highlights, type],
    500,
  );

  const handleInputChange = useCallback(
    (newValue: string) => {
      if (!validHexChars(newValue?.split(""))) {
        return;
      }

      const hex =
        newValue?.length > 0 && newValue[0] === "#" ? newValue : `#${newValue}`;

      setValue(hex);
      setHexValue(hex);
      if (value !== hex) {
        handleColorPickerChange(hex);
      }
    },
    [handleColorPickerChange, value],
  );

  return (
    <GridModalContainer
      panel="formatting"
      body={
        <>
          <GridModalSelectionInfo selectedRange={ranges} />
          <Box
            sx={{
              width: 232,
              ml: "auto",
              mr: "auto",
              padding: 2,
              ".react-colorful": {
                height: 140,
              },
            }}
          >
            <HexColorPicker
              color={value || undefined}
              onChange={(newColor) => {
                handleColorPickerChange(newColor);

                if (newColor !== hexValue) {
                  setHexValue(newColor);
                }
              }}
            />
            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              sx={{
                padding: "8px 0 8px 2px",
              }}
            >
              {swatches.map((color) => (
                <Swatch
                  key={color}
                  color={color}
                  onClick={(hex: string) => handleInputChange(hex)}
                />
              ))}
            </Stack>
            <Divider />
            <Stack padding={2}>
              <Input
                type="text"
                value={hexValue}
                onChange={(e) => handleInputChange(e.target.value)}
              />
            </Stack>
          </Box>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel="formatting"
          onCancel={() => {
            cancelCellFormatting(pageId, initialFormatting);

            if (initialSettings) {
              saveColumnSettings(initialSettings);
            }
          }}
        />
      }
      justifyContent="flex-start"
    />
  );
}

function FormatCellTextWithState({
  pageId,
  initialFormatting,
  pageFormatting,
  type,
}: TFormatProps) {
  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const ranges = dockviewValue?.formatting?.selectedRange || [];

  const initialSettings = useAtomValue(
    initialFormattingAndSettingsAtom,
  )?.settings;

  const highlightType = modalTypeToHighlightType(type);
  const highlights = pageFormatting[highlightType];

  const [api] = useGrid();

  const { cellIds } = getCellIds({ type, ranges, api });

  const cellBoldText = unique(
    cellIds.map((cellId) => {
      return highlights[cellId]?.boldText;
    }) || [],
  ).filter(Boolean);

  const cellInvertTextColor = unique(
    cellIds.map((cellId) => {
      return highlights[cellId]?.invertTextColor;
    }) || [],
  ).filter(Boolean);

  const initialBoldText = cellBoldText.length === 1 ? cellBoldText[0] : false;
  const initialInvertTextColor =
    cellInvertTextColor.length === 1 ? cellInvertTextColor[0] : false;

  const [boldText, setBoldText] = useState<boolean>(initialBoldText);
  const [invertTextColor, setInvertTextColor] = useState<boolean>(
    initialInvertTextColor,
  );

  const handleSetBoldText = useCallback(
    (boldText: boolean) => {
      setBoldText(boldText);

      cellIds.map((cellId) => {
        highlights[cellId] = {
          color: highlights[cellId]?.color || null,
          boldText: boldText || false,
          invertTextColor: highlights[cellId]?.invertTextColor || false,
        };
      });

      setCellFormatting(pageId, highlightType, highlights);
    },
    [cellIds, highlightType, pageId, highlights],
  );

  const handleSetInvertTextColor = useCallback(
    (invertTextColor: boolean) => {
      setInvertTextColor(invertTextColor);

      cellIds.map((cellId) => {
        highlights[cellId] = {
          color: highlights[cellId]?.color || null,
          boldText: highlights[cellId]?.boldText || false,
          invertTextColor: invertTextColor || false,
        };
      });

      setCellFormatting(pageId, highlightType, highlights);
    },
    [cellIds, highlightType, pageId, highlights],
  );

  return (
    <GridModalContainer
      panel="formatting"
      body={
        <>
          <GridModalSelectionInfo selectedRange={ranges} />
          <Stack
            sx={{
              padding: 2,
            }}
          >
            <Stack gap={2}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography>Bold Text</Typography>
                <Checkbox
                  color="neutral"
                  checked={boldText}
                  onChange={(e) => {
                    handleSetBoldText(e.target.checked);
                  }}
                />
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography>Invert Text Color</Typography>
                <Checkbox
                  color="neutral"
                  checked={invertTextColor}
                  onChange={(e) => {
                    handleSetInvertTextColor(e.target.checked);
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel="formatting"
          onCancel={() => {
            cancelCellFormatting(pageId, initialFormatting);

            if (initialSettings) {
              saveColumnSettings(initialSettings);
            }
          }}
        />
      }
      justifyContent="flex-start"
    />
  );
}

export type TFormatCellProps = Omit<
  TFormatProps,
  "initialFormatting" | "pageFormatting"
> & {
  tab: "colors" | "text";
};

export default function FormatCell({ pageId, type, tab }: TFormatCellProps) {
  const pageFormatting = usePageFormatting(pageId);
  const [initialFormattingAtom, setInitialFormattingAtom] = useAtom(
    initialFormattingAndSettingsAtom,
  );

  useEffect(() => {
    if (!initialFormattingAtom.formatting && !pageFormatting.fetching) {
      setInitialFormattingAtom({
        ...initialFormattingAtom,
        formatting: pageFormatting,
      });
    }
  }, [initialFormattingAtom, pageFormatting, setInitialFormattingAtom]);

  if (pageFormatting.fetching || !initialFormattingAtom.formatting) return null;

  if (tab === "colors") {
    return (
      <FormatCellColorsWithState
        initialFormatting={initialFormattingAtom.formatting}
        pageFormatting={pageFormatting}
        pageId={pageId}
        type={type}
      />
    );
  }

  return (
    <FormatCellTextWithState
      initialFormatting={initialFormattingAtom.formatting}
      pageFormatting={pageFormatting}
      pageId={pageId}
      type={type}
    />
  );
}

import { useMemo } from "react";
import * as R from "remeda";

import type { TOption } from "../components/Autocomplete/Autocomplete";
import { productName, sortByStringCaseInsensitive } from "../utils";
import { useAllProductsSuspended, useGlobalProducts } from "../../data";

export function useAllProductsOptions(props?: {
  disableListPermission?: boolean;
  selectedIds?: string[];
  excludeIds?: string[];
}) {
  const { disableListPermission, selectedIds, excludeIds } = props || {};

  const { data, isFetching } = useAllProductsSuspended();

  const mappedData = useMemo(() => {
    const selectedIdsSet = new Set(selectedIds);
    const excludeIdsSet = new Set(excludeIds);

    return data
      ?.map((product) => {
        return {
          ...product,
          label: productName(product),
        };
      })
      .filter((product) => {
        const selectedIdsFilter =
          selectedIdsSet.size > 0 ? selectedIdsSet.has(product.id) : true;

        const excludeIdsFilter =
          excludeIdsSet.size > 0 ? !excludeIdsSet.has(product.id) : true;

        return product.name && selectedIdsFilter && excludeIdsFilter;
      });
  }, [data, selectedIds, excludeIds]);

  const groupedData = useMemo(() => {
    return (
      mappedData &&
      R.groupBy(mappedData, (product) => product.packageByPackage.name)
    );
  }, [mappedData]);

  const parsedData = useMemo(() => {
    return (
      groupedData &&
      R.mapValues(groupedData, (group) => {
        const packageName = group[0].packageByPackage.name;
        const sortedOptions = sortByStringCaseInsensitive("name", group).map(
          (p) => {
            return {
              label: p.label,
              value: p.id,
              disabled:
                (disableListPermission &&
                  p.packageByPackage.permissions?.[0]?.permission === "list") ||
                false,
              groupValue: packageName,
              metadata: p,
            } as const satisfies TOption;
          },
        );

        return sortedOptions;
      })
    );
  }, [groupedData, disableListPermission]);

  const options = useMemo(() => {
    return parsedData && R.values(parsedData).flat();
  }, [parsedData]);

  return { data: options, fetching: isFetching };
}

export type TProductIdAndWidth = {
  gridId: string;
  productId?: string;
  width: number;
  columnFieldSelector?: string;
  columnSettings?: {
    decimalPlaces?: number | null;
    thousandsSeparator?: boolean | null;
  };
};

export function useGlobalProductOptions() {
  const { data, isLoading: fetching, error } = useGlobalProducts();

  if (error) console.error("error fetching global product options", { error });

  if (!data) {
    return { data: undefined, fetching };
  }

  const mappedData = data.map((product) => ({
    ...product,
    label: product.description || product.name,
  }));

  const groupedData = R.groupBy(
    mappedData,
    (product) => product.globalPackageByGlobalPackage.name,
  );

  const parsedData = R.mapValues(groupedData, (group) => {
    const packageName = group[0].globalPackageByGlobalPackage.name;
    return sortByStringCaseInsensitive("name", group).map(
      (p) =>
        ({
          label: p.label,
          value: p.id,
          disabled:
            p.globalPackageByGlobalPackage.global_permissions?.[0]
              ?.permission === "list",
          groupValue: packageName,
          metadata: undefined,
        }) as const satisfies TOption,
    );
  });

  const options = R.values(parsedData).flat();

  return { data: options, fetching };
}

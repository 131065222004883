import { useAuth0 } from "@auth0/auth0-react";
import { getHasuraUrl } from "../../globals";
import { useUrlEnv } from "../../context/environment";

export function useApi() {
  const { getAccessTokenSilently } = useAuth0();

  const [env] = useUrlEnv();
  const hasuraUrl = getHasuraUrl(env);

  const makeApiRequest = async (url: string, method: string, body: string) => {
    const token = await getAccessTokenSilently();

    return fetch(url, {
      method,
      body,
      headers: {
        "x-hasura-url": hasuraUrl,
        Authorization: `Bearer ${token}`,
      },
    }).then((e) => {
      if (e.ok) return e;
      throw new Error(`Error making request to ${url}: ${e.statusText}`);
    });
  };

  return makeApiRequest;
}

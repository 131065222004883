import { useQuery } from "@triplit/react";
import { client } from "../../triplit/triplit";
import { useAuth0 } from "@auth0/auth0-react";
import { useOrgId } from "../orgs";
import type { clearanceLevelsArray } from "../../globals";
import { useRoles } from "../../context/auth";
import { nanoid } from "nanoid";
import { posthog } from "posthog-js";

export function useAuditEntries() {
  const {
    results: auditEntries,
    fetchingLocal,
    error,
  } = useQuery(client, client.query("auditLog"));

  return {
    auditEntries,
    fetching: fetchingLocal,
    error,
  };
}

type Status = "started" | "succeeded" | "failed";

interface AuditLoggerOptions {
  actionName: string;
  id: string;
  clearanceLevel?: (typeof clearanceLevelsArray)[number];
}

const LOG_TABLE = "auditLog";

class AuditLogger {
  private status: Status = "started";
  private retries = 0;
  private description: string | null = null;

  constructor(
    private options: AuditLoggerOptions,
    private userId: string | undefined,
    private orgId: ReturnType<typeof useOrgId>,
    private rolesPromise: ReturnType<typeof useRoles>,
  ) {
    // Empty constructor intentionally left blank.
  }

  public async start(description: string) {
    this.description = description;
    this.status = "started";
    await this.checkRoles();
    await this.write();
  }

  public async success() {
    this.status = "succeeded";
    posthog.capture(`${this.options.actionName} Succeeded.`, {
      description: this.description,
      clearanceLevel: this.options.clearanceLevel,
      userId: this.userId,
      orgId: this.orgId,
      type: "success",
    });

    return await client.update(LOG_TABLE, this.options.id, (entity) => {
      entity.succeededAt = new Date();
      entity.status = this.status;
    });
  }

  public async error(message: string) {
    this.status = "failed";
    this.retries += 1;
    this.description += `\nFailed with error: ${message}`;

    posthog.capture(`${this.options.actionName} Failed.`, {
      description: this.description,
      clearanceLevel: this.options.clearanceLevel,
      userId: this.userId,
      orgId: this.orgId,
      type: "error",
    });

    return await client.update(LOG_TABLE, this.options.id, (entity) => {
      entity.retries = this.retries;
      entity.status = this.status;
      entity.description = this.description;
    });
  }

  private async checkRoles() {
    const roles = await this.rolesPromise;
    if (
      this.options.clearanceLevel &&
      !roles.includes(this.options.clearanceLevel)
    ) {
      throw new Error(`User does not have required clearance level. {
        userRoles: ${roles},
        requiredRole: ${this.options.clearanceLevel},
      }`);
    }
  }

  private async write() {
    const res = await client.insert(LOG_TABLE, {
      id: this.options.id,
      actionName: this.options.actionName,
      status: this.status,
      description: this.description,
      clearanceLevel: this.options.clearanceLevel || "user",
      createdAt: new Date(),
      succeededAt: null,
      retries: this.retries,
      userId: this.userId || "unknown",
      orgId: this.orgId?.toString() || null,
      userGroup: null,
    });
    return res;
  }
}

export function useAuditLoggerFactory() {
  const { user } = useAuth0();
  const userId = user?.sub;
  const orgId = useOrgId();
  const rolesPromise = useRoles();

  return ({
    actionName,
    clearanceLevel,
  }: {
    actionName: string;
    clearanceLevel?: (typeof clearanceLevelsArray)[number];
  }) => {
    const id = nanoid();
    return new AuditLogger(
      { actionName, clearanceLevel, id },
      userId,
      orgId,
      rolesPromise,
    );
  };
}

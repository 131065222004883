import { atom } from "jotai";

type MessageData<Value> = {
  value: Value;
};

export function atomWithBroadcast<Value>(key: string, initialValue: Value) {
  const baseAtom = atom(initialValue);
  const listeners = new Set<
    (event: MessageEvent<MessageData<Value>>) => void
  >();
  const channel = new BroadcastChannel(key);
  channel.onmessage = (event) => {
    for (const listener of listeners) {
      listener(event as MessageEvent<MessageData<Value>>);
    }
  };

  const broadcastAtom = atom<Value, [{ isEvent: boolean; value: Value }], void>(
    (get) => get(baseAtom),
    (get, set, update) => {
      set(baseAtom, update.value);

      if (!update.isEvent) {
        const message: MessageData<Value> = { value: get(baseAtom) };
        channel.postMessage(message);
      }
    },
  );

  broadcastAtom.onMount = (setAtom) => {
    const listener = (event: MessageEvent<MessageData<Value>>) => {
      setAtom({ isEvent: true, value: event.data.value });
    };
    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  };

  const returnedAtom = atom<Value, [Value], void>(
    (get) => get(broadcastAtom),
    (_, set, update) => {
      set(broadcastAtom, { isEvent: false, value: update });
    },
  );

  return returnedAtom;
}

export const isPausedAtom = atomWithBroadcast<boolean>("isPaused", false);

export const lastUpdatedAtAtom = atomWithBroadcast<Date | null>(
  "lastUpdatedAt",
  null,
);

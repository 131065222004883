import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";
import { useEffect } from "react";
import posthog from "posthog-js";
import { nanoid } from "nanoid";

import { type TriplitEntity, client, reportSyncError } from "../triplit";
import { useUserId } from "../context/auth";

// PAGES
const pagesQuery = client.query("pages").order("idx", "ASC").build();

export const pagesQueryOptions = () =>
  queryOptions({
    queryKey: ["pages"],
    queryFn: () => client.fetch(pagesQuery),
  });

export const usePagesSuspended = () => useSuspenseQuery(pagesQueryOptions());

export const usePageSuspended = (pageId: string) => {
  const pages = usePagesSuspended();
  return pages.data?.find((p) => p.id === pageId);
};

export const usePage = (pageId: string) =>
  useQuery({
    ...pagesQueryOptions(),
    select: (data) => data.find((p) => p.id === pageId),
  });

export const useUpdatePage = () => {
  const qry = useQueryClient();
  return useMutation({
    networkMode: "always",
    mutationKey: ["pages"],
    mutationFn: async (arg: {
      id: string;
      updaterFn: (page: TriplitEntity<"pages">) => void;
    }) => client.update("pages", arg.id, arg.updaterFn),
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["pages"] });
      reportSyncError(trx?.txId, "pages");
    },
  });
};

export const useAppendNewPage = () => {
  const qry = useQueryClient();
  const userId = useUserId();

  return useMutation({
    mutationKey: ["pages"],
    mutationFn: async (p: { page: string }) => {
      const existing =
        qry.getQueryData<TriplitEntity<"pages">[]>(["pages"]) ?? [];
      const highestIdx = existing[existing.length - 1]?.idx;
      const idx = existing.length ? highestIdx + 1 : 1;

      const trx = await client.insert("pages", {
        id: nanoid(),
        page: p.page,
        idx,
        userId: userId,
      });

      posthog.capture("page_created", {
        initial_page: false,
        page_name: p.page,
        idx,
      });
      return trx;
    },
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["pages"] });
      reportSyncError(trx?.txId, "pages", "insert");
    },
  });
};

export const useDeletePage = () => {
  const qry = useQueryClient();
  return useMutation({
    mutationKey: ["pages"],
    mutationFn: async (id: string) => {
      return client.transact(async (tx) => {
        const products = await tx.fetch(
          client.query("pageProducts").where("pageId", "=", id).build(),
        );
        return Promise.all([
          ...products.map((x) => tx.delete("pageProducts", x.id)),
          tx.delete("pages", id),
        ]);
      });
    },
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["pages"] });
      reportSyncError(trx?.txId, "pages");
    },
  });
};

export const useActivePageId = () => useParams({ from: "/app/market/$id" }).id;

export const useOptionalActivePageId = () => useParams({ strict: false }).id;

export const useSyncPages = () => {
  const qry = useQueryClient();
  useEffect(() => {
    return client.subscribe(pagesQuery, (data) => {
      qry.setQueryData<TriplitEntity<"pages">[]>(["pages"], data);
    });
  }, [qry]);
};

// PAGE PRODUCTS
export const pageProductsQueryOptions = (pageId?: string) =>
  queryOptions({
    enabled: !!pageId,
    queryKey: ["pageProducts", pageId],
    queryFn: () =>
      client.fetch(
        client
          .query("pageProducts")
          .where([
            ["userId", "=", "$session.SESSION_USER_ID"],
            ["pageId", "=", pageId ?? ""],
          ])
          .order("idx", "ASC")
          .build(),
      ),
  });

export const usePageProductsSuspended = (pageId?: string) =>
  useSuspenseQuery(pageProductsQueryOptions(pageId));

export const usePageProducts = (pageId?: string) => {
  return useQuery({
    enabled: !!pageId,
    ...pageProductsQueryOptions(pageId ?? ""),
  });
};

export const useInsertPageProducts = () => {
  const qry = useQueryClient();
  return useMutation({
    mutationKey: ["pageProducts"],
    mutationFn: (products: TriplitEntity<"pageProducts">[]) => {
      return client.transact(async (tx) => {
        for (const product of products) {
          await tx.insert("pageProducts", product);
        }
      });
    },
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["pageProducts"] });
      reportSyncError(trx?.txId, "pageProducts", "insert");
    },
  });
};

import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  client,
  reportSyncError,
  type TConditionalFormattingRules,
  type TriplitEntity,
} from "../../../../triplit";

export const useDeleteConditionalFormattingRules = () => {
  const qry = useQueryClient();
  return useMutation({
    mutationKey: ["conditionalFormattingRules", "delete"],
    mutationFn: (id: string) => client.delete("conditionalFormattingRules", id),
    onSettled: (e) => {
      qry.invalidateQueries({ queryKey: ["conditionalFormattingRules"] });
      reportSyncError(e?.txId, "conditionalFormattingRules");
    },
  });
};

export const useUpdateConditionalFormattingRules = () => {
  const qry = useQueryClient();
  return useMutation({
    networkMode: "always",
    mutationKey: ["conditionalFormattingRules"],
    mutationFn: async (arg: {
      id: string;
      updaterFn: (page: TriplitEntity<"conditionalFormattingRules">) => void;
    }) => client.update("conditionalFormattingRules", arg.id, arg.updaterFn),
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["conditionalFormattingRules"] });
      reportSyncError(trx?.txId, "conditionalFormattingRules");
    },
  });
};

export const useInsertConditionalFormattingRules = () => {
  const qry = useQueryClient();
  return useMutation({
    mutationKey: ["conditionalFormattingRules"],
    mutationFn: async (
      p: Omit<TriplitEntity<"conditionalFormattingRules">, "id">,
    ) => client.insert("conditionalFormattingRules", p),
    onSettled: (trx) => {
      qry.invalidateQueries({ queryKey: ["conditionalFormattingRules"] });
      reportSyncError(trx?.txId, "conditionalFormattingRules", "insert");
    },
  });
};

const getQueryConditionalFormattingRules = (pageId: string) =>
  queryOptions({
    queryKey: ["conditionalFormattingRules"],
    queryFn: () =>
      client.fetch(
        client
          .query("conditionalFormattingRules")
          .where("pageId", "=", pageId)
          .order("priorityIdx", "ASC")
          .build(),
      ),
  });

export const useQueryConditionalFormattingRules = (pageId: string) =>
  useQuery(getQueryConditionalFormattingRules(pageId));

export const useQueryConditionalFormattingRulesGrouped = (pageId: string) => {
  return useQuery({
    ...getQueryConditionalFormattingRules(pageId),
    select: groupRules,
  });
};

// Create an object where the key is `columnId-rowId` and the value is an array of rules based on the ones that have the same columnId and rowId.
const groupRules = (rules: TConditionalFormattingRules[]) =>
  rules.reduce((acc, rule) => {
    const key = `${rule.columnId}-${rule.rowId}`;
    if (!acc[key]) return Object.assign(acc, { [key]: [rule] });
    return Object.assign(acc, { [key]: [...acc[key], rule] });
  }, {} as TConditionalFormattingRulesGrouped);

export type TConditionalFormattingRulesGrouped = Record<
  string,
  TConditionalFormattingRules[]
>;

import { LineSeries } from ".";
import { ImageWatermark } from "./components/image-watermarks";
import ReactDOMServer from "react-dom/server";
import { ArtisLogo } from "../../images/ArtisLogo";
import { TooltipPrimitive } from "./tooltip-plugin/tooltip";
import {
  type BandCalcData,
  BandsIndicator,
} from "./bands-indicator/bands-indicator";
import { bb, kc } from "indicatorts";
import type { TIndicatorsState } from "./utils";
import type { CandlestickData } from "lightweight-charts";
import { useMemo } from "react";

export function generateLogoWatermark(mode: "dark" | "light") {
  const svgString = ReactDOMServer.renderToString(<ArtisLogo mode={mode} />);
  const src = `data:image/svg+xml;base64,${btoa(svgString)}`;
  return new ImageWatermark(src, {
    maxHeight: 100,
    maxWidth: 100,
    padding: 26,
    alpha: 0.9,
  });
}

export function generateTooltip(mode: "dark" | "light") {
  return new TooltipPrimitive({
    theme: mode,
    tooltip: {
      followMode: "tracking",
    },
  });
}

export function generateBollingerBands(period: number) {
  const bands = new BandsIndicator({
    fillColor: "rgba(245,173,28,0.1)",
    lineColor: "rgb(245,173,28)",
    lineWidth: 0.5,
    period,
    calculationFn: ({ closings }) => {
      return bb(closings, { period });
    },
  });

  return bands;
}

export function BollingerBands({
  data,
  color,
  period,
  bandData,
}: TIndicatorsState[number] & {
  bandData: BandCalcData;
  data: CandlestickData[];
}) {
  const bands = generateBollingerBands(period);

  const indicatorData = useMemo(() => {
    return aggregateData(data, period);
  }, [data, period]);

  return (
    <LineSeries
      lineWidth={1}
      reactive
      data={indicatorData}
      bandCalcData={bandData}
      lastValueVisible={false}
      color={color}
      crosshairMarkerVisible={false}
      onSeriesReady={(api) => {
        api.attachPrimitive(bands);
        api.applyOptions({ crosshairMarkerVisible: false });
      }}
    />
  );
}

export function generateKeltnerChannels(period: number) {
  const bands = new BandsIndicator({
    fillColor: "rgba(161, 51, 163, 0.1)",
    lineColor: "rgb(161, 51, 163)",
    lineWidth: 0.5,
    period,
    calculationFn: ({ highs, lows, closings }) => {
      return kc(highs, lows, closings, { period });
    },
  });

  return bands;
}

export function KeltnerChannels({
  bandData,
  data,
  color,
  period,
}: TIndicatorsState[number] & {
  bandData: BandCalcData;
  data: CandlestickData[];
}) {
  const bands = generateKeltnerChannels(period);

  const indicatorData = useMemo(() => {
    return aggregateData(data, period);
  }, [data, period]);

  return (
    <LineSeries
      lineWidth={1}
      reactive
      data={indicatorData}
      bandCalcData={bandData}
      lastValueVisible={false}
      color={color}
      crosshairMarkerVisible={false}
      onSeriesReady={(api) => {
        api.attachPrimitive(bands);
        api.applyOptions({ crosshairMarkerVisible: false });
      }}
    />
  );
}

export function aggregateData(data: CandlestickData[], period: number) {
  // aggregate data by taking the last <period> candles and calculating the average for each candle
  const aggregatedData = data.map((d, idx) => {
    if (idx < period) return null;
    const periodData = data.slice(idx - period, idx);
    const average = periodData.reduce((acc, d) => acc + d.close, 0) / period;
    return {
      time: d.time,
      value: average,
    };
  });
  return aggregatedData.filter(Boolean);
}

export function MovingAverageSeries({
  id,
  period,
  color,
  data,
}: TIndicatorsState[number] & { data: CandlestickData[] }) {
  const indicatorData = useMemo(() => {
    return aggregateData(data, period);
  }, [data, period]);

  return (
    <LineSeries
      lineWidth={1}
      lastValueVisible={false}
      crosshairMarkerVisible={false}
      onSeriesReady={(api) => {
        api.applyOptions({ crosshairMarkerVisible: false });
      }}
      reactive
      data={indicatorData}
      color={color}
    />
  );
}

import { atomFamily, atomWithStorage } from "jotai/utils";
import type { SerializedDockview } from "dockview";
import { useAtom } from "jotai";
import { useMemo } from "react";

import { useUserId } from "../../../context/auth";

type LayoutState = {
  pannels: number;
  state: null | SerializedDockview;
};

const layoutStateAtom = atomFamily((userId: string) =>
  atomWithStorage<LayoutState>(`charts-layout-state-${userId}`, {
    pannels: 1,
    state: null,
  }),
);

export const useLayoutState = () => {
  const userId = useUserId();
  return useAtom(useMemo(() => layoutStateAtom(userId), [userId]));
};

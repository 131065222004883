import {
  Box,
  Button,
  Checkbox,
  Input,
  Option,
  Select,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import { ModalButtons } from "../Buttons";
import { GridModalContainer } from "../GridModal";
import { useCallback, useState } from "react";
import { FaTimes, FaHandPointUp, FaTrash } from "react-icons/fa";
import { CSS } from "@dnd-kit/utilities";
import {
  type DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
  DndContext,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { LuGripHorizontal } from "react-icons/lu";
import { useAuth0 } from "@auth0/auth0-react";
import {
  type UseFormReturn,
  useForm,
  useFieldArray,
  Controller,
  useWatch,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ColorPicker } from "../../../grid-settings/ColorPicker";
import { generateNKeysBetween } from "fractional-indexing";
import type { TConditionalFormattingRules } from "../../../../triplit/schema";
import {
  relativeRowToRowId,
  rowIdToRelativeRow,
  rowStringToCode,
} from "../../periodHelpers";
import { useAtomValue } from "jotai";
import { gridModalDockviewAtom } from "../../../calculations-worker/sharedStores";
import { collisionDetection } from "../../../utils";
import toast from "react-hot-toast";
import {
  emptyRule,
  getNodePeriod,
  headerStyle,
  ruleValidation,
  useResetLimitSelection,
  type TConditionalRule,
} from "./helpers";
import type { TConditionalRuleFormatted } from "./ManageRules";
import type { GridApi } from "ag-grid-community";
import { unique } from "remeda";
import { GridModalSelectionInfo } from "../modalComponents";
import { useGrid } from "../../stores";
import { useActivePageId, useOptionalActivePageId } from "../../../../data";
import {
  type TConditionalFormattingRulesGrouped,
  useQueryConditionalFormattingRulesGrouped,
  useInsertConditionalFormattingRules,
  useQueryConditionalFormattingRules,
} from "./hooks";
import { captureEvent } from "../../../../context/ph";

const widths = {
  drag: 60,
  rule: 180,
  formatting: 90,
  actions: { create: 70, manage: 120 },
  stopIfTrue: 90,
};

const maxHeight = 32;

export function ConditionalFormattingHeaders({
  type,
}: {
  type: "create" | "manage";
}) {
  const headers = {
    rule: (
      <th
        key={"rule"}
        style={{
          width: widths.rule,
        }}
      >
        <Typography fontSize={"xs"}>Rule</Typography>
      </th>
    ),
    limit: (
      <th key="limit">
        <Typography fontSize={"xs"}>Limit</Typography>
      </th>
    ),
    formatting: (
      <th key="formatting" style={{ width: widths.formatting }}>
        <Typography fontSize={"xs"}>Formatting</Typography>
      </th>
    ),
    affected: (
      <th key="affected">
        <Typography fontSize={"xs"}>Affected</Typography>
      </th>
    ),
    note: (
      <th key="note">
        <Typography fontSize={"xs"}>Note</Typography>
      </th>
    ),
    actions: (
      <th
        key="actions"
        style={{
          width: widths.actions[type],
        }}
      >
        <Typography fontSize={"xs"}>Actions</Typography>
      </th>
    ),
    stopIfTrue: (
      <th
        key="stop-if-true"
        style={{
          width: widths.stopIfTrue,
        }}
      >
        <Typography fontSize={"xs"}>Stop If True</Typography>
      </th>
    ),
  };

  const headerItems =
    type === "create"
      ? [
          headers.rule,
          headers.limit,
          headers.formatting,
          headers.note,
          headers.actions,
          headers.stopIfTrue,
        ]
      : [
          headers.limit,
          headers.rule,
          headers.formatting,
          headers.affected,
          headers.note,
          headers.actions,
          headers.stopIfTrue,
        ];

  return (
    <thead>
      <tr>
        <th style={{ width: widths.drag }}>
          <Typography fontSize={"xs"}>#</Typography>
        </th>
        {headerItems}
      </tr>
    </thead>
  );
}

function getColumnPeriods(
  gridApi?: GridApi | null,
  rules?: TConditionalFormattingRulesGrouped,
  rule?: TConditionalRule,
) {
  if (!gridApi || !rules || !rule) return [];

  const ruleValues = Object.values(rules).flat();

  const ruleGroup =
    ruleValues.filter(
      (item) =>
        item.rule === rule.rule &&
        (item.limit === rule.limit ||
          item.limit ===
            `${rule.limitRef?.columnId}:${rule.limitRef?.rowId}`) &&
        item.bgColor === rule.formatting.bgColor &&
        item.boldText === rule.formatting.boldText &&
        item.invertTextColor === rule.formatting.invertTextColor &&
        item.note === rule.note &&
        item.stopIfTrue === rule.stopIfTrue,
    ) || [];

  if (!ruleGroup.length) return [];

  return ruleGroup.map((group) => {
    const columnId = group.columnId;

    const columnRules = ruleValues
      .filter((rule) => rule.rowId === group.rowId)
      .sort((a, b) => b.rowId.localeCompare(a.rowId));

    const periods = unique(
      columnRules.map((rule) => relativeRowToRowId[rule.rowId]),
    );

    const columnName = gridApi?.getColumn(columnId)?.getColDef()?.headerName;

    const periodsString =
      periods.length > 1
        ? `${periods[0]} - ${periods[periods.length - 1]}`
        : periods[0];

    const affectedString = `${columnName} [${periodsString}]`;

    return {
      columnId,
      columnName,
      periods,
      string: affectedString,
    };
  });
}

export function ConditionalFormattingRow({
  form,
  rule,
  index,
  remove,
  type,
}: {
  groupedRules?: TConditionalRuleFormatted[];
  form: UseFormReturn<{ rules: TConditionalRule[] & { ids?: Array<string> } }>;
  rule: TConditionalRule;
  index: number;
  remove: (index: number) => void;
  type: "create" | "manage";
}) {
  const pageId = useOptionalActivePageId();
  const groupedFormattingRules = useQueryConditionalFormattingRulesGrouped(
    pageId ?? "",
  );
  const conditionalFormattingRules = groupedFormattingRules.data ?? {};

  const [gridApi] = useGrid();

  const [showColorPicker, setShowColorPicker] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: rule.id });

  const { register, control } = form;

  const output = useWatch({
    control,
    name: "rules",
  });

  const columnPeriods = getColumnPeriods(
    gridApi,
    conditionalFormattingRules,
    rule,
  );

  const affectedString = unique(
    columnPeriods.map((period) => period.string),
  ).join(", ");

  const resetLimitSelection = useResetLimitSelection();

  const items = {
    rule: (
      <td key="rule">
        <Controller
          name={`rules.${index}.rule`}
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              size="sm"
            >
              <Option value={"equals"}>Equals</Option>
              <Option value={"isNotEqual"}>Is not equal</Option>
              <Option value={"lessThan"}>Less than</Option>
              <Option value={"moreThan"}>More than</Option>
              <Option value={"lessThanOrEqual"}>Less than or equal</Option>
              <Option value={"moreThanOrEqual"}>More than or equal</Option>
            </Select>
          )}
        />
      </td>
    ),
    limit: (
      <td key="limit">
        <Stack flexDirection={"row"} gap={1}>
          {output[index]?.limitRef?.name ? (
            <Stack
              flexDirection={"row"}
              gap={1}
              overflow="hidden"
              width="100%"
              justifyContent={"space-between"}
            >
              <Stack overflow="hidden">
                <Typography
                  fontSize="xs"
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  overflow="hidden"
                >
                  {output[index]?.limitRef?.name}
                </Typography>
                <Typography fontSize="xs">
                  [{output[index]?.limitRef?.period}]
                </Typography>
              </Stack>
              <Controller
                name={`rules.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Button
                    size="sm"
                    variant="soft"
                    color="neutral"
                    sx={{
                      maxHeight,
                    }}
                    onClick={() => {
                      field.onChange(undefined);
                    }}
                  >
                    <FaTimes />
                  </Button>
                )}
              />
            </Stack>
          ) : (
            <>
              <Input
                {...register(`rules.${index}.limit`)}
                type="number"
                size="sm"
                fullWidth
              />
              <Controller
                name={`rules.${index}.limitRef`}
                control={control}
                render={({ field }) => (
                  <Button
                    size="sm"
                    variant="soft"
                    color="neutral"
                    onClick={() => {
                      if (
                        gridApi?.getGridOption("rowClass") ===
                        "ag-cell-selection"
                      ) {
                        resetLimitSelection();
                        return;
                      }

                      gridApi?.setGridOption("onCellClicked", (e) => {
                        resetLimitSelection();

                        const columnId = e.column.getColId();
                        const name = e.colDef.headerName;
                        const period = getNodePeriod(e.node);
                        const rowId = period
                          ? rowIdToRelativeRow[period]
                          : e.node.id;

                        field.onChange({
                          columnId,
                          rowId,
                          name,
                          period,
                        });
                      });

                      gridApi?.setGridOption("rowClass", "ag-cell-selection");
                    }}
                  >
                    <FaHandPointUp />
                  </Button>
                )}
              />
            </>
          )}
        </Stack>
      </td>
    ),
    formatting: (
      <td key="formatting">
        <Controller
          name={`rules.${index}.formatting`}
          control={control}
          render={({ field }) => (
            <ColorPicker
              show={showColorPicker}
              setShow={setShowColorPicker}
              onChange={(newValue) => {
                field.onChange({
                  bgColor: newValue.color,
                  boldText: newValue.boldText,
                  invertTextColor: newValue.invertTextColor,
                });
              }}
              value={{
                color: output[index].formatting.bgColor,
                boldText: output[index].formatting.boldText,
                invertTextColor: output[index].formatting.invertTextColor,
              }}
              showSwatches
              showTextOptions
              width={"100%"}
              height={maxHeight}
            />
          )}
        />
      </td>
    ),
    affected: (
      <td key="affected">
        <Typography
          fontSize="xs"
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow="hidden"
          title={affectedString}
        >
          {affectedString}
        </Typography>
      </td>
    ),
    note: (
      <td key="note">
        <Input {...register(`rules.${index}.note`)} size="sm" />
      </td>
    ),
    actions: (
      <td key="actions">
        <Stack gap={1} flexDirection={"row"} justifyContent={"center"}>
          <Button
            fullWidth={type === "create"}
            size="sm"
            variant="soft"
            color="neutral"
            onClick={() => remove(index)}
          >
            <FaTrash />
          </Button>
          {type === "manage" && (
            <Button
              size="sm"
              variant="soft"
              color="neutral"
              onClick={() => {
                gridApi?.clearRangeSelection();

                const rowNodes = columnPeriods.map((column) => {
                  return column.periods.map((period) => {
                    return gridApi?.getRowNode(rowStringToCode(period));
                  });
                });

                const rowStartIndex = rowNodes[0][0]?.rowIndex;
                const rowEndIndex = rowNodes[rowNodes.length - 1][0]?.rowIndex;

                if (rowStartIndex && rowEndIndex) {
                  gridApi?.addCellRange({
                    rowStartIndex,
                    rowEndIndex,
                    columnStart: columnPeriods[0].columnId,
                    columnEnd: columnPeriods[0].columnId,
                  });
                }
              }}
            >
              Go To
            </Button>
          )}
        </Stack>
      </td>
    ),
    stopIfTrue: (
      <td key="stopIfTrue">
        <Controller
          name={`rules.${index}.stopIfTrue`}
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={output[index].stopIfTrue}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              size="sm"
            />
          )}
        />
      </td>
    ),
  };

  const gridItems =
    type === "create"
      ? [
          items.rule,
          items.limit,
          items.formatting,
          items.note,
          items.actions,
          items.stopIfTrue,
        ]
      : [
          items.limit,
          items.rule,
          items.formatting,
          items.affected,
          items.note,
          items.actions,
          items.stopIfTrue,
        ];

  return (
    <tr
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      key={rule.id}
    >
      <Box
        component="td"
        sx={{
          cursor: "grab",
        }}
        {...attributes}
        {...listeners}
      >
        <Button
          size="sm"
          variant="soft"
          color="neutral"
          fullWidth
          sx={{
            pointerEvents: "none",
          }}
        >
          <LuGripHorizontal />
        </Button>
      </Box>
      {gridItems}
    </tr>
  );
}

export function CreateConditionalFormattingRule() {
  const pageId = useActivePageId();
  const rules = useQueryConditionalFormattingRules(pageId);
  const insert = useInsertConditionalFormattingRules();
  const dockviewValue = useAtomValue(gridModalDockviewAtom);
  const rangeSelection = dockviewValue?.conditional?.selectedRange || [];

  const [gridApi] = useGrid();

  const form = useForm<{
    rules: TConditionalRule[];
  }>({
    defaultValues: {
      rules: [emptyRule()],
    },
    resolver: zodResolver(ruleValidation),
  });

  const { fields, append, remove, replace } = useFieldArray({
    control: form.control,
    name: "rules",
  });

  const formData = useWatch({
    control: form.control,
    name: "rules",
  });

  const auth = useAuth0();
  const userId = auth.user?.sub;

  const cells = rangeSelection.flatMap((range) => {
    return range.map((cell) => {
      const rowNode = gridApi?.getRowNode(cell.rowId);
      const period = getNodePeriod(rowNode);
      const rowId = period ? rowIdToRelativeRow[period] : rowNode?.id;

      return {
        columnId: cell.columnId,
        rowId,
      };
    });
  });

  const sensors = useSensors(useSensor(MouseSensor));

  // Ideally we'd use move/swap, but in some cases we need to do multiple, and it is not recommended to do so by the docs, so replace is better otherwise we risk the rules getting messed up.
  const reorderRulesHanlder = useCallback(
    (e: DragEndEvent) => {
      if (!e.over || !fields?.length) return;

      const draggedRuleId = e.active.id.toString();
      const dropTargetId = e.over.id.toString();

      const draggedRule = fields.find((rule) => rule.id === draggedRuleId);
      const dropTargetRule = fields.find((rule) => rule.id === dropTargetId);

      if (!draggedRule || !dropTargetRule)
        throw new Error("dragged or drop target rule not found");

      const draggedRuleIndex = fields.findIndex(
        (rule) => rule.id === draggedRuleId,
      );
      const dropTargetIndex = fields.findIndex(
        (rule) => rule.id === dropTargetId,
      );

      const direction = draggedRuleIndex < dropTargetIndex ? "top" : "bottom";

      if (draggedRuleId === dropTargetId) {
        return;
      }

      // Item is moved to be the first item.
      if (direction === "bottom" && dropTargetIndex === 0) {
        const current = [...formData];
        const draggedRule = current.splice(draggedRuleIndex, 1)[0];
        current.unshift(draggedRule);
        replace(current);
      }

      // Item is moved to be the last item.
      if (direction === "top" && dropTargetIndex === fields.length - 1) {
        const current = [...formData];
        const draggedRule = current.splice(draggedRuleIndex, 1)[0];
        current.push(draggedRule);
        replace(current);
      }

      const current = [...formData];
      const draggedRuleSpliced = current.splice(draggedRuleIndex, 1)[0];
      current.splice(dropTargetIndex, 0, draggedRuleSpliced);
      replace(current);
    },
    [fields, replace, formData],
  );

  const resetLimitSelection = useResetLimitSelection();

  async function handleSave() {
    try {
      resetLimitSelection();

      if (!pageId) throw new Error("pageId is not defined");
      if (!userId) throw new Error("userId is not defined");
      if (!formData.length) throw new Error("No rules defined");

      const cellIds = cells.map((cell) => `${cell.columnId}-${cell.rowId}`);

      const existingRules = rules.data || [];

      const existingCellRules = existingRules.filter((rule) =>
        cellIds.includes(`${rule.columnId}-${rule.rowId}`),
      );

      const lastPriority = existingCellRules.length
        ? existingCellRules[existingCellRules.length - 1].priorityIdx
        : null;

      const priorityIdxs = generateNKeysBetween(
        lastPriority,
        null,
        formData.length,
      );

      const formattedRules = formData.flatMap((rule, index) => {
        return cells.map((cell) => {
          return {
            rule: rule.rule,
            limit: rule?.limitRef
              ? `${rule.limitRef.columnId}:${rule.limitRef.rowId}`
              : rule.limit,
            priorityIdx: priorityIdxs[index],
            bgColor: rule.formatting.bgColor,
            boldText: rule.formatting.boldText,
            invertTextColor: rule.formatting.invertTextColor,
            note: rule.note,
            stopIfTrue: rule.stopIfTrue,
            columnId: cell.columnId,
            rowId: cell.rowId || "",
            userId,
            pageId,
          } satisfies Omit<TConditionalFormattingRules, "id">;
        });
      });

      const inserts = formattedRules.map((rule) => insert.mutateAsync(rule));

      await Promise.all(inserts);
    } catch (error) {
      console.error(error);
      toast.error("Failed to save conditional formatting rules");
    }
  }

  return (
    <GridModalContainer
      panel="conditional"
      body={
        <>
          <GridModalSelectionInfo selectedRange={rangeSelection} />
          <form onSubmit={form.handleSubmit(handleSave)}>
            <Box pt={2}>
              <Stack gap={2} mb={4}>
                <DndContext
                  sensors={sensors}
                  onDragEnd={reorderRulesHanlder}
                  collisionDetection={collisionDetection}
                >
                  <SortableContext
                    items={fields}
                    strategy={verticalListSortingStrategy}
                  >
                    <Table
                      sx={(theme) => ({
                        tableLayout: "fixed",
                        minWidth: 800,
                        td: {
                          minWidth: 40,
                          textAlign: "center",
                        },
                        "tr:first-of-type td": {
                          paddingTop: 1.5,
                        },
                        th: headerStyle(theme),
                        borderSpacing: "6px 4px",
                      })}
                      noWrap
                      borderAxis="none"
                    >
                      <ConditionalFormattingHeaders type="create" />

                      <tbody>
                        {fields.map((rule, index) => (
                          <ConditionalFormattingRow
                            key={rule.id}
                            form={form}
                            rule={rule}
                            index={index}
                            remove={remove}
                            type="create"
                          />
                        ))}
                      </tbody>
                    </Table>
                  </SortableContext>
                </DndContext>
                <Box>
                  <Typography
                    onClick={() => append(emptyRule())}
                    sx={{
                      color: "var(--artis-orange)",
                      cursor: "pointer",
                      display: "inline",
                      pl: 2,
                    }}
                  >
                    + Add Rule
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </form>
        </>
      }
      buttons={
        <ModalButtons
          parentPanel={"conditional"}
          onCancel={() => {
            gridApi?.setGridOption("onCellClicked", undefined);
            gridApi?.setGridOption("rowClass", undefined);
          }}
          onSave={() => {
            captureEvent("Conditional Formatting Save");
            handleSave();
          }}
        />
      }
    />
  );
}
